import React from "react";
import "./Page404.scss";
import Header from "../../include/Header";
import Sidebar from "../../include/Sidebar";
import Footer from "../../include/Footer";
import Button from "react-bootstrap/Button";
import {useState} from "react";
import {useLanguage} from "../../utils/LanguageProvider";
import {Link, useNavigate} from "react-router-dom";

const Page404 = props => {
  const {langJson} = useLanguage();
  const navigate = useNavigate();

  const parseTextWithLinks = text => {
    const parts = text.split("{@site_email@}"); // Split the text using {@link1@} and {@link2@} as delimiters
    return parts.map((part, index) => (
      <React.Fragment key={index}>
        {part}
        {index === 0 ? <a href="mailto:info@diakanext.org">info@diakanext.org</a> : null}
      </React.Fragment>
    ));
  };

  const [loginShow, setLoginShow] = useState(false);
  const handleLoginShow = () => setLoginShow(true);
  return (
    <div>
      <Header loginShow={loginShow} setLoginShow={setLoginShow} />
      <Sidebar />
      <div className="content-wrapper">
        <div className="content">
          <div className="empty-page-placeholder">
            <div className="placeholder-message d-flex">
              <div className="icon">
                <svg xmlns="http://www.w3.org/2000/svg" width={512} height={512} viewBox="0 0 512 512">
                  <path
                    d="M457.74,170.1a30.26,30.26,0,0,0-11.16-2.1h-.4c-20.17.3-42.79,19.19-54.66,47.76-14.23,34.18-7.68,69.15,14.74,78.14a30.21,30.21,0,0,0,11.15,2.1c20.27,0,43.2-19,55.17-47.76C486.71,214.06,480.06,179.09,457.74,170.1Z"
                    style={{
                      fill: "none",
                      strokeMiterlimit: 10,
                      strokeWidth: 32,
                    }}
                  />
                  <path
                    d="M327.6,303.48C299.8,257.35,287.8,240,256,240s-43.9,17.46-71.7,63.48c-23.8,39.36-71.9,42.64-83.9,76.07a50.91,50.91,0,0,0-3.6,19.25c0,27.19,20.8,49.2,46.4,49.2,31.8,0,75.1-25.39,112.9-25.39S337,448,368.8,448c25.6,0,46.3-22,46.3-49.2a51,51,0,0,0-3.7-19.25C399.4,346,351.4,342.84,327.6,303.48Z"
                    style={{
                      fill: "none",
                      strokeMiterlimit: 10,
                      strokeWidth: 32,
                    }}
                  />
                  <path
                    d="M192.51,196a26.53,26.53,0,0,0,4-.3c23.21-3.37,37.7-35.53,32.44-71.85C224,89.61,203.22,64,181.49,64a26.53,26.53,0,0,0-4,.3c-23.21,3.37-37.7,35.53-32.44,71.85C150,170.29,170.78,196,192.51,196Z"
                    style={{
                      fill: "none",
                      strokeMiterlimit: 10,
                      strokeWidth: 32,
                    }}
                  />
                  <path
                    d="M366.92,136.15c5.26-36.32-9.23-68.48-32.44-71.85a26.53,26.53,0,0,0-4-.3c-21.73,0-42.47,25.61-47.43,59.85-5.26,36.32,9.23,68.48,32.44,71.85a26.53,26.53,0,0,0,4,.3C341.22,196,362,170.29,366.92,136.15Z"
                    style={{
                      fill: "none",
                      strokeMiterlimit: 10,
                      strokeWidth: 32,
                    }}
                  />
                  <path
                    d="M105.77,293.9c22.39-9,28.93-44,14.72-78.14C108.53,187,85.62,168,65.38,168a30.21,30.21,0,0,0-11.15,2.1c-22.39,9-28.93,44-14.72,78.14C51.47,277,74.38,296,94.62,296A30.21,30.21,0,0,0,105.77,293.9Z"
                    style={{
                      fill: "none",
                      strokeMiterlimit: 10,
                      strokeWidth: 32,
                    }}
                  />
                </svg>
              </div>
              <div className="message-text">
                <h3>{langJson?.page_not_found}</h3>
                <p>{parseTextWithLinks(langJson?.sorry_but_we_cannot_find_the_page_you_are_looking_for_please_go_b)}</p>
                <Button className="btn-custom text-uppercase main-outline" onClick={() => navigate("/")}>
                  Go back to Homepage
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Page404;
