import React, {useState} from "react";
import {Navigate, Outlet} from "react-router-dom";

const Auth = () => {
  const [userLoggedIn] = useState(JSON.parse(localStorage.getItem("loginInfo")) ? true : false);
  //   return userLoggedIn ? <Navigate to="/dashboard" /> : <Navigate to="/" />;
  return userLoggedIn ? <Outlet /> : <Navigate to="/" />;
};

export default Auth;
