import React, {useState, useEffect, useRef} from "react";
import axios from "axios";
import {NavLink, useNavigate} from "react-router-dom";
import "./PasswordSettings.scss";
import "../user-common.scss";
import AfterLoginHeader from "../../../include/AfterLoginHeader";
import AfterLoginSidebar2 from "../../../include/AfterLoginSidebar2";
import SettingsBanner from "../../../include/settingsBanner/SettingsBanner";
import Footer from "../../../include/Footer";
import Button from "react-bootstrap/Button";
import {BASE_URL} from "../../../app_url";
import {useLanguage} from "../../../utils/LanguageProvider";

const PasswordSettings = props => {
  const navigate = useNavigate();
  const {langJson} = useLanguage();
  const [storedUserData, setStoredUserData] = useState();
  const [loginData, setLoginData] = useState();

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    let value = JSON.parse(localStorage.getItem("loginInfo")); //JSON.parse(secureLocalStorage.getItem('loginInfo'));
    setStoredUserData(value["token"]);
    if (value == undefined) {
      navigate("/");
    }
    if (value) {
      setLoginData(value["user"]);
    }
  }, []);

  const [formData, setFormData] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  });

  const [formErrors, setFormErrors] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  });

  const validateForm = () => {
    let isValid = true;
    const errors = {
      old_password: "",
      new_password: "",
      confirm_password: "",
    };

    if (formData.old_password === "") {
      errors.old_password = langJson?.old_password_is_required;
      isValid = false;
    }

    if (formData.new_password === "") {
      errors.new_password = langJson?.new_password_is_required;
      isValid = false;
    } else if (!/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}/.test(formData.new_password)) {
      errors.new_password = langJson?.password_should_be_strong;
      isValid = false;
    }

    if (formData.confirm_password === "") {
      errors.confirm_password = langJson?.confirm_password_is_required;
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };

  const handleSubmit = e => {
    e.preventDefault();

    if (validateForm()) {
      setIsSubmitting(true);
      // Send the signup data to your Python Flask API
      // You can use libraries like Axios to make API requests
      // Example:
      axios
        .post(`${BASE_URL}resetpassword`, JSON.stringify(formData), {
          headers: {
            "Content-Type": "application/json", // Set the content type
            Authorization: `Bearer ${storedUserData}`,
          },
        })
        .then(response => {
          setIsSubmitting(false);
          setSuccessMessage(response.data.message);
          setErrorMessage(""); // Clear any previous error messages

          setFormData({
            old_password: "",
            new_password: "",
            confirm_password: "",
          });

          setFormErrors({
            old_password: "",
            new_password: "",
            confirm_password: "",
          });
        })
        .catch(error => {
          setIsSubmitting(false);
          if (error.response) {
            setErrorMessage(error.response.data.error || langJson?.something_went_wrong_please_try_again);
          } else {
            setErrorMessage(langJson?.something_went_wrong_please_try_again);
          }
          setSuccessMessage(""); // Clear any previous success messages
        });
    }
  };

  const handleChange = e => {
    const {name, value, type, checked} = e.target;

    // Clear the error message associated with the field
    setFormErrors(prevErrors => ({...prevErrors, [name]: ""}));
    setFormData({...formData, [name]: value});
  };

  return (
    <div>
      <AfterLoginHeader />
      <AfterLoginSidebar2 />
      <div className="content-wrapper">
        <div className="content">
          <div className="content-block-header">
            <h1>{langJson?.account_settings}</h1>
          </div>
          <div className="white-settings-section">
            <SettingsBanner />
            <div className="seetings-bottom-section">
              <h3>{langJson?.account_password_settings}</h3>
              {successMessage && <div className="success-message">{successMessage}</div>}
              {errorMessage && <div className="error-message">{errorMessage}</div>}
              <form className="form" onSubmit={handleSubmit}>
                <ul className="row setting-form-list">
                  <li className="col-md-12">
                    <label className="form-label">{langJson?.current_password}</label>
                    <div className="form-control-holder">
                      <input
                        //type="password"
                        type={showPassword ? "text" : "password"}
                        className="form-control"
                        placeholder={langJson?.enter_your_current_password}
                        value={formData.old_password}
                        name="old_password"
                        onChange={handleChange}
                      />
                      {formErrors.old_password && <span className="error-message">{formErrors.old_password}</span>}
                      <div className="input-ctrls d-flex">
                        <Button className="input-ctrl-item" onClick={togglePasswordVisibility}>
                          <svg xmlns="http://www.w3.org/2000/svg" width={512} height={512} viewBox="0 0 512 512" {...props}>
                            <path
                              d="M255.66,112c-77.94,0-157.89,45.11-220.83,135.33a16,16,0,0,0-.27,17.77C82.92,340.8,161.8,400,255.66,400,348.5,400,429,340.62,477.45,264.75a16.14,16.14,0,0,0,0-17.47C428.89,172.28,347.8,112,255.66,112Z"
                              style={{
                                fill: "none",
                                strokeLinecap: "round",
                                strokeLinejoin: "round",
                                strokeWidth: 32,
                              }}
                            />
                            <circle
                              cx={256}
                              cy={256}
                              r={80}
                              style={{
                                fill: "none",
                                strokeMiterlimit: 10,
                                strokeWidth: 32,
                              }}
                            />
                          </svg>
                        </Button>
                      </div>
                    </div>
                  </li>
                  <li className="col-md-6">
                    <label className="form-label">{langJson?.new_password}</label>
                    <div className="form-control-holder">
                      <input
                        //type="password"
                        type={showPassword ? "text" : "password"}
                        className="form-control"
                        placeholder={langJson?.enter_a_new_password}
                        value={formData.new_password}
                        name="new_password"
                        onChange={handleChange}
                      />
                      {formErrors.new_password && <span className="error-message">{formErrors.new_password}</span>}
                      <div className="input-ctrls d-flex">
                        <Button className="input-ctrl-item" onClick={togglePasswordVisibility}>
                          <svg xmlns="http://www.w3.org/2000/svg" width={512} height={512} viewBox="0 0 512 512" {...props}>
                            <path
                              d="M255.66,112c-77.94,0-157.89,45.11-220.83,135.33a16,16,0,0,0-.27,17.77C82.92,340.8,161.8,400,255.66,400,348.5,400,429,340.62,477.45,264.75a16.14,16.14,0,0,0,0-17.47C428.89,172.28,347.8,112,255.66,112Z"
                              style={{
                                fill: "none",
                                strokeLinecap: "round",
                                strokeLinejoin: "round",
                                strokeWidth: 32,
                              }}
                            />
                            <circle
                              cx={256}
                              cy={256}
                              r={80}
                              style={{
                                fill: "none",
                                strokeMiterlimit: 10,
                                strokeWidth: 32,
                              }}
                            />
                          </svg>
                        </Button>
                      </div>
                    </div>
                  </li>
                  <li className="col-md-6">
                    <label className="form-label">{langJson?.confirm_new_password}</label>
                    <div className="form-control-holder">
                      <input
                        //type="password"
                        type={showPassword ? "text" : "password"}
                        className="form-control"
                        placeholder={langJson?.enter_the_new_password_again}
                        value={formData.confirm_password}
                        name="confirm_password"
                        onChange={handleChange}
                      />
                      {formErrors.confirm_password && <span className="error-message">{formErrors.confirm_password}</span>}
                      <div className="input-ctrls d-flex">
                        <Button className="input-ctrl-item" onClick={togglePasswordVisibility}>
                          <svg xmlns="http://www.w3.org/2000/svg" width={512} height={512} viewBox="0 0 512 512" {...props}>
                            <path
                              d="M255.66,112c-77.94,0-157.89,45.11-220.83,135.33a16,16,0,0,0-.27,17.77C82.92,340.8,161.8,400,255.66,400,348.5,400,429,340.62,477.45,264.75a16.14,16.14,0,0,0,0-17.47C428.89,172.28,347.8,112,255.66,112Z"
                              style={{
                                fill: "none",
                                strokeLinecap: "round",
                                strokeLinejoin: "round",
                                strokeWidth: 32,
                              }}
                            />
                            <circle
                              cx={256}
                              cy={256}
                              r={80}
                              style={{
                                fill: "none",
                                strokeMiterlimit: 10,
                                strokeWidth: 32,
                              }}
                            />
                          </svg>
                        </Button>
                      </div>
                    </div>
                  </li>
                  <li className="col-md-12">
                    <div className="d-grid gap-2">
                      {/* <Button
                        className="btn-custom main-inline btn-block"
                        disabled
                      >
                        Change password!
                      </Button> */}
                      <button type="submit" className={`btn btn-custom main-inline ${isSubmitting ? "disabled" : ""}`} disabled={isSubmitting}>
                        {isSubmitting ? langJson?.updated : langJson?.change_password}
                      </button>
                    </div>
                  </li>
                </ul>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PasswordSettings;
