import React, {useEffect, useState} from "react";
import {NavLink} from "react-router-dom";
import "./CMS.scss";
import "./Blog.scss";
import Header from "../../include/Header";
import PlayerSidebar from "../../include/PlayerSidebar";
import Footer from "../../include/Footer";
import {useLanguage} from "../../utils/LanguageProvider";
import {blogListApi} from "../../services/ApiService";

const PosterImg1 = "https://diakanext.org/upload/images/2023/06/1686298340ZQYUMP.jpg";
const PosterImg2 = "https://diakanext.org/upload/images/2023/06/1686298151DNKFQX.jpeg";
const PosterImg3 = "https://diakanext.org/upload/images/2021/07/1626692464QZJXTK.jpg";

const Blog = () => {
  const {langJson} = useLanguage();

  const [loading, setLoading] = useState(false); // For load
  const [blogList, setBlogList] = useState([]); // For List

  // Fetching blogs
  const fetchBlogs = async () => {
    setLoading(true);
    try {
      let response = await blogListApi();
      if (response) {
        if (response.data.res == 1) {
          setBlogList(response.data.data);
        }
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBlogs();
  }, []);

  return (
    <div>
      <Header />
      <PlayerSidebar />
      <div className="content-wrapper blog-content-wrapper">
        <div className="content-header">
          <h1 className="text-uppercase">{langJson?.blog}</h1>
        </div>
        <div className="content">
          <ul className="row blog-list">
            {blogList?.map((item, index) => (
              <li className="col-md-4" key={index}>
                <div className="video-grid-item">
                  <div className="video-poster">
                    <NavLink to={`/blog-details/${item?.id}`}>
                      <img src={item?.thumbnail} alt="Poster" />
                    </NavLink>
                  </div>
                  <div className="video-description">
                    <div className="video-info">
                      <h6>
                        <NavLink to={`/blog-details/${item?.id}`}>{item?.name}</NavLink>
                      </h6>
                      <div className="video-meta-info">
                        <div className="uname">
                          <NavLink></NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Blog;
