import React, {useEffect, useState} from "react";
import "./trending.scss";
import Header from "../../include/Header";
import Sidebar from "../../include/Sidebar";
import Footer from "../../include/Footer";
import Button from "react-bootstrap/Button";
import {NavLink} from "react-router-dom";

const Trending = props => {
  const [loginInfo, setLoginInfo] = useState("");

  useEffect(() => {
    let value = JSON.parse(localStorage.getItem("loginInfo"));
    if (value != undefined) {
      setLoginInfo(value);
    }
  }, []);

  return (
    <div>
      <Header />
      <Sidebar />
      <div className="content-wrapper">
        <div className="content">
          <div className="empty-page-placeholder">
            <div className="placeholder-message d-flex">
              <div className="icon">
                <svg xmlns="http://www.w3.org/2000/svg" width={512} height={512} viewBox="0 0 512 512" {...props}>
                  <polyline
                    points="48 320 112 320 176 64 240 448 304 224 336 320 400 320"
                    style={{
                      fill: "none",
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeWidth: 32,
                    }}
                  />
                  <circle
                    cx={432}
                    cy={320}
                    r={32}
                    style={{
                      fill: "none",
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeWidth: 32,
                    }}
                  />
                </svg>
              </div>
              <div className="message-text">
                <h3>No videos found!</h3>
                <p>Oops. It looks like there is no videos on your channel yet to display. To post a new video, click the button below!</p>
                {loginInfo ? (
                  <NavLink to="/upload-video">
                    <Button className="btn-custom text-uppercase main-outline">Upload my first video!</Button>
                  </NavLink>
                ) : (
                  <NavLink to="/reauth">
                    <Button className="btn-custom text-uppercase main-outline">Upload my first video!</Button>
                  </NavLink>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Trending;
