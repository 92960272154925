import React, {useEffect, useRef, useState} from "react";
import {NavLink, useNavigate, Link} from "react-router-dom";
import "./history.scss";
import Header from "../../include/Header";
import Sidebar from "../../include/Sidebar";
import Footer from "../../include/Footer";
import Button from "react-bootstrap/Button";
import {useLanguage} from "../../utils/LanguageProvider";
import {getWatchHistoryList, deleteHistoryApi} from "../../services/ApiService";
import GlobalSuccessModal from "../../include/GlobalSuccessModal";

const thumbnail = "https://diakanext.org/upload/images/2023/06/ukmVudQ8LvHAVWowILTz_28_b394842e90a83ee71be0c59fda2c702f_thumbnail_1280x720.jpg";

const History = () => {
  const {langJson} = useLanguage();
  const [allDetails, setAllDetails] = useState([]);

  const [isHovered, setIsHovered] = useState(false);
  const [currentPage, setCurrentPage] = useState(1); // For load more
  const [loading, setLoading] = useState(false); // For show load more buttton
  const [videoResponseConnt, setVideoResponseConnt] = useState(); // For show load more buttton
  const [videoLimit] = useState(16); // For video limit
  const [selectedItems, setSelectedItems] = useState([]);
  const [checkedItems, setCheckedItems] = useState([]);
  const [userDetail] = useState(JSON.parse(localStorage.getItem("loginInfo"))?.user); // Set the login user id
  const [modalShouldOpen, setModalShouldOpen] = useState(false); // For success modal
  const [modalOpenMessage, setModalOpenMessage] = useState(); // For success modal

  const getVideoList = async page => {
    setLoading(true);
    try {
      const formData = new FormData();
      //formData.append("user_id", userDetail?.id ? userDetail?.id : "");
      formData.append("page", page);
      formData.append("limit", videoLimit);
      let response = await getWatchHistoryList(formData);
      if (response) {
        if (response.data.res == 1) {
          setVideoResponseConnt(response.data.data.videos.length);
          let tempArray = [...allDetails];
          response.data.data.videos.map((item, index) => {
            tempArray.push(item);
          });
          setAllDetails(tempArray);
        }
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getVideoList(currentPage);
  }, [currentPage]);

  const onLoadMore = () => {
    setCurrentPage(prevCount => (prevCount += 1));
  };

  // Function to toggle item selection
  const toggleItemSelection = itemId => {
    if (selectedItems.includes(itemId)) {
      setSelectedItems(selectedItems.filter(id => id !== itemId));
    } else {
      setSelectedItems([...selectedItems, itemId]);
    }
  };

  // Function to handle the multi-delete action
  const deleteFunction = async () => {
    // Implement your logic here to delete the selected items.
    // You can use the `selectedItems` array to get the IDs of selected items.
    // console.log("Selected items to delete:", selectedItems);

    try {
      const formData = new FormData();
      formData.append("history", selectedItems);
      let response = await deleteHistoryApi(formData);
      if (response) {
        if (response.data.res == 1) {
          getVideoList(currentPage);
        }
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleSelectAllItems = value => {
    if (value) {
      const allIds = allDetails.map(item => parseInt(item.id));
      setCheckedItems(allIds);
    } else {
      setCheckedItems([]);
    }
  };

  const handleCheckboxChange = (value, id) => {
    if (value) {
      // If the checkbox is checked, add the item to the array
      setCheckedItems(prevItems => [...prevItems, id]);
    } else {
      // If the checkbox is unchecked, remove the item from the array
      setCheckedItems(prevItems => prevItems.filter(item => item !== id));
    }
  };

  const deleteHistory = async () => {
    try {
      const formData = new FormData();
      formData.append("history", JSON.stringify(checkedItems));
      let response = await deleteHistoryApi(formData);
      if (response) {
        if (response.data.res == 1) {
          if (checkedItems?.length === allDetails?.length) {
            setAllDetails([]);
          } else {
            const filteredHistoryList = allDetails.filter(item => !checkedItems.includes(parseInt(item?.id)));
            setAllDetails(filteredHistoryList);
          }
          setModalOpenMessage(response.data.msg);
          setModalShouldOpen(true);
          setTimeout(() => {
            setModalShouldOpen(false);
          }, 1000);
        } else {
          setAllDetails([]);
        }
      }
    } catch (error) {}
  };

  return (
    <div>
      <Header />
      <Sidebar />
      <div className="content-wrapper">
        <div className="content-header">
          <h1 className="text-uppercase">{langJson?.history}</h1>
        </div>
        <div className="content">
          <div className="history-list-header">
            <div className="flex-row d-flex">
              <div className="lp"></div>
              <div className="rp">
                {/* <Button className="btn-custom text-uppercase main-outline" disabled={selectedItems.length === 0} onClick={deleteFunction}> */}
                <Button className="btn-custom text-uppercase main-outline" disabled={checkedItems.length === 0} onClick={() => deleteHistory()}>
                  {langJson?.delete_selected}
                </Button>
                <div className="tos-agree-chbox">
                  <input
                    id="chbox-tos-agree"
                    type="checkbox"
                    className="filled-in chk-col-main"
                    onChange={e => handleSelectAllItems(e.target.checked)}
                    checked={!loading && allDetails.length === checkedItems?.length ? true : false}
                  />
                  <label htmlFor="chbox-tos-agree"></label>
                </div>
              </div>
            </div>
          </div>
          <div className="history-list-body">
            <ul className="history-list">
              {allDetails.map((item, index) => (
                <li className="d-flex">
                  <div className="thumbnail">
                    <img src={item.thumbnail} alt="Video Thumbnail" />
                    <span className="video-dur">{item.duration}</span>
                  </div>
                  <div className="item-mp">
                    <div className="video-data">
                      <a className="video-title">
                        <h4>
                          <NavLink to={`/watch/${item?.id}`}>{item?.title}</NavLink>
                        </h4>
                      </a>
                      <div className="uname">
                        <NavLink to={`/@${item?.username}`}>
                          {item?.fname} {item?.lname}
                        </NavLink>
                      </div>
                      <div className="quick-stats">
                        <span>
                          {item?.views} {langJson?.views} • {item?.pub_time}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="item-rp">
                    <time>{item?.time}</time>
                    <div className="tos-agree-chbox">
                      <input
                        id={`chbox-tos-agree-${item.id}`} // Use a unique identifier
                        type="checkbox"
                        className="filled-in chk-col-main"
                        onChange={e => handleCheckboxChange(e.target.checked, parseInt(item?.id))}
                        checked={checkedItems.includes(parseInt(item?.id))}
                        // checked={selectedItems.includes(item.id)} // Add checked attribute to reflect the selected state
                        // onChange={() => toggleItemSelection(item.id)} // Use onChange to handle checkbox changes
                      />
                      <label htmlFor={`chbox-tos-agree-${item.id}`}></label>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
            {!loading && videoResponseConnt >= videoLimit ? (
              <div className="ufx-load-more d-flex justify-content-center">
                <Button variant="outline-success" type="butto" className="btn-custom text-uppercase main-outline" onClick={() => onLoadMore()}>
                  {langJson?.load_more}
                </Button>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <Footer />
      <GlobalSuccessModal message={modalOpenMessage} show={modalShouldOpen} onClose={() => setModalShouldOpen(false)} />
    </div>
  );
};

export default History;
