import React, {useEffect, useState} from "react";
import {Link, NavLink, useParams} from "react-router-dom";
import "./PlayLists.scss";
import "../user-common.scss";
import AfterLoginHeader from "../../../include/AfterLoginHeader";
import AfterLoginSidebar from "../../../include/AfterLoginSidebar";
import ChannelBanner from "../../../include/channelBanner/ChannelBanner";
import Footer from "../../../include/Footer";
import {Modal, Button} from "react-bootstrap";
import {useLanguage} from "../../../utils/LanguageProvider";
import {deletePlaylistApi, getChannelDetailsApi, getPlaylistVideosApi} from "../../../services/ApiService";
import {Dropdown} from "react-bootstrap";

const PlayLists = props => {
  const {langJson} = useLanguage();
  const {username} = useParams();

  const [userDetail] = useState(JSON.parse(localStorage.getItem("loginInfo"))?.user); // Set the login user id
  const [videoLimit] = useState(16); // For video
  const [currentPage, setCurrentPage] = useState(1); // For load more
  const [loading, setLoading] = useState(false); // For show load more buttton
  const [videoResponseCount, setvideoResponseCount] = useState(); // For show load more buttton
  const [playlistVideosList, setPlaylistVideosList] = useState([]); // For store
  const [deleteVideosShow, setDeleteVideosShow] = useState(false);
  const [videoId, setVideoId] = useState();
  const [saveListShow, setSaveListShowShow] = useState(false);
  const [btnDis, setBtnDis] = useState(false); // For button disable

  const handleDeleteClose = () => setDeleteVideosShow(false);
  const handleDeleteShow = () => setDeleteVideosShow(true);

  // Fetching videos
  const fetchPlaylistVideos = async page => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("user_id", userDetail?.id ? userDetail?.id : "");
      formData.append("page", page);
      formData.append("limit", videoLimit);
      let response = await getPlaylistVideosApi(username.slice(1), formData);
      if (response) {
        if (response.data.res == 1) {
          setvideoResponseCount(response.data.data.length);
          let tempArray = [...playlistVideosList];
          response.data.data.map((item, index) => {
            tempArray.push(item);
          });
          setPlaylistVideosList(tempArray);
        } else {
          setPlaylistVideosList([]);
        }
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  // Delete videos
  const deletePlaylist = async () => {
    setBtnDis(true);
    try {
      let response = await deletePlaylistApi(videoId);
      if (response) {
        if (response.data.res == 1) {
          setvideoResponseCount(response.data.data.length);
          let tempArray = [...playlistVideosList];
          let updatedArray = tempArray.filter(item => parseInt(item?.id) !== parseInt(videoId));
          setPlaylistVideosList(updatedArray);
          handleDeleteClose();
        } else {
        }
        setBtnDis(false);
      }
    } catch (error) {
      setBtnDis(false);
    }
  };

  // Triggered after clicking load more button
  const onLoadMore = () => {
    setCurrentPage(prevCount => (prevCount += 1));
  };

  useEffect(() => {
    fetchPlaylistVideos();
  }, []);

  return (
    <div>
      <AfterLoginHeader />
      <AfterLoginSidebar />
      {/* <ChannelBanner /> */}
      <div className="content-wrapper channel-content-wrapper">
        <div className="content">
          <div className="content-block-header">
            <h1>{langJson?.play_lists}</h1>
          </div>
          {playlistVideosList && playlistVideosList?.length > 0 ? (
            <>
              <div className="recommended-videos">
                <ul className="row main-play-list playlistVideos">
                  {playlistVideosList &&
                    playlistVideosList?.map((item, index) => (
                      <li className="col-lg-3 col-md-4" key={index}>
                        <div className="video-grid-item">
                          <div className="video-poster">
                            <Link className to={`/watch/${item?.id}`}>
                              {/* <img src="/static/media/ukmVudQ8LvHAVWowILTz_28_b394842e90a83ee71be0c59fda2c702f_thumbnail_1280x720.566cfa0bf15e144f0927.jpg" alt /> */}
                              <img src={item?.thumbnail} alt />
                              <div className="playlists-overlay">
                                <div className="playlists-count">
                                  <span className="total-videos">1 </span>
                                  <span className="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width={512} height={512} viewBox="0 0 512 512">
                                      <path d="M64,384H448V341.33H64Zm0-106.67H448V234.67H64ZM64,128v42.67H448V128Z" />
                                    </svg>{" "}
                                  </span>
                                </div>
                              </div>
                            </Link>
                          </div>
                          <div className="video-description">
                            <div className="video-info">
                              <h6>
                                <a className href="/watch/0">
                                  {item?.title}
                                </a>
                              </h6>
                              <div className="video-meta-info">
                                <div className="uname">
                                  <a>{item?.u_name}</a>
                                </div>
                                <div className="quick-stats">
                                  <span>
                                    {item?.views} {langJson?.views} • {item?.time}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="controls">
                              <Dropdown>
                                <Dropdown.Toggle variant="link" id="dropdown-basic">
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                    aria-labelledby="appsAltIconTitle"
                                    stroke="#000000"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    fill="none"
                                    color="#000000"
                                    {...props}>
                                    <title id="appsAltIconTitle">{"Apps drawer"}</title>
                                    <rect x={5} y={5} width={2} height={2} />
                                    <rect x={11} y={5} width={2} height={2} />
                                    <rect x={17} y={5} width={2} height={2} />
                                    <rect x={5} y={11} width={2} height={2} />
                                    <rect x={11} y={11} width={2} height={2} />
                                    <rect x={17} y={11} width={2} height={2} />
                                    <rect x={5} y={17} width={2} height={2} />
                                    <rect x={11} y={17} width={2} height={2} />
                                    <rect x={17} y={17} width={2} height={2} />
                                  </svg>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  {/* {userDetail?.username === item?.username ? (
                                    <>
                                      <Button
                                        variant="link"
                                        className="dropdown-item"
                                        onClick={() => {
                                          handleDeleteShow();
                                          setVideoId(item?.id);
                                        }}>
                                        {langJson?.delete}
                                      </Button>
                                      <Link to={`/edit-video/${item?.id}`} className="dropdown-item">
                                        {langJson?.edit}
                                      </Link>
                                    </>
                                  ) : null} */}

                                  <Button variant="link" className="dropdown-item">
                                    {langJson?.play_all}
                                  </Button>
                                  {item?.type !== "system" && userDetail?.id === item?.user_id ? (
                                    <Dropdown.Item
                                      onClick={() => {
                                        setVideoId(item?.id);
                                        handleDeleteShow();
                                      }}>
                                      {langJson?.delete}
                                    </Dropdown.Item>
                                  ) : null}
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                </ul>
                {!loading && videoResponseCount >= videoLimit ? (
                  <div className="ufx-load-more d-flex">
                    <Button variant="outline-success" type="butto" className="btn-custom text-uppercase main-outline" onClick={() => onLoadMore()}>
                      {langJson?.load_more}
                    </Button>
                  </div>
                ) : null}
              </div>
            </>
          ) : !loading && playlistVideosList && playlistVideosList?.length === 0 ? (
            <div className="empty-page-placeholder">
              <div className="placeholder-message d-flex">
                <div className="icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width={512} height={512} viewBox="0 0 512 512" {...props}>
                    <path
                      d="M448,256c0-106-86-192-192-192S64,150,64,256s86,192,192,192S448,362,448,256Z"
                      style={{
                        fill: "none",
                        strokeMiterlimit: 10,
                        strokeWidth: 32,
                      }}
                    />
                    <path d="M216.32,334.44,330.77,265.3a10.89,10.89,0,0,0,0-18.6L216.32,177.56A10.78,10.78,0,0,0,200,186.87V325.13A10.78,10.78,0,0,0,216.32,334.44Z" />
                  </svg>
                </div>
                <div className="message-text">
                  <h3>{langJson?.no_videos_found}</h3>
                  <p>{langJson?.oops_it_looks_like_there_is_no_videos_on_your_channel_yet_to_disp}</p>
                  <NavLink to="/upload-video">
                    <Button className="btn-custom text-uppercase main-outline">{langJson?.upload_my_first_video}</Button>
                  </NavLink>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <Footer />

      {/* Delete Video Modal Start */}
      <Modal show={deleteVideosShow} onHide={handleDeleteClose} className="cmnModal deleteVideorModal">
        <Modal.Body>
          <h3>{langJson?.please_confirm_your_actions}</h3>
          <p>{langJson?.please_note_that_if_you_delete_this_playlist_all_information_asso}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" className="cancel-btn" onClick={handleDeleteClose}>
            {langJson?.cancel}
          </Button>
          <Button variant="primary" disabled={btnDis} onClick={() => deletePlaylist()}>
            {langJson?.delete}
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Delete Video Modal End */}
    </div>
  );
};

export default PlayLists;
