import React, {useState} from "react";
import "./DeleteAccount.scss";
import "../user-common.scss";
import AfterLoginHeader from "../../../include/AfterLoginHeader";
import AfterLoginSidebar2 from "../../../include/AfterLoginSidebar2";
import SettingsBanner from "../../../include/settingsBanner/SettingsBanner";
import Footer from "../../../include/Footer";
import {Button} from "react-bootstrap";
import {useLanguage} from "../../../utils/LanguageProvider";
import {deleteAccountApi} from "../../../services/ApiService";
import GlobalSuccessModal from "../../../include/GlobalSuccessModal";
import {onLogout} from "../../../utils/commonData";
import {useNavigate} from "react-router-dom";

const DeleteAccount = props => {
  const {langJson} = useLanguage();
  const navigate = useNavigate();
  const [password, setPassword] = useState();
  const [passwordError, setPasswordError] = useState();
  const [modalShouldOpen, setModalShouldOpen] = useState(false); // For success modal
  const [modalOpenMessage, setModalOpenMessage] = useState(); // For success modal
  const [loading, setLoading] = useState(false); // For publish button login disable

  const deleteAccount = async () => {
    if (!password) {
      setPasswordError(langJson?.please_enter_password);
    } else {
      setLoading(true);
      try {
        const formData = new FormData();
        formData.append("password", password);
        let response = await deleteAccountApi(formData);
        if (response) {
          if (response.data.res == 1) {
            setModalOpenMessage(response.data.msg);
            setModalShouldOpen(true);
            setPasswordError(null);
            setPassword(null);
            setTimeout(() => {
              setModalShouldOpen(false);
              onLogout();
              navigate("/");
            }, 1000);
          } else {
            setPasswordError(response.data.msg);
          }
          setLoading(false);
        }
      } catch (error) {
        setPasswordError(error.response.data.msg);
        setLoading(false);
      }
    }
  };

  return (
    <div>
      <AfterLoginHeader />
      <AfterLoginSidebar2 />
      <div className="content-wrapper">
        <div className="content">
          <div className="content-block-header">
            <h1>{langJson?.account_settings}</h1>
          </div>
          <div className="white-settings-section">
            <SettingsBanner />
            <div className="seetings-bottom-section">
              <h3>{langJson?.delete_account}</h3>
              <ul className="row setting-form-list">
                <li className="col-md-12">
                  <div className="form-group">
                    <div className="info-feedback">{langJson?.please_note_that_if_you_delete_your_account_then_all_your_videos_}</div>
                  </div>
                </li>
                <li className="col-md-12">
                  <label className="form-label">{langJson?.your_password}</label>
                  <input
                    type="password"
                    className="form-control"
                    placeholder={langJson?.enter_your_password}
                    onChange={e => setPassword(e.target.value)}
                    value={password}
                  />
                  {passwordError && <span className="error-message">{passwordError}</span>}
                </li>
                <li className="col-md-12">
                  <div className="d-grid gap-2">
                    <Button className="btn-custom main-inline btn-block" onClick={() => deleteAccount()} disabled={loading}>
                      {langJson?.delete_my_account}
                    </Button>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <GlobalSuccessModal message={modalOpenMessage} show={modalShouldOpen} onClose={() => setModalShouldOpen(false)} />
    </div>
  );
};

export default DeleteAccount;
