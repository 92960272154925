import React, {useState} from "react";
import {Modal, Button} from "react-bootstrap";
import ProgressBar from "react-bootstrap/ProgressBar";
import {useLanguage} from "../utils/LanguageProvider";

function GlobalSuccessImageModal({uploadProgress, show, onClose}) {
  const {langJson} = useLanguage();

  return (
    <Modal show={show} onHide={onClose} className="cmnModal progressBarModal">
      {/* <Modal.Header closeButton>
        <Modal.Title>Success</Modal.Title>
      </Modal.Header> */}
      <Modal.Body>
        <h3>
          {langJson?.uploading_image} ({Math.round(uploadProgress)}%) <span>{langJson?.please_wait}...</span>
        </h3>
        {/* <progress id="myProgressBar" value={uploadProgress} max={100} className="myProgressBar" /> */}
        <ProgressBar now={uploadProgress} />
      </Modal.Body>
      {/* <Modal.Footer>
        <Button variant="primary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer> */}
    </Modal>
  );
}

export default GlobalSuccessImageModal;
