import EnglishLang from "../assets/langs/english.json";
import FrenchLang from "../assets/langs/french.json";
import GermanLang from "../assets/langs/german.json";
import ItalianLang from "../assets/langs/italian.json";
import RussianLang from "../assets/langs/russian.json";
import PortugueseLang from "../assets/langs/portuguese.json";
import SpanishLang from "../assets/langs/spanish.json";
import TurkishLang from "../assets/langs/turkish.json";
import DutchLang from "../assets/langs/dutch.json";
import UkraineLang from "../assets/langs/ukraine.json";

export const ChooseLanguage = () => {
  let lang = "en";
  let selectLangJson = EnglishLang;
  let appLang = localStorage.getItem("appLanguage");
  if (appLang) {
    lang = appLang;
    switch (lang) {
      case "es":
        selectLangJson = SpanishLang;
        break;
      case "de":
        selectLangJson = GermanLang;
        break;
      case "it":
        selectLangJson = ItalianLang;
        break;
      case "fr":
        selectLangJson = FrenchLang;
        break;
      case "ru":
        selectLangJson = RussianLang;
        break;
      case "tr":
        selectLangJson = TurkishLang;
        break;
      case "pt":
        selectLangJson = PortugueseLang;
        break;
      case "nl":
        selectLangJson = DutchLang;
        break;
      case "ua":
        selectLangJson = UkraineLang;
        break;
      default:
        break;
    }
    // if (lang === 'es') {
    //   selectLangJson = SpanishLang;
    // } else
  } else {
    selectLangJson = EnglishLang;
  }
  // console.log(lang);
  return {lang, selectLangJson};
};
