import React, {useEffect, useState} from "react";
import {NavLink} from "react-router-dom";
import {Tooltip as ReactTooltip} from "react-tooltip";
import "./Sidebar.scss";
import {useLanguage} from "../utils/LanguageProvider";

const Sidebar = props => {
  const {langJson} = useLanguage();
  const [loginInfo, setLoginInfo] = useState("");

  useEffect(() => {
    let value = JSON.parse(localStorage.getItem("loginInfo"));
    if (value != undefined) {
      setLoginInfo(value);
      console.log(value?.user?.username);
    }
  }, []);

  return (
    <div>
      <div className="sidebar d-flex">
        <ul className="navbar-nav">
          <li>
            <NavLink to="/" data-tooltip-id="Homepage">
              <svg
                width="800px"
                height="800px"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
                strokeWidth=" 1.8"
                strokeLinecap="square"
                strokeLinejoin="miter"
                fill="none">
                <path
                  d="M22 20V12.1735C22 11.0734 21.5469 10.0218 20.7473 9.26624L13.3737 2.29812C12.6028 1.56962 11.3972 1.56962 10.6263 2.29812L3.25265 9.26624C2.45308 10.0218 2 11.0734 2 12.1735V20C2 21.1046 2.89543 22 4 22H20C21.1046 22 22 21.1046 22 20Z"
                  stroke="#fff"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </NavLink>
            <ReactTooltip id="Homepage" place="left" content={langJson?.homepage} />
          </li>
          <li>
            <NavLink to="/trending" data-tooltip-id="trending">
              <svg
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                aria-labelledby="activityIconTitle"
                strokeWidth={1.8}
                strokeLinecap="square"
                strokeLinejoin="miter"
                fill="none"
                {...props}>
                <polyline points="21 14 18 14 15 7 10 17 7 11 5 14 3 14" />
              </svg>
            </NavLink>
            <ReactTooltip id="trending" place="left" content={langJson?.trending} />
          </li>
          <li>
            <NavLink to="/channels" data-tooltip-id="channels">
              <svg
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                aria-labelledby="radioIconTitle"
                strokeWidth={1.8}
                strokeLinecap="square"
                strokeLinejoin="miter"
                fill="none"
                {...props}>
                <path d="M5.44972845 6C2.18342385 9.2663046 2.18342385 14.7336954 5.44972845 18M8.59918369 8C6.46693877 10.1322449 6.46693877 13.8677551 8.59918369 16M18.5502716 18C21.8165761 14.7336954 21.8165761 9.2663046 18.5502716 6M15.4008163 16C17.5330612 13.8677551 17.5330612 10.1322449 15.4008163 8" />
                <circle cx={12} cy={12} r={1} />
              </svg>
            </NavLink>
            <ReactTooltip id="channels" place="left" content={langJson?.channels} />
          </li>
          <li>
            <NavLink to="/explore" data-tooltip-id="explore">
              <svg
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                aria-labelledby="exploreIconTitle"
                stroke="#fff"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="none"
                color="#000000">
                <title id="exploreIconTitle">Explore</title>
                <polygon points="14.121 14.121 7.05 16.95 9.879 9.879 16.95 7.05" />
                <circle cx="12" cy="12" r="10" />
              </svg>
            </NavLink>
            <ReactTooltip id="explore" place="left" content={langJson?.explore} />
          </li>
          {loginInfo ? (
            <li>
              <NavLink to={`@${loginInfo?.user?.username}/playlists`} data-tooltip-id="playlist">
                <svg
                  width="25px"
                  height="25px"
                  viewBox="0 0 25 25"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-labelledby="listViewIconTitle"
                  stroke="#fff"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  fill="none"
                  color="#000000">
                  <title id="listViewIconTitle">List view</title>
                  <path d="M5.208 5.208h14.583" />
                  <path d="M5.208 19.792h14.583" />
                  <path x="5" y="9" width="14" height="6" d="M5.208 9.375H19.792V15.625H5.208V9.375z" />
                </svg>
              </NavLink>
              <ReactTooltip id="playlist" place="left" content={langJson?.playlist} />
            </li>
          ) : (
            <li>
              <NavLink to="/playlist" data-tooltip-id="playlist">
                <svg
                  width="25px"
                  height="25px"
                  viewBox="0 0 25 25"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-labelledby="listViewIconTitle"
                  stroke="#fff"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  fill="none"
                  color="#000000">
                  <title id="listViewIconTitle">List view</title>
                  <path d="M5.208 5.208h14.583" />
                  <path d="M5.208 19.792h14.583" />
                  <path x="5" y="9" width="14" height="6" d="M5.208 9.375H19.792V15.625H5.208V9.375z" />
                </svg>
              </NavLink>
              <ReactTooltip id="playlist" place="left" content={langJson?.playlist} />
            </li>
          )}

          <li>
            <NavLink to="/history" data-tooltip-id="history">
              <svg
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                aria-labelledby="historyIconTitle"
                strokeWidth={1.8}
                strokeLinecap="square"
                strokeLinejoin="miter"
                fill="none"
                {...props}>
                <polyline points="1 12 3 14 5 12" />
                <polyline points="12 7 12 12 15 15" />
                <path d="M12,21 C16.9705627,21 21,16.9705627 21,12 C21,7.02943725 16.9705627,3 12,3 C7.02943725,3 3,7.02943725 3,12 C3,11.975305 3,12.3086383 3,13" />
              </svg>
            </NavLink>
            <ReactTooltip id="history" place="left" content={langJson?.history} />
          </li>
        </ul>
        <NavLink to="/upload-video" className="add-video-link" data-tooltip-id="uploadVideo">
          <svg
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 32 32"
            enableBackground="new 0 0 32 32"
            xmlSpace="preserve"
            {...props}>
            <rect x={10} y={15} width={12} height={2} />
            <rect x={15} y={10} width={2} height={12} />
            <circle fill="none" strokeWidth={2} strokeMiterlimit={10} cx={16} cy={16} r={12} />
          </svg>
        </NavLink>
        <ReactTooltip id="uploadVideo" place="left" content={langJson?.upload_new_video} />
      </div>
    </div>
  );
};

export default Sidebar;
