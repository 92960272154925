import {Routes, Route, Outlet} from "react-router-dom";
import "./App.scss";
import Home from "./pages/home/Home";
import Player from "./pages/player/Player";
import Trending from "./pages/trending/Trending";
import Channels from "./pages/channels/Channels";
import Explore from "./pages/explore/Explore";
import Playlist from "./pages/playlist/Playlist";
import History from "./pages/history/History";
import MyChannel from "./pages/user/channel/MyChannel";
import PlayLists from "./pages/user/playlists/PlayLists";
import Subcriptions from "./pages/user/subcriptions/Subcriptions";
import Liked from "./pages/user/liked/Liked";
import About from "./pages/user/about/About";
import Products from "./pages/user/products/Products";
import Dashboard from "./pages/user/dashboard/Dashboard";
import Upload from "./pages/user/upload-video/Upload";
import ImportVideo from "./pages/user/import-video/ImportVideo";
import GeneralSettings from "./pages/user/general-settings/GeneralSettings";
import PasswordSettings from "./pages/user/password-settings/PasswordSettings";
import LinksSettings from "./pages/user/links-settings/LinksSettings";
import DeleteAccount from "./pages/user/delete-account/DeleteAccount";
import Notifications from "./pages/user/notifications/Notifications";
import AboutUs from "./pages/cms/AboutUs";
import Terms from "./pages/cms/Terms";
import PrivacyPolicy from "./pages/cms/PrivacyPolicy";
import Faqs from "./pages/cms/Faqs";
import Blog from "./pages/cms/Blog";
import BlogDetails from "./pages/cms/BlogDetails";
import Reauth from "./pages/reauth/Reauth";
import Auth from "./ProtectedRoutes/Auth";
import EditVideo from "./pages/user/edit-video/EditVideo";
import CreateProduct from "./pages/user/create-product/CreateProduct";
import ChannelLayout from "./include/ChannelLayout";
import EditProduct from "./pages/user/edit-product/EditProduct";
import Page404 from "./pages/page404/Page404";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/reauth" element={<Reauth />} />

      {/* <Route element={<Auth />}> */}
      <Route path="/watch/:videoId" element={<Player />} />
      <Route path="/trending" element={<Trending />} />
      <Route path="/channels" element={<Channels />} />
      <Route path="/explore" element={<Explore />} />
      <Route path="/playlist" element={<Playlist />} />
      <Route path="/history" element={<History />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/import-video" element={<ImportVideo />} />
      <Route path="/general-settings" element={<GeneralSettings />} />
      <Route path="/password-settings" element={<PasswordSettings />} />
      <Route path="/links-settings" element={<LinksSettings />} />
      <Route path="/delete-account" element={<DeleteAccount />} />
      <Route path="/notifications" element={<Notifications />} />
      <Route path="/aboutus" element={<AboutUs />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/privacy" element={<PrivacyPolicy />} />
      <Route path="/faqs" element={<Faqs />} />
      <Route path="/blog" element={<Blog />} />
      <Route path="/blog-details/:blogId" element={<BlogDetails />} />
      <Route path="/404" element={<Page404 />} />
      {/* </Route> */}
      <Route
        path="/:username/*"
        element={
          <ChannelLayout>
            <Outlet />
          </ChannelLayout>
        }>
        <Route index element={<MyChannel />} />
        <Route path="playlists" element={<PlayLists />} />
        <Route path="subscriptions" element={<Subcriptions />} />
        <Route path="liked" element={<Liked />} />
        <Route path="about" element={<About />} />
        <Route path="products" element={<Products />} />
      </Route>

      <Route element={<Auth />}>
        <Route path="/create-product" element={<CreateProduct />} />
        <Route path="/edit-product/:id" element={<EditProduct />} />
        <Route path="/upload-video" element={<Upload />} />
        <Route path="/edit-video/:id" element={<EditVideo />} />
      </Route>
    </Routes>
  );
}

export default App;
