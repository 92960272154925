import React, {useEffect, useState} from "react";
import "./Notifications.scss";
import AfterLoginHeader from "../../../include/AfterLoginHeader";
import AfterLoginSidebar2 from "../../../include/AfterLoginSidebar2";
import Footer from "../../../include/Footer";
import Button from "react-bootstrap/Button";
import {useLanguage} from "../../../utils/LanguageProvider";
import {deleteNotificationsApi, getNotificationsApi, notificationUpdatesApi} from "../../../services/ApiService";
import {Link} from "react-router-dom";
import GlobalSuccessModal from "../../../include/GlobalSuccessModal";
import {BASE_URL} from "../../../app_url";

const avatar = BASE_URL + "static/" + "themes/default/img/avatar.png";

const Notifications = props => {
  const {langJson, setNotificationsCount} = useLanguage();

  const ListItems = [
    {id: 1, name: "test"},
    {id: 2, name: "test1"},
    {id: 3, name: "test2"},
  ];

  const [checkedItems, setCheckedItems] = useState([]);
  const [notificationsList, setNotificationsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userDetail] = useState(JSON.parse(localStorage.getItem("loginInfo"))?.user); // Set the login user id
  const [modalShouldOpen, setModalShouldOpen] = useState(false); // For success modal
  const [modalOpenMessage, setModalOpenMessage] = useState(); // For success modal

  const handleCheckboxChange = (value, id) => {
    if (value) {
      // If the checkbox is checked, add the item to the array
      setCheckedItems(prevItems => [...prevItems, id]);
    } else {
      // If the checkbox is unchecked, remove the item from the array
      setCheckedItems(prevItems => prevItems.filter(item => item !== id));
    }
  };

  const handleSelectAllItems = value => {
    if (value) {
      const allIds = notificationsList.map(item => item.id);
      setCheckedItems(allIds);
    } else {
      setCheckedItems([]);
    }
  };

  const deleteNotifications = async () => {
    try {
      const formData = new FormData();
      // checkedItems.map(item => {
      //   formData.append("noti_id", item);
      // });
      formData.append("noti_id", JSON.stringify(checkedItems));
      let response = await deleteNotificationsApi(formData);
      if (response) {
        if (response.data.res == 1) {
          if (checkedItems?.length === notificationsList?.length) {
            setNotificationsList([]);
          } else {
            const filteredNotificationsList = notificationsList.filter(item => !checkedItems.includes(item?.id));
            setNotificationsList(filteredNotificationsList);
          }
          setModalOpenMessage(response.data.msg);
          setModalShouldOpen(true);
          setTimeout(() => {
            setModalShouldOpen(false);
          }, 1000);
        } else {
          setNotificationsList([]);
        }
      }
    } catch (error) {}
  };

  const fetchNotifications = async () => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("user_id", userDetail?.id);
      let response = await getNotificationsApi(formData);
      if (response) {
        if (response.data.res == 1) {
          // setvideoResponseCount(response.data.data.length);
          let tempArray = [...notificationsList];
          response.data.data.map((item, index) => {
            tempArray.push(item);
          });

          setNotificationsList(tempArray);
        } else {
          setNotificationsList([]);
        }
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const changeNotificationStatus = async () => {
    try {
      const formData = new FormData();
      formData.append("recipient_id", userDetail?.id);
      let response = await notificationUpdatesApi(formData);
      if (response) {
        if (response.data.res == 1) {
          setNotificationsCount(0);
        } else {
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchNotifications();
    changeNotificationStatus();
  }, []);

  return (
    <div>
      <AfterLoginHeader />
      <AfterLoginSidebar2 />
      <div className="content-wrapper">
        <div className="content-header">
          <h1 className="text-uppercase">{langJson?.notifications}</h1>
        </div>
        <div className="content">
          <div className="history-list-header">
            <div className="flex-row d-flex">
              <div className="lp">
                <h4>
                  {langJson?.your_notifications} - (<span>{notificationsList?.length ? notificationsList?.length : 0}</span>)
                </h4>
              </div>
              {notificationsList?.length > 0 ? (
                <div className="rp">
                  <Button
                    className="btn-custom text-uppercase main-outline"
                    disabled={checkedItems?.length === 0 ? true : false}
                    onClick={() => deleteNotifications()}>
                    {langJson?.delete_selected}
                  </Button>
                  <div className="tos-agree-chbox">
                    <input
                      id="chbox-tos-agree"
                      className="filled-in chk-col-main"
                      type="checkbox"
                      onChange={e => handleSelectAllItems(e.target.checked)}
                      checked={!loading && notificationsList.length === checkedItems?.length ? true : false}
                    />
                    <label htmlFor="chbox-tos-agree"></label>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <div className="history-list-body">
            <ul className="history-list">
              {notificationsList?.map((item, index) => (
                <li className="d-flex notifications-list-item">
                  <div className="avatar">
                    <img src={item?.avatar} alt="" />
                  </div>
                  <div className="item-mp">
                    <div className="notif-data">
                      <Link className="uname" to={`/${item?.username}`}>
                        {item?.fname} {item?.lname}
                      </Link>
                      {item?.subject === "comment" ? (
                        <Link className="subject-url" to={`/watch/${item?.video_id}`}>
                          <span className="notif-msg seen">{langJson?.commented_on_your_video}</span>
                          <svg xmlns="http://www.w3.org/2000/svg" width={512} height={512} viewBox="0 0 512 512" {...props}>
                            <path
                              d="M384,224V408a40,40,0,0,1-40,40H104a40,40,0,0,1-40-40V168a40,40,0,0,1,40-40H271.48"
                              style={{
                                fill: "none",
                                strokeLinecap: "round",
                                strokeLinejoin: "round",
                                strokeWidth: 32,
                              }}
                            />
                            <polyline
                              points="336 64 448 64 448 176"
                              style={{
                                fill: "none",
                                strokeLinecap: "round",
                                strokeLinejoin: "round",
                                strokeWidth: 32,
                              }}
                            />
                            <line
                              x1={224}
                              y1={288}
                              x2={440}
                              y2={72}
                              style={{
                                fill: "none",
                                strokeLinecap: "round",
                                strokeLinejoin: "round",
                                strokeWidth: 32,
                              }}
                            />
                          </svg>
                        </Link>
                      ) : item?.subject === "subscription" ? (
                        <a className="subject-url">
                          <span className="notif-msg seen">{langJson?.subscribed_to_your_channel}.</span>
                        </a>
                      ) : item?.subject === "publication" ? (
                        <a className="subject-url">
                          <span className="notif-msg seen">{langJson?.published_a_new_video}.</span>
                        </a>
                      ) : item?.subject === "video_like" ? (
                        <Link className="subject-url" to={`/watch/${item?.video_id}`}>
                          <span className="notif-msg seen">{langJson?.liked_your_video}</span>
                          <svg xmlns="http://www.w3.org/2000/svg" width={512} height={512} viewBox="0 0 512 512" {...props}>
                            <path
                              d="M384,224V408a40,40,0,0,1-40,40H104a40,40,0,0,1-40-40V168a40,40,0,0,1,40-40H271.48"
                              style={{
                                fill: "none",
                                strokeLinecap: "round",
                                strokeLinejoin: "round",
                                strokeWidth: 32,
                              }}
                            />
                            <polyline
                              points="336 64 448 64 448 176"
                              style={{
                                fill: "none",
                                strokeLinecap: "round",
                                strokeLinejoin: "round",
                                strokeWidth: 32,
                              }}
                            />
                            <line
                              x1={224}
                              y1={288}
                              x2={440}
                              y2={72}
                              style={{
                                fill: "none",
                                strokeLinecap: "round",
                                strokeLinejoin: "round",
                                strokeWidth: 32,
                              }}
                            />
                          </svg>
                        </Link>
                      ) : item?.subject === "comment_like" ? (
                        <Link className="subject-url" to={`/watch/${item?.video_id}`}>
                          <span className="notif-msg seen">{langJson?.liked_your_comment}</span>
                          <svg xmlns="http://www.w3.org/2000/svg" width={512} height={512} viewBox="0 0 512 512" {...props}>
                            <path
                              d="M384,224V408a40,40,0,0,1-40,40H104a40,40,0,0,1-40-40V168a40,40,0,0,1,40-40H271.48"
                              style={{
                                fill: "none",
                                strokeLinecap: "round",
                                strokeLinejoin: "round",
                                strokeWidth: 32,
                              }}
                            />
                            <polyline
                              points="336 64 448 64 448 176"
                              style={{
                                fill: "none",
                                strokeLinecap: "round",
                                strokeLinejoin: "round",
                                strokeWidth: 32,
                              }}
                            />
                            <line
                              x1={224}
                              y1={288}
                              x2={440}
                              y2={72}
                              style={{
                                fill: "none",
                                strokeLinecap: "round",
                                strokeLinejoin: "round",
                                strokeWidth: 32,
                              }}
                            />
                          </svg>
                        </Link>
                      ) : null}
                    </div>
                  </div>
                  <div className="item-rp">
                    <time>{item?.datetime}</time>
                    <div className="tos-agree-chbox">
                      <input
                        id={`chbox-tos-agree-${index}`}
                        type="checkbox"
                        onChange={e => handleCheckboxChange(e.target.checked, parseInt(item?.id))}
                        className="filled-in chk-col-main"
                        checked={checkedItems.includes(parseInt(item?.id))}
                      />
                      <label htmlFor={`chbox-tos-agree-${index}`}></label>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <Footer />
      <GlobalSuccessModal message={modalOpenMessage} show={modalShouldOpen} onClose={() => setModalShouldOpen(false)} />
    </div>
  );
};

export default Notifications;
