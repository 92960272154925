import axios from "axios";
import {BASE_URL} from "../app_url";

const getHeader = () => {
  let value = JSON.parse(localStorage.getItem("loginInfo"));
  let header = {
    headers: {
      Authorization: `Bearer ${value["token"]}`,
    },
  };
  return header;
};

export const loginApi = values => {
  return axios.post(BASE_URL + `signup`, values);
};

export const getUserDetails = values => {
  return axios.post(BASE_URL + `userdetailsbyid`, values, getHeader());
};

export const getUserDetailsbyUsername = values => {
  return axios.post(BASE_URL + `userdetailsbyusername`, values);
};

export const getVideoCategories = () => {
  return axios.get(BASE_URL + `categories/`);
};

export const getVideosList = () => {
  return axios.get(BASE_URL + `videos/`, getHeader());
};

export const saveVideo = values => {
  return axios.post(BASE_URL + `videos/save`, values, getHeader());
};

export const updateVideo = (id, values) => {
  return axios.put(BASE_URL + `videos/${id}`, values, getHeader());
};

export const uploadVideo = (values, onUploadProgress) => {
  const headers = {
    ...getHeader().headers, // Merge the Authorization header
    "Content-Type": "multipart/form-data", // Add your custom headers here
  };
  return axios.post(BASE_URL + `videos/file`, values, {
    headers,
    onUploadProgress,
  });
};

export const uploadPoster = (values, onUploadProgress) => {
  const headers = {
    ...getHeader().headers, // Merge the Authorization header
    "Content-Type": "multipart/form-data", // Add your custom headers here
  };
  return axios.post(BASE_URL + `videos/poster`, values, {
    headers,
    onUploadProgress,
  });
};

export const getHomeVideoList = values => {
  return axios.post(BASE_URL + `videos/recommended`, values);
};

export const getWatchHistoryList = values => {
  return axios.post(BASE_URL + `settings/watch_history`, values, getHeader());
};

export const getChannelDraft = () => {
  return axios.get(BASE_URL + `videos/draft`, getHeader());
};

export const deleteVideoPoster = id => {
  return axios.delete(BASE_URL + `videos/poster/${id}`, getHeader());
};

export const deleteVideoFile = id => {
  return axios.delete(BASE_URL + `videos/file/${id}`, getHeader());
};

export const getVideoDetails = (id, values) => {
  return axios.post(BASE_URL + `videos/${id}`, values);
};

export const getVideoDetailsForEdit = id => {
  return axios.get(BASE_URL + `videos/${id}`, getHeader());
};

export const getVideoRelated = (id, values) => {
  return axios.post(BASE_URL + `videos/${id}/related`, values);
};

export const deleteVideoDetails = id => {
  return axios.delete(BASE_URL + `videos/${id}`, getHeader());
};

export const importVideoApi = values => {
  return axios.post(BASE_URL + `videos/import`, values, getHeader());
};

export const importVideoDraftApi = values => {
  return axios.get(BASE_URL + `videos/import/draft`, getHeader());
};

export const importVideoSaveApi = values => {
  return axios.post(BASE_URL + `videos/upsert`, values, getHeader());
};

export const getChannelDetailsApi = (id, values) => {
  return axios.post(BASE_URL + `channels/${id}`, values);
};

export const getChannelLikedApi = (id, values) => {
  return axios.post(BASE_URL + `channels/${id}/liked`, values);
};

export const getChannelSubscriptionsApi = id => {
  return axios.get(BASE_URL + `channels/${id}/subscriptions`);
};

export const getChannelAboutApi = id => {
  return axios.get(BASE_URL + `channels/${id}/about`);
};

export const getChannelProductsApi = (id, values) => {
  return axios.post(BASE_URL + `channels/${id}/products`, values);
};

export const getProductsDraftApi = id => {
  return axios.get(BASE_URL + `channels/products/draft`, getHeader());
};

export const uploadProductImage = (values, onUploadProgress) => {
  const headers = {
    ...getHeader().headers, // Merge the Authorization header
    "Content-Type": "multipart/form-data", // Add your custom headers here
  };
  return axios.post(BASE_URL + `channels/products/poster`, values, {
    headers,
    onUploadProgress,
  });
};

export const saveProduct = values => {
  return axios.post(BASE_URL + `channels/products/save`, values, getHeader());
};

export const deleteProductImageApi = id => {
  return axios.delete(BASE_URL + `channels/products/poster/${id}`, getHeader());
};

export const deleteProductApi = id => {
  return axios.delete(BASE_URL + `channels/products/${id}`, getHeader());
};

export const getProductDetailsApi = id => {
  return axios.get(BASE_URL + `channels/products/${id}`, getHeader());
};

export const updateProductDetailsApi = (id, values) => {
  return axios.put(BASE_URL + `channels/products/${id}`, values, getHeader());
};

export const getChannelDashboardApi = () => {
  return axios.get(BASE_URL + `channels/dashboard`, getHeader());
};

export const getPlaylistVideosApi = (username, values) => {
  return axios.post(BASE_URL + `channels/${username}/playlists`, values);
};

export const createPlaylistApi = values => {
  return axios.post(BASE_URL + `settings/playlists`, values, getHeader());
};

export const createReportApi = values => {
  return axios.post(BASE_URL + `settings/report_video`, values, getHeader());
};

export const createRiceApi = values => {
  return axios.post(BASE_URL + `settings/raise_ticket`, values, getHeader());
};

export const getRiportlistApi = values => {
  return axios.get(BASE_URL + `settings/report_types`, getHeader());
};

export const getPlaylistApi = values => {
  return axios.get(BASE_URL + `settings/playlists`, getHeader());
};

export const addToPlaylistApi = values => {
  return axios.post(BASE_URL + `settings/playlists/addto_playlist`, values, getHeader());
};

export const deletePlaylistApi = id => {
  return axios.delete(BASE_URL + `settings/playlists/${id}`, getHeader());
};

export const deleteAccountApi = values => {
  return axios.post(BASE_URL + `settings/delete_account`, values, getHeader());
};

export const addLike = values => {
  return axios.post(BASE_URL + `watch/like`, values);
};

export const addSubscriptions = values => {
  return axios.post(BASE_URL + `watch/subscriptions`, values);
};

export const getCommentVideos = values => {
  return axios.post(BASE_URL + `watch/get_comment_videos`, values);
};

export const addCommentApi = values => {
  return axios.post(BASE_URL + `watch/comment`, values);
};

export const addCommentReplyApi = values => {
  return axios.post(BASE_URL + `watch/replycomment`, values);
};

export const deleteCommentApi = values => {
  return axios.post(BASE_URL + `watch/deletecomment`, values);
};

export const commentLikeApi = values => {
  return axios.post(BASE_URL + `watch/update_comment_like`, values);
};

export const getNotificationsApi = values => {
  return axios.post(BASE_URL + `watch/notification_user_id`, values);
};

export const deleteNotificationsApi = values => {
  return axios.post(BASE_URL + `watch/delete_user_notification`, values);
};

export const notificationUpdatesApi = values => {
  return axios.post(BASE_URL + `watch/notification_update`, values);
};

export const unreadNotificationCountApi = values => {
  return axios.post(BASE_URL + `watch/unread_notification_count`, values);
};

export const blogListApi = () => {
  return axios.get(BASE_URL + `watch/post_list_front`);
};

export const blogDetailsApi = values => {
  return axios.post(BASE_URL + `watch/post_list_details_byid`, values);
};

export const blogAddCommentApi = values => {
  return axios.post(BASE_URL + `watch/post_comment`, values);
};

export const blogFetchCommentApi = values => {
  return axios.post(BASE_URL + `watch/get_post_comment`, values);
};

export const blogCommentDeleteApi = values => {
  return axios.post(BASE_URL + `watch/deletepostcomment`, values);
};

export const blogCommentReplyApi = values => {
  return axios.post(BASE_URL + `watch/replypostcomment`, values);
};

export const blogCommentReplyLikeApi = values => {
  return axios.post(BASE_URL + `watch/update_post_comment_like`, values);
};

export const headerSearchApi = values => {
  return axios.post(BASE_URL + `settings/quick_search`, values);
};

export const advanceSearchApi = values => {
  return axios.post(BASE_URL + `settings/search`, values);
};

export const deleteHistoryApi = values => {
  return axios.post(BASE_URL + `settings/delete_history`, values, getHeader());
};
