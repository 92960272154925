import React, {useState, useEffect, useRef} from "react";
import {NavLink} from "react-router-dom";
import {Tooltip as ReactTooltip} from "react-tooltip";
import "./AfterLoginSidebar.scss";
import {useLanguage} from "../utils/LanguageProvider";

const AfterLoginSidebar = props => {
  const sidebarRef = useRef(null);
  const {langJson} = useLanguage();
  const [loginData, setLoginData] = useState();

  useEffect(() => {
    let value = JSON.parse(localStorage.getItem("loginInfo"));
    if (value) {
      setLoginData(value["user"]);
    }
  }, []);

  useEffect(() => {
    const select = (el, all = false) => {
      el = el.trim();
      if (all) {
        return [...document.querySelectorAll(el)];
      } else {
        return document.querySelector(el);
      }
    };

    const on = (type, el, listener, all = false) => {
      if (all) {
        select(el, all).forEach(e => e.addEventListener(type, listener));
      } else {
        select(el, all).addEventListener(type, listener);
      }
    };

    const handleBodyClick = e => {
      console.log("handleBodyClick triggered");
      // Check if the clicked element is outside the sidebar
      if (sidebarRef.current && !sidebarRef.current.contains(e.target)) {
        select("body").classList.remove("toggle-sidebar");
      }
    };

    on("click", ".close-sidebar-btn", function (e) {
      console.log(".close-sidebar-btn clicked");
      select("body").classList.toggle("toggle-sidebar");
    });

    // Add the event listener to the document for body clicks
    //document.addEventListener("click", handleBodyClick);

    // Clean up the event listener when the component unmounts
    return () => {
      //document.removeEventListener("click", handleBodyClick);
    };
  }, []);

  return (
    <div>
      <div className="sidebar playerSidebar d-flex">
        <a className="closeSideBar close-sidebar-btn">
          <svg
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            aria-labelledby="closeIconTitle"
            strokeWidth={1.8}
            strokeLinecap="square"
            strokeLinejoin="miter"
            fill="none"
            {...props}>
            <path d="M6.34314575 6.34314575L17.6568542 17.6568542M6.34314575 17.6568542L17.6568542 6.34314575" />
          </svg>
        </a>
        <ul className="navbar-nav">
          <li>
            <NavLink to="/" data-tooltip-id="Homepage">
              <svg
                width="800px"
                height="800px"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                {...props}
                strokeWidth=" 1.8"
                strokeLinecap="square"
                strokeLinejoin="miter"
                fill="none">
                <path
                  d="M22 20V12.1735C22 11.0734 21.5469 10.0218 20.7473 9.26624L13.3737 2.29812C12.6028 1.56962 11.3972 1.56962 10.6263 2.29812L3.25265 9.26624C2.45308 10.0218 2 11.0734 2 12.1735V20C2 21.1046 2.89543 22 4 22H20C21.1046 22 22 21.1046 22 20Z"
                  stroke="#fff"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </NavLink>
            <ReactTooltip id="Homepage" place="left" content={langJson?.homepage} />
          </li>
          <li>
            <NavLink to={`/@${loginData?.username}`} data-tooltip-id="myChannel">
              <svg
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                width="48px"
                height="48px"
                viewBox="0 0 24 24"
                aria-labelledby="userIconTitle"
                strokeWidth={1.8}
                strokeLinecap="square"
                strokeLinejoin="miter"
                fill="none"
                color="#2329D6"
                {...props}>
                <title id="userIconTitle">{"User"}</title>
                <path
                  strokeLinecap="round"
                  d="M5.5,19.5 C7.83333333,18.5 9.33333333,17.6666667 10,17 C11,16 8,16 8,11 C8,7.66666667 9.33333333,6 12,6 C14.6666667,6 16,7.66666667 16,11 C16,16 13,16 14,17 C14.6666667,17.6666667 16.1666667,18.5 18.5,19.5"
                />
                <circle cx={12} cy={12} r={10} />
              </svg>
            </NavLink>
            <ReactTooltip id="myChannel" place="left" content={langJson?.my_channel} />
          </li>
          <li>
            <NavLink to="/dashboard" data-tooltip-id="dashboard">
              <svg
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                aria-labelledby="chartIconTitle"
                strokeWidth={1.8}
                strokeLinecap="square"
                strokeLinejoin="miter"
                fill="none"
                {...props}>
                <polygon points="2 12 2 21 6 21 6 12" />
                <polygon points="18 7 18 21 22 21 22 7" />
                <polygon points="10 3 10 21 14 21 14 3" />
              </svg>
            </NavLink>
            <ReactTooltip id="dashboard" place="left" content={langJson?.dashboard} />
          </li>
          <li>
            <a>
              <svg
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                aria-labelledby="tvIconTitle"
                strokeWidth={1.8}
                strokeLinecap="square"
                strokeLinejoin="miter"
                fill="none"
                {...props}>
                <polygon points="20 8 20 20 4 20 4 8" />
                <polyline strokeLinejoin="round" points="8 4 12 7.917 16 4" />
              </svg>
            </a>
          </li>
          <li>
            <a>
              <svg
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                aria-labelledby="hornIconTitle"
                strokeWidth={1.8}
                strokeLinecap="square"
                strokeLinejoin="miter"
                fill="none"
                {...props}>
                <path
                  strokeLinejoin="round"
                  d="M6.5,9 C8.33333333,9 10.1666667,9 12,9 C14,9 16.3333333,7.33333333 19,4 L19,19 C16.3333333,15.6666667 14,14 12,14 C10.1666667,14 8.33333333,14 6.5,14 L6.5,14 C5.11928813,14 4,12.8807119 4,11.5 L4,11.5 C4,10.1192881 5.11928813,9 6.5,9 Z"
                />
                <polygon points="7 14 9 20 13 20 11 14" />
                <path d="M11,9 L11,14" />
              </svg>
            </a>
          </li>
          <li>
            <a>
              <svg
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                aria-labelledby="supportIconTitle"
                strokeWidth={1.8}
                strokeLinecap="square"
                strokeLinejoin="miter"
                fill="none"
                {...props}>
                <path
                  strokeLinecap="round"
                  d="M11 8L9.42229 7.21115C9.14458 7.07229 8.83835 7 8.52786 7H7.82843C7.29799 7 6.78929 7.21071 6.41421 7.58579L5.58579 8.41421C5.21071 8.78929 5 9.29799 5 9.82843L5 14.9296C5 15.5983 5.3342 16.2228 5.8906 16.5937L9.75746 19.1716C10.4944 19.663 11.4668 19.611 12.1472 19.044L17 15"
                />
                <path d="M14.4549 12.9142C13.8515 12.1062 12.741 11.8739 11.8643 12.3721L10.009 13.4266C9.41298 13.7653 8.66412 13.6641 8.17937 13.1794V13.1794C7.54605 12.546 7.59324 11.5056 8.2813 10.9323L12.4437 7.46356C12.8032 7.16403 13.2562 7 13.7241 7H14.5279C14.8384 7 15.1446 7.07229 15.4223 7.21115L17.8944 8.44721C18.572 8.786 19 9.47852 19 10.2361L19 12.9796C19 14.9037 16.5489 15.718 15.3976 14.1764L14.4549 12.9142Z" />
                <path d="M1 17V8" />
                <path d="M1 17V8" />
                <path d="M23 17V8" />
              </svg>
            </a>
          </li>
          <li>
            <NavLink to="/general-settings" data-tooltip-id="accountSettings">
              <svg
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                aria-labelledby="settingsIconTitle"
                strokeWidth={1.8}
                strokeLinecap="square"
                strokeLinejoin="miter"
                fill="none"
                {...props}>
                <path d="M5.03506429,12.7050339 C5.01187484,12.4731696 5,12.2379716 5,12 C5,11.7620284 5.01187484,11.5268304 5.03506429,11.2949661 L3.20577137,9.23205081 L5.20577137,5.76794919 L7.9069713,6.32070904 C8.28729123,6.0461342 8.69629298,5.80882212 9.12862533,5.61412402 L10,3 L14,3 L14.8713747,5.61412402 C15.303707,5.80882212 15.7127088,6.0461342 16.0930287,6.32070904 L18.7942286,5.76794919 L20.7942286,9.23205081 L18.9649357,11.2949661 C18.9881252,11.5268304 19,11.7620284 19,12 C19,12.2379716 18.9881252,12.4731696 18.9649357,12.7050339 L20.7942286,14.7679492 L18.7942286,18.2320508 L16.0930287,17.679291 C15.7127088,17.9538658 15.303707,18.1911779 14.8713747,18.385876 L14,21 L10,21 L9.12862533,18.385876 C8.69629298,18.1911779 8.28729123,17.9538658 7.9069713,17.679291 L5.20577137,18.2320508 L3.20577137,14.7679492 L5.03506429,12.7050339 Z" />
                <circle cx={12} cy={12} r={1} />
              </svg>
            </NavLink>
            <ReactTooltip id="accountSettings" place="left" content={langJson?.account_settings} />
          </li>
        </ul>
        <NavLink to="/upload-video" className="add-video-link" data-tooltip-id="uploadVideo">
          <svg
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 32 32"
            enableBackground="new 0 0 32 32"
            xmlSpace="preserve"
            {...props}>
            <rect x={10} y={15} width={12} height={2} />
            <rect x={15} y={10} width={2} height={12} />
            <circle fill="none" strokeWidth={2} strokeMiterlimit={10} cx={16} cy={16} r={12} />
          </svg>
        </NavLink>
        <ReactTooltip id="uploadVideo" place="left" content={langJson?.upload_new_video} />
      </div>
    </div>
  );
};

export default AfterLoginSidebar;
