import React, {useState, useEffect, useRef} from "react";
import axios from "axios";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import "./ChannelBanner.scss";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import {changeTitle, onLogout} from "../../utils/commonData";
import {NotificationManager} from "react-notifications";
import {BASE_URL} from "../../app_url";
import {getUserDetailsbyUsername} from "../../services/ApiService";
import {ChooseLanguage} from "../../utils/ChooseLanguage";
import {useLanguage} from "../../utils/LanguageProvider";

const coverImg = "https://diakanext.org/themes/default/statics/img/cover.jpg";
const userAvatar = BASE_URL + "static/" + "themes/default/img/avatar.png";

const ChannelBanner = props => {
  const {username} = useParams();
  const navigate = useNavigate();
  const {selectedLanguage, langJson, setVideoCount, videoCount} = useLanguage();

  const [loginData, setLoginData] = useState();
  const [tokenVal, setTokenVal] = useState();
  const [userDetail] = useState(JSON.parse(localStorage.getItem("loginInfo"))?.user);
  const [userToken] = useState(JSON.parse(localStorage.getItem("loginInfo"))?.token);
  const [cover, setCover] = useState("https://diakanext.org/themes/default/statics/img/cover.jpg");
  const [coverBig, setCoverBig] = useState("https://diakanext.org/themes/default/statics/img/cover.jpg");
  const [avatar, setAvatar] = useState(BASE_URL + "static/" + "themes/default/img/avatar.png");
  const [userDetails, setUserDetails] = useState([]);
  const [langJson1, setLangJson] = useState();

  const uploadImage = event => {
    // console.log(event.target.files[0]);
    // console.log(event.target.files[0].type);
    if (event.target.files[0].type == "image/jpeg" || event.target.files[0].type == "image/png" || event.target.files[0].type == "image/gif") {
      let reader = new FileReader();
      reader.onload = e => {
        setCover(e.target.result);
      };
      reader.readAsDataURL(event.target.files[0]);

      const formData = new FormData();
      formData.append("file", event.target.files[0]);
      //formData.append('UserId',data.UserId);
      const config = {
        headers: {"content-type": "multipart/form-data", Authorization: `Bearer ${userToken}`},
      };
      axios.post(`${BASE_URL}updatecover`, formData, config).then(response => {
        if (response.data.result == true) {
          NotificationManager.success(response.data.msg, "", 2000);
          getUserData(userToken);
        } else {
          NotificationManager.error(response.data.msg, "", 2000);
        }
      });
    } else {
      window.scrollTo(0, 0);
      NotificationManager.error("Upload valid image. Only PNG,JPEG,JPEG are allowed", "", 2000);
    }
  };

  const uploadAvatar = event => {
    // console.log(event.target.files[0]);
    // console.log(event.target.files[0].type);
    if (event.target.files[0].type == "image/jpeg" || event.target.files[0].type == "image/png" || event.target.files[0].type == "image/gif") {
      let reader = new FileReader();
      reader.onload = e => {
        setAvatar(e.target.result);
      };
      reader.readAsDataURL(event.target.files[0]);

      const formData = new FormData();
      formData.append("file_avatar", event.target.files[0]);
      //formData.append('UserId',data.UserId);
      const config = {
        headers: {"content-type": "multipart/form-data", Authorization: `Bearer ${userToken}`},
      };
      axios.post(`${BASE_URL}updateavatar`, formData, config).then(response => {
        if (response.data.result == true) {
          NotificationManager.success(response.data.msg, "", 2000);
          getUserData(userToken);
        } else {
          NotificationManager.error(response.data.msg, "", 2000);
        }
      });
    } else {
      window.scrollTo(0, 0);
      NotificationManager.error("Upload valid image. Only PNG,JPEG,JPEG are allowed", "", 2000);
    }
  };

  const getUserData = async token => {
    try {
      let values = {
        username: username.slice(1),
      };
      let response = await getUserDetailsbyUsername(values);
      if (response) {
        if (response.data.res === true) {
          setUserDetails(response.data.data);
          setCover(BASE_URL + "static/" + response.data.data.cover);
          setCoverBig(BASE_URL + "static/" + response.data.data.cover_orig);
          setAvatar(BASE_URL + "static/" + response.data.data.avatar);
          setVideoCount(parseInt(response.data.data.videos));
          changeTitle(response.data.data?.fname + " " + response.data.data?.lname);
        } else {
          NotificationManager.error(langJson?.something_went_wrong_please_try_again, "", 3000);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const Logout = () => {
    onLogout();
    navigate("/");
  };

  const getLanguage = () => {
    setLangJson(ChooseLanguage().selectLangJson);
  };

  useEffect(() => {
    //CreateBroadcast();
    let value = JSON.parse(localStorage.getItem("loginInfo")); //JSON.parse(secureLocalStorage.getItem('loginInfo'));
    if (value == undefined) {
      navigate("/");
    }
    if (value) {
      setTokenVal(value["token"]);
      setLoginData(value["user"]);

      getUserData(value["token"]);
    }

    getLanguage();
  }, []);

  return (
    <div>
      <div className="channelBanner">
        {/* small banner */}
        <div className="channel-cover">
          {/* <img src={coverImg} alt="Cover" /> */}
          <img src={cover} alt="Cover" />
          <div className="cover-settings-ctrls">
            <Button className="settings-ctrls-item">
              <svg xmlns="http://www.w3.org/2000/svg" width={512} height={512} viewBox="0 0 512 512">
                <circle cx={256} cy={272} r={64} />
                <path d="M456,144H373c-3,0-6.72-1.94-9.62-5l-27.28-42.8C325,80,320,80,302,80H210c-18,0-24,0-34.07,16.21L148.62,139c-2.22,2.42-5.34,5-8.62,5V128a8,8,0,0,0-8-8H92a8,8,0,0,0-8,8v16H56a24,24,0,0,0-24,24V408a24,24,0,0,0,24,24H456a24,24,0,0,0,24-24V168A24,24,0,0,0,456,144ZM260.51,367.9a96,96,0,1,1,91.39-91.39A96.11,96.11,0,0,1,260.51,367.9Z" />
              </svg>
              <input type="file" data-an="cover" accept="image/*" className="inputCoverImg" name="file" onChange={e => uploadImage(e)} />
            </Button>
            {/* <Button className="settings-ctrls-item">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={512}
                height={512}
                viewBox="0 0 512 512"
              >
                <polyline
                  points="176 112 256 32 336 112"
                  style={{
                    fill: "none",
                    strokeMiterlimit: 10,
                    strokeWidth: 32,
                  }}
                />
                <line
                  x1={255.98}
                  y1={32}
                  x2={256}
                  y2={480}
                  style={{
                    fill: "none",
                    strokeMiterlimit: 10,
                    strokeWidth: 32,
                  }}
                />
                <polyline
                  points="176 400 256 480 336 400"
                  style={{
                    fill: "none",
                    strokeMiterlimit: 10,
                    strokeWidth: 32,
                  }}
                />
                <polyline
                  points="400 176 480 256 400 336"
                  style={{
                    fill: "none",
                    strokeMiterlimit: 10,
                    strokeWidth: 32,
                  }}
                />
                <polyline
                  points="112 176 32 256 112 336"
                  style={{
                    fill: "none",
                    strokeMiterlimit: 10,
                    strokeWidth: 32,
                  }}
                />
                <line
                  x1={32}
                  y1={256}
                  x2={480}
                  y2={256}
                  style={{
                    fill: "none",
                    strokeMiterlimit: 10,
                    strokeWidth: 32,
                  }}
                />
              </svg>
            </Button> */}
          </div>
        </div>
        {/* small banner */}

        {/* big banner */}
        {/* <div className="channel-cover">
          <img src={coverBig} alt="Cover" />
          <div className="cover-settings-ctrls">
            <Button className="settings-ctrls-item">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={512}
                height={512}
                viewBox="0 0 512 512"
              >
                <circle cx={256} cy={272} r={64} />
                <path d="M456,144H373c-3,0-6.72-1.94-9.62-5l-27.28-42.8C325,80,320,80,302,80H210c-18,0-24,0-34.07,16.21L148.62,139c-2.22,2.42-5.34,5-8.62,5V128a8,8,0,0,0-8-8H92a8,8,0,0,0-8,8v16H56a24,24,0,0,0-24,24V408a24,24,0,0,0,24,24H456a24,24,0,0,0,24-24V168A24,24,0,0,0,456,144ZM260.51,367.9a96,96,0,1,1,91.39-91.39A96.11,96.11,0,0,1,260.51,367.9Z" />
              </svg>
              <input type="file" data-an="cover" accept="image/*" className="inputCoverImg" name = "file"  onChange={(e)=>uploadImage(e)} />
              
            </Button>
            <Button className="settings-ctrls-item">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={512}
                height={512}
                viewBox="0 0 512 512"
              >
                <polyline
                  points="176 112 256 32 336 112"
                  style={{
                    fill: "none",
                    strokeMiterlimit: 10,
                    strokeWidth: 32,
                  }}
                />
                <line
                  x1={255.98}
                  y1={32}
                  x2={256}
                  y2={480}
                  style={{
                    fill: "none",
                    strokeMiterlimit: 10,
                    strokeWidth: 32,
                  }}
                />
                <polyline
                  points="176 400 256 480 336 400"
                  style={{
                    fill: "none",
                    strokeMiterlimit: 10,
                    strokeWidth: 32,
                  }}
                />
                <polyline
                  points="400 176 480 256 400 336"
                  style={{
                    fill: "none",
                    strokeMiterlimit: 10,
                    strokeWidth: 32,
                  }}
                />
                <polyline
                  points="112 176 32 256 112 336"
                  style={{
                    fill: "none",
                    strokeMiterlimit: 10,
                    strokeWidth: 32,
                  }}
                />
                <line
                  x1={32}
                  y1={256}
                  x2={480}
                  y2={256}
                  style={{
                    fill: "none",
                    strokeMiterlimit: 10,
                    strokeWidth: 32,
                  }}
                />
              </svg>
            </Button>
          </div>
        </div> */}
        {/* big banner */}

        <div className="channel-owner-info d-flex">
          <div className="channel-avatar-settings">
            <div className="channel-avatar">
              <div className="avatar">
                <img src={avatar} alt="Cover" />
              </div>
              <Button className="btn-custom text-uppercase main-outline">
                <svg xmlns="http://www.w3.org/2000/svg" width={512} height={512} viewBox="0 0 512 512">
                  <circle cx={256} cy={272} r={64} />
                  <path d="M456,144H373c-3,0-6.72-1.94-9.62-5l-27.28-42.8C325,80,320,80,302,80H210c-18,0-24,0-34.07,16.21L148.62,139c-2.22,2.42-5.34,5-8.62,5V128a8,8,0,0,0-8-8H92a8,8,0,0,0-8,8v16H56a24,24,0,0,0-24,24V408a24,24,0,0,0,24,24H456a24,24,0,0,0,24-24V168A24,24,0,0,0,456,144ZM260.51,367.9a96,96,0,1,1,91.39-91.39A96.11,96.11,0,0,1,260.51,367.9Z" />
                </svg>
              </Button>
              <input type="file" accept="image/*" className="uploadInput" name="file_avatar" onChange={e => uploadAvatar(e)} />
            </div>
          </div>
          <div className="channel-name-holder">
            <div className="flex-row d-flex">
              <div className="uname">
                <div className="uname-inner d-flex">
                  <div className="row-item">
                    <h4>
                      {userDetails?.fname} {userDetails?.lname}
                    </h4>
                  </div>
                  {loginData?.username === username.slice(1) ? (
                    <div className="row-item">
                      <Dropdown>
                        <Dropdown.Toggle variant="link" id="dropdown-basic">
                          <svg xmlns="http://www.w3.org/2000/svg" width={512} height={512} viewBox="0 0 512 512" {...props}>
                            <circle
                              cx={256}
                              cy={256}
                              r={32}
                              style={{
                                fill: "none",
                                strokeMiterlimit: 10,
                                strokeWidth: 32,
                              }}
                            />
                            <circle
                              cx={416}
                              cy={256}
                              r={32}
                              style={{
                                fill: "none",
                                strokeMiterlimit: 10,
                                strokeWidth: 32,
                              }}
                            />
                            <circle
                              cx={96}
                              cy={256}
                              r={32}
                              style={{
                                fill: "none",
                                strokeMiterlimit: 10,
                                strokeWidth: 32,
                              }}
                            />
                          </svg>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <NavLink to="/dashboard" className="dropdown-item">
                            {langJson?.channel_control_panel}
                          </NavLink>
                          <NavLink to="/general-settings" className="dropdown-item">
                            {langJson?.edit_profile}
                          </NavLink>
                          <NavLink to="/" className="dropdown-item" onClick={() => Logout()}>
                            {langJson?.logout}
                          </NavLink>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  ) : null}
                </div>
                <div className="channel-activity-stats d-flex">
                  <div className="stat-holder">
                    <span className="stat-num">{userDetails.subscribers}</span>
                    <span className="stat-label">{langJson?.subscribers}</span>
                  </div>
                  <div className="stat-holder">
                    <span className="stat-num">{videoCount}</span>
                    <span className="stat-label">{langJson?.videos}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="channel-navigation-bar">
          <div className="navbar-wrapper d-flex">
            <NavLink to={`/@${username.slice(1)}`} end className="navbar-link-item">
              <div className="navbar-link-item-inner d-flex">
                <div className="icon">
                  <svg
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    width="48px"
                    height="48px"
                    viewBox="0 0 24 24"
                    aria-labelledby="userIconTitle"
                    strokeWidth={1.8}
                    strokeLinecap="square"
                    strokeLinejoin="miter"
                    fill="none"
                    color="#2329D6"
                    {...props}>
                    <title id="userIconTitle">{"User"}</title>
                    <path
                      strokeLinecap="round"
                      d="M5.5,19.5 C7.83333333,18.5 9.33333333,17.6666667 10,17 C11,16 8,16 8,11 C8,7.66666667 9.33333333,6 12,6 C14.6666667,6 16,7.66666667 16,11 C16,16 13,16 14,17 C14.6666667,17.6666667 16.1666667,18.5 18.5,19.5"
                    />
                    <circle cx={12} cy={12} r={10} />
                  </svg>
                </div>
                <span className="text-label">{langJson?.channel}</span>
              </div>
            </NavLink>
            <NavLink to={`/@${username.slice(1)}/playlists`} className="navbar-link-item">
              <div className="navbar-link-item-inner d-flex">
                <div className="icon">
                  <svg
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-labelledby="listViewIconTitle"
                    strokeWidth={1.8}
                    strokeLinecap="square"
                    strokeLinejoin="miter"
                    fill="none"
                    {...props}>
                    <path d="M5 5H19" />
                    <path d="M5 19H19" />
                    <rect x={5} y={9} width={14} height={6} />
                  </svg>
                </div>
                <span className="text-label">{langJson?.play_lists}</span>
              </div>
            </NavLink>
            <NavLink to={`/@${username.slice(1)}/subscriptions`} className="navbar-link-item">
              <div className="navbar-link-item-inner d-flex">
                <div className="icon">
                  <svg
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    aria-labelledby="peopleIconTitle"
                    strokeWidth={1.8}
                    strokeLinecap="square"
                    strokeLinejoin="miter"
                    fill="none"
                    {...props}>
                    <path d="M1 18C1 15.75 4 15.75 5.5 14.25 6.25 13.5 4 13.5 4 9.75 4 7.25025 4.99975 6 7 6 9.00025 6 10 7.25025 10 9.75 10 13.5 7.75 13.5 8.5 14.25 10 15.75 13 15.75 13 18M12.7918114 15.7266684C13.2840551 15.548266 13.6874862 15.3832994 14.0021045 15.2317685 14.552776 14.9665463 15.0840574 14.6659426 15.5 14.25 16.25 13.5 14 13.5 14 9.75 14 7.25025 14.99975 6 17 6 19.00025 6 20 7.25025 20 9.75 20 13.5 17.75 13.5 18.5 14.25 20 15.75 23 15.75 23 18" />
                    <path strokeLinecap="round" d="M12,16 C12.3662741,15.8763472 12.6302112,15.7852366 12.7918114,15.7266684" />
                  </svg>
                </div>
                <span className="text-label">{langJson?.subscriptions}</span>
              </div>
            </NavLink>
            <NavLink to={`/@${username.slice(1)}/liked`} className="navbar-link-item">
              <div className="navbar-link-item-inner d-flex">
                <div className="icon">
                  <svg
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    aria-labelledby="thumbUpIconTitle"
                    strokeWidth={1.8}
                    strokeLinecap="square"
                    strokeLinejoin="miter"
                    fill="none"
                    {...props}>
                    <path d="M8,8.73984815 C8,8.26242561 8.17078432,7.80075162 8.4814868,7.43826541 L13.2723931,1.84887469 C13.7000127,1.34998522 14.4122932,1.20614658 15,1.5 C15.5737957,1.78689785 15.849314,2.45205792 15.6464466,3.06066017 L14,8 L18.6035746,8 C18.7235578,8 18.8432976,8.01079693 18.9613454,8.03226018 C20.0480981,8.22985158 20.7689058,9.27101818 20.5713144,10.3577709 L19.2985871,17.3577709 C19.1256814,18.3087523 18.2974196,19 17.3308473,19 L10,19 C8.8954305,19 8,18.1045695 8,17 L8,8.73984815 Z" />
                    <path d="M4,18 L4,9" />
                  </svg>
                </div>
                <span className="text-label">{langJson?.liked}</span>
              </div>
            </NavLink>
            <NavLink to={`/@${username.slice(1)}/about`} className="navbar-link-item">
              <div className="navbar-link-item-inner d-flex">
                <div className="icon">
                  <svg
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    aria-labelledby="infoIconTitle"
                    strokeWidth={1.8}
                    strokeLinecap="square"
                    strokeLinejoin="miter"
                    fill="none"
                    {...props}>
                    <path d="M12,12 L12,15" />
                    <line x1={12} y1={9} x2={12} y2={9} />
                    <circle cx={12} cy={12} r={10} />
                  </svg>
                </div>
                <span className="text-label">{langJson?.about}</span>
              </div>
            </NavLink>
            <NavLink to={`/@${username.slice(1)}/products`} className="navbar-link-item">
              <div className="navbar-link-item-inner d-flex">
                <div className="icon">
                  <svg
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-labelledby="listViewIconTitle"
                    strokeWidth={1.8}
                    strokeLinecap="square"
                    strokeLinejoin="miter"
                    fill="none"
                    {...props}>
                    <path d="M5 5H19" />
                    <path d="M5 19H19" />
                    <rect x={5} y={9} width={14} height={6} />
                  </svg>
                </div>
                <span className="text-label"> {langJson?.products}</span>
              </div>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChannelBanner;
