import {NotificationManager} from "react-notifications";
import secureLocalStorage from "react-secure-storage";

export const onLogout = () => {
  localStorage.removeItem("loginInfo");
  NotificationManager.success("Logout successfully", "", 3000);
};

export const logInDetails = () => {
  // let value = JSON.parse(localStorage.getItem('loginInfo'));
  let value = JSON.parse(secureLocalStorage.getItem("loginInfo"));
  return value;
};

export const dateFormat = date => {
  if (date !== undefined) {
    const [year, month, day] = date.split("-");
    const result = [month, day, year].join("-");
    return result;
  } else {
    return 0;
  }
};

export const changeTitle = newTitle => {
  // document.title = newTitle;
};
