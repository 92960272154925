import React, {useState} from "react";
import {Modal, Button} from "react-bootstrap";

function GlobalSuccessModal({message, show, onClose}) {
  return (
    <Modal show={show} onHide={onClose} className="cmnModal successModal">
      {/* <Modal.Header closeButton>
        <Modal.Title>Success</Modal.Title>
      </Modal.Header> */}
      <Modal.Body>
        <p>{message}</p>
        {/* <p>Your changes has been successfully saved!</p> */}
      </Modal.Body>
      {/* <Modal.Footer>
        <Button variant="primary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer> */}
    </Modal>
  );
}

export default GlobalSuccessModal;
