import React from "react";
import {NavLink, useNavigate} from "react-router-dom";
import "./CreateProduct.scss";
import "../user-common.scss";
import AfterLoginHeader from "../../../include/AfterLoginHeader";
import AfterLoginSidebar from "../../../include/AfterLoginSidebar2";
import Footer from "../../../include/Footer";
import {Button} from "react-bootstrap";
import {useState} from "react";
import axios from "axios";
import {
  deleteProductImageApi,
  deleteVideoFile,
  deleteVideoPoster,
  getChannelDraft,
  getProductsDraftApi,
  getVideoCategories,
  saveProduct,
  saveVideo,
  uploadPoster,
  uploadProductImage,
  uploadVideo,
} from "../../../services/ApiService";
import GlobalSuccessModal from "../../../include/GlobalSuccessModal";
import GlobalProgressLoaderModal from "../../../include/GlobalProgressLoaderModal";
import GlobalSuccessImageModal from "../../../include/GlobalSuccessImageModal";
import {BASE_URL} from "../../../app_url";
import {useLanguage} from "../../../utils/LanguageProvider";

const CreateProduct = props => {
  const navigate = useNavigate();
  const {langJson} = useLanguage();
  const imageFileRef = React.useRef();
  const [formValues, setFormValues] = useState({
    title: null,
    url: null,
    agreeTerms: null,
  }); // Setting field values
  const [formValuesErrors, setFormValuesErrors] = useState({
    title: null,
    url: null,
    agreeTerms: null,
  }); // Setting field values errors
  const [videoError, setVideoError] = useState(); //For invalid video format.
  const [imagePosterError, setImagePosterError] = useState(); //For invalid image poster format.
  const [videoFile, setVideoFile] = useState(); // Video
  const [imagePosterFile, setImagePosterFile] = useState(); // Poster
  const [uploadProgress, setUploadProgress] = useState(0); // For progress bar
  const [categoryList, setCategoryList] = useState([]); // For store categories
  const [loading, setLoading] = useState(false); // For publish button login disable
  const [modalShouldOpen, setModalShouldOpen] = useState(false); // For success modal
  const [modalOpenMessage, setModalOpenMessage] = useState(); // For success modal
  const [progressModalShouldOpen, setProgressModalShouldOpen] = useState(false); // For success modal
  const [showPoster, setShowPoster] = useState(); // For success modal
  const [productId, setProductId] = useState(); // For video id

  //Checking validation
  const validateForm = () => {
    let isValid = true;
    const errors = {
      title: null,
      url: null,
      agreeTerms: null,
    };

    if (!formValues.title) {
      errors.title = langJson?.title_is_required;
      isValid = false;
    }
    if (!formValues.url) {
      errors.url = langJson?.url_is_required;
      isValid = false;
    } else {
      // Regular expression for URL validation
      const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;

      if (!urlPattern.test(formValues.url)) {
        errors.url = langJson?.please_enter_a_valid_url;
        isValid = false;
      }
    }

    setFormValuesErrors(errors);
    return isValid;
  };

  //When change any input value it will trigger.
  const handleChange = e => {
    const {name, value, type, checked} = e.target;
    if (name === "agreeTerms") {
      // Clear the error message associated with the field
      setFormValuesErrors(prevErrors => ({...prevErrors, [name]: ""}));
      setFormValues({...formValues, [name]: checked});
    } else {
      // Clear the error message associated with the field
      setFormValuesErrors(prevErrors => ({...prevErrors, [name]: ""}));
      setFormValues({...formValues, [name]: value});
    }
  };

  // Handle publish button click
  const handlePublishProduct = async () => {
    if (validateForm()) {
      setLoading(true);
      const formData = new FormData();
      formData.append("title", formValues.title);
      formData.append("source", formValues.url);
      try {
        let response = await saveProduct(formData);
        if (response) {
          if (response.data.res == 1) {
            setModalOpenMessage(response.data.msg);
            setModalShouldOpen(true);
            setTimeout(() => {
              setModalShouldOpen(false);
              let logData = JSON.parse(localStorage.getItem("loginInfo"));
              navigate("/@" + logData.user.username + "/products");
            }, 1000);
          }
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    } else {
    }
  };

  // Upload image poster
  const onImageUploadChange = async e => {
    const allowedExtensions = ["jpg", "jpeg", "png"];
    const file = e.target.files[0];
    if (file) {
      const fileExtension = file.name.split(".").pop().toLowerCase();
      if (allowedExtensions.includes(fileExtension)) {
        setImagePosterFile(file);
        setImagePosterError("");

        const formData = new FormData();
        formData.append("poster", file);
        setProgressModalShouldOpen(true);
        try {
          const response = await uploadProductImage(formData, progressEvent => {
            const progress = (progressEvent.loaded / progressEvent.total) * 100;
            setUploadProgress(progress);
          });
          if (response.data.res == 1) {
            // setVideoFile(response.data.file_name);
            setShowPoster(BASE_URL + "static/" + response.data.data.thumbnail);
            setProgressModalShouldOpen(false);
          } else {
            setImagePosterError(response.data.msg);
          }
        } catch (error) {
          // Handle errors, e.g., display an error message
          console.error("Upload failed:", error);
          setImagePosterError(langJson?.upload_failed_please_try_again);
          setProgressModalShouldOpen(false);
        }
      } else {
        setImagePosterFile(null);
        setImagePosterError(langJson?.invalid_file_format_Please_upload_a_valid__image_file);
      }
    }
    setUploadProgress(0);
  };

  // Delete image poster
  const onImagePosterDelete = async () => {
    try {
      let response = await deleteProductImageApi(productId);
      if (response) {
        if (response.data.res == 1) {
          setProductId(response.data.data.id);
          setImagePosterFile(null);
          setImagePosterError("");
          setShowPoster(null);
        } else {
          // setVideosList([]);
        }
      }
    } catch (error) {}
  };

  // Fetching channel draft
  const fetchProductsDraft = async () => {
    try {
      let response = await getProductsDraftApi();
      if (response) {
        if (response.data.res == 1) {
          if (response.data.data.thumbnail) {
            setShowPoster(BASE_URL + "static/" + response.data.data.thumbnail);
            setImagePosterFile(response.data.data.thumbnail);
          }
          if (response.data.data.file_name) {
            // setVideoFile(response.data.data.file_name);
          }
          setProductId(response.data.data.id);
        } else {
          // setVideosList([]);
        }
      }
    } catch (error) {}
  };

  React.useEffect(() => {
    fetchProductsDraft();
  }, []);

  return (
    <div>
      <AfterLoginHeader />
      <AfterLoginSidebar />
      <div className="content-wrapper">
        <div className="content">
          <div className="content-block-header">
            <h1>{langJson?.create_new_product}</h1>
          </div>
          <div className="upload-section upload-section-one">
            <div className="row">
              <div className="col-lg-6 upload-form-section">
                <h3>{langJson?.product_details}</h3>
                <ul className="row upload-form-list">
                  <li className="col-md-12">
                    <label className="form-label">{langJson?.product_title}</label>
                    <input type="text" className="form-control" placeholder={langJson?.name_required_field} name="title" onChange={handleChange} />
                    {formValuesErrors.title && <span className="error-message">{formValuesErrors.title}</span>}
                  </li>
                  <li className="col-md-12">
                    <label className="form-label">{langJson?.product_url}</label>
                    <input type="text" className="form-control" placeholder={langJson?.url_required_field} name="url" onChange={handleChange} />
                    {formValuesErrors.url && <span className="error-message">{formValuesErrors.url}</span>}
                  </li>
                  <li className="col-md-12">
                    <div className="d-grid gap-2">
                      <Button
                        className="btn-custom main-inline btn-block"
                        disabled={!imagePosterFile || loading || !formValues.agreeTerms || !formValues.title || !formValues.url}
                        onClick={() => handlePublishProduct()}>
                        {langJson?.publish_product}
                      </Button>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="col-lg-6 upload-form-section">
                <ul className="row upload-form-list">
                  <li className="col-md-12">
                    <label className="form-label">{langJson?.product_thumbnail}</label>
                    {showPoster ? (
                      <div className="video-file-dropzone">
                        {/* <img src={URL.createObjectURL(imagePosterFile)} /> */}
                        <img src={showPoster} />
                        <button onClick={() => onImagePosterDelete()} className="removeBtn">
                          <svg xmlns="http://www.w3.org/2000/svg" width={512} height={512} viewBox="0 0 512 512">
                            <path
                              d="M448,256c0-106-86-192-192-192S64,150,64,256s86,192,192,192S448,362,448,256Z"
                              style={{
                                fill: "none",
                                strokeMiterlimit: 10,
                                strokeWidth: 32,
                              }}
                            />
                            <line
                              x1={320}
                              y1={320}
                              x2={192}
                              y2={192}
                              style={{
                                fill: "none",
                                strokeLinecap: "round",
                                strokeLinejoin: "round",
                                strokeWidth: 32,
                              }}
                            />
                            <line
                              x1={192}
                              y1={320}
                              x2={320}
                              y2={192}
                              style={{
                                fill: "none",
                                strokeLinecap: "round",
                                strokeLinejoin: "round",
                                strokeWidth: 32,
                              }}
                            />
                          </svg>
                        </button>
                      </div>
                    ) : (
                      <div className="video-file-dropzone" onClick={() => imageFileRef?.current?.click()}>
                        <div className="video-file-dropzone-inner d-flex">
                          <div className="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width={512} height={512} viewBox="0 0 512 512" {...props}>
                              <path
                                d="M350.54,148.68l-26.62-42.06C318.31,100.08,310.62,96,302,96H210c-8.62,0-16.31,4.08-21.92,10.62l-26.62,42.06C155.85,155.23,148.62,160,140,160H80a32,32,0,0,0-32,32V384a32,32,0,0,0,32,32H432a32,32,0,0,0,32-32V192a32,32,0,0,0-32-32H373C364.35,160,356.15,155.23,350.54,148.68Z"
                                style={{
                                  fill: "none",
                                  strokeLinecap: "round",
                                  strokeLinejoin: "round",
                                  strokeWidth: 32,
                                }}
                              />
                              <circle
                                cx={256}
                                cy={272}
                                r={80}
                                style={{
                                  fill: "none",
                                  strokeMiterlimit: 10,
                                  strokeWidth: 32,
                                }}
                              />
                              <polyline
                                points="124 158 124 136 100 136 100 158"
                                style={{
                                  fill: "none",
                                  strokeLinecap: "round",
                                  strokeLinejoin: "round",
                                  strokeWidth: 32,
                                }}
                              />
                            </svg>
                          </div>
                          <input ref={imageFileRef} hidden type="file" accept="image/*" onChange={e => onImageUploadChange(e)} />
                          <div className="text-labels">
                            <strong>{langJson?.select_poster}</strong>
                            <span>{langJson?.upload_a_product_image}</span>
                          </div>
                        </div>
                      </div>
                    )}
                    {imagePosterError && <span className="error-message">{imagePosterError}</span>}
                  </li>
                  <li className="col-md-12">
                    <div className="tos-agree d-flex">
                      <div className="tos-agree-chbox">
                        <input id="chbox-tos-agree" type="checkbox" className="filled-in chk-col-main" name="agreeTerms" onChange={handleChange} />
                        <label htmlFor="chbox-tos-agree"></label>
                      </div>
                      <p>{langJson?.by_posting_a_product_you_agree_to_the}</p>
                    </div>
                    {formValuesErrors.agreeTerms && <span className="error-message">{formValuesErrors.agreeTerms}</span>}
                    {/* <progress value={uploadProgress} max={100} /> */}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <GlobalSuccessModal message={modalOpenMessage} show={modalShouldOpen} onClose={() => setModalShouldOpen(false)} />
      <GlobalSuccessImageModal uploadProgress={uploadProgress} show={progressModalShouldOpen} onClose={() => setProgressModalShouldOpen(false)} />
    </div>
  );
};
export default CreateProduct;
