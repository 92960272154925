import React from "react";
import { NavLink } from "react-router-dom";
import "./SettingsBanner.scss";

const SettingsBanner = (props) => {
  return (
    <div>
      <div className="settingsBanner">
        <div className="setting-navigation-bar">
          <div className="navbar-wrapper d-flex">
            <NavLink to="/general-settings" className="navbar-link-item">
              <div className="navbar-link-item-inner d-flex">
                <span className="text-label">GENERAL</span>
              </div>
            </NavLink>
            <NavLink to="/password-settings" className="navbar-link-item">
              <div className="navbar-link-item-inner d-flex">
                <span className="text-label">PASSWORD</span>
              </div>
            </NavLink>
            <NavLink to="/links-settings" className="navbar-link-item">
              <div className="navbar-link-item-inner d-flex">
                <span className="text-label">LINKS</span>
              </div>
            </NavLink>
            <NavLink to="/delete-account" className="navbar-link-item">
              <div className="navbar-link-item-inner d-flex">
                <span className="text-label">DELETE ACCOUNT</span>
              </div>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsBanner;
