import React, {useEffect, useState} from "react";
import {Link, NavLink, useParams} from "react-router-dom";
import "./Subcriptions.scss";
import "../user-common.scss";
import AfterLoginHeader from "../../../include/AfterLoginHeader";
import AfterLoginSidebar from "../../../include/AfterLoginSidebar";
import ChannelBanner from "../../../include/channelBanner/ChannelBanner";
import Footer from "../../../include/Footer";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {addSubscriptions, getChannelSubscriptionsApi} from "../../../services/ApiService";
import Dropdown from "react-bootstrap/Dropdown";
import {useLanguage} from "../../../utils/LanguageProvider";
import GlobalSuccessModal from "../../../include/GlobalSuccessModal";

const Subcriptions = props => {
  const {username} = useParams();
  const {langJson} = useLanguage();
  const [subcriptionList, setSubcriptionList] = useState(); // For store
  const [unsubscribeModal, setUnsubscribeModal] = useState(false); // For unsubscribe confirmation
  const [btnDis, setBtnDis] = useState(false);
  const [userDetail] = useState(JSON.parse(localStorage.getItem("loginInfo"))?.user); // Set the login user id
  const [modalShouldOpen, setModalShouldOpen] = useState(false); // For success modal
  const [modalOpenMessage, setModalOpenMessage] = useState(); // For success modal
  const [clickedUserId, setClickedUserId] = useState(); // For success modal

  // Fetching videos
  const fetchSubcriptions = async () => {
    try {
      let response = await getChannelSubscriptionsApi(username.slice(1));
      if (response) {
        if (response.data.res == 1) {
          setSubcriptionList(response.data.data);
        } else {
          setSubcriptionList([]);
        }
      }
    } catch (error) {}
  };

  // Unsubscribe
  const unsubscribeChannel = async () => {
    let tempArray = [...subcriptionList];
    setBtnDis(true);
    try {
      const formData = new FormData();
      formData.append("user_id", clickedUserId ? clickedUserId : 0);
      formData.append("subscriber_id", userDetail?.id);
      let response = await addSubscriptions(formData);
      if (response) {
        if (response.data.res == 1) {
          let selectedItemIndex = tempArray.findIndex(item => parseInt(item?.user_id) === clickedUserId);
          tempArray.splice(selectedItemIndex, 1);
          setSubcriptionList(tempArray);
          setModalOpenMessage(response.data.msg);
          setModalShouldOpen(true);
          setUnsubscribeModal(false);
          setTimeout(() => {
            setModalShouldOpen(false);
          }, 1000);
        }
        setBtnDis(false);
      }
    } catch (error) {
      setBtnDis(false);
    }
  };

  useEffect(() => {
    fetchSubcriptions();
  }, []);

  return (
    <div>
      <AfterLoginHeader />
      <AfterLoginSidebar />
      {/* <ChannelBanner /> */}
      <div className="content-wrapper channel-content-wrapper">
        <div className="content">
          <div className="content-block-header">
            <h1>{langJson?.subscriptions}</h1>
          </div>
          {subcriptionList && subcriptionList?.length > 0 ? (
            <>
              <ul className="row channelList">
                {subcriptionList &&
                  subcriptionList?.map((item, index) => (
                    <li className="col-lg-2 col-md-2 col-sm-4 col-6">
                      <div className="channel-grid-item d-flex">
                        <div className="avatar">
                          <img src={item?.avatar} alt="avater" />
                        </div>
                        <div className="uname">
                          <NavLink to="/my-Channel">{item?.name}</NavLink>
                          <time>
                            {item?.subscribers ? item?.subscribers : 0} {langJson?.subscribers}
                          </time>
                          <Button
                            className="btn-custom text-uppercase main-outline"
                            onClick={() => {
                              setUnsubscribeModal(true);
                              setClickedUserId(item?.user_id);
                            }}>
                            {langJson?.unsubscribe}
                          </Button>
                        </div>
                      </div>
                    </li>
                  ))}
              </ul>
            </>
          ) : subcriptionList && subcriptionList?.length === 0 ? (
            <div className="empty-page-placeholder">
              <div className="placeholder-message d-flex">
                <div className="icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width={512} height={512} viewBox="0 0 512 512" {...props}>
                    <path
                      d="M448,256c0-106-86-192-192-192S64,150,64,256s86,192,192,192S448,362,448,256Z"
                      style={{
                        fill: "none",
                        strokeMiterlimit: 10,
                        strokeWidth: 32,
                      }}
                    />
                    <path d="M365.2,313c-16.33-19.34-27.86-27.47-27.86-80.8,0-48.86-25.78-66.23-47-74.67a11.39,11.39,0,0,1-6.34-6.68C280.29,138.6,269.88,128,256,128s-24.31,10.6-28,22.86a11.35,11.35,0,0,1-6.33,6.68c-21.24,8.46-47,25.8-47,74.67,0,53.33-11.54,61.46-27.87,80.8-6.77,8-.65,23,11.19,23H354C365.77,336,371.94,321,365.2,313Z" />
                    <path d="M220.24,352a4,4,0,0,0-4,4.42C218.49,375.14,235.11,384,256,384c20.67,0,37.14-9.15,39.66-27.52a4,4,0,0,0-4-4.48Z" />
                  </svg>
                </div>
                <div className="message-text">
                  <h3>{langJson?.no_subscriptions_found}</h3>
                  {/* <p>Oops. It looks like there is no subscriptions on your channel yet to display. To post a new video, click the button below!</p> */}
                  <NavLink to="/upload-video">
                    <Button className="btn-custom text-uppercase main-outline">{langJson?.upload_my_first_video}</Button>
                  </NavLink>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <Footer />

      <GlobalSuccessModal message={modalOpenMessage} show={modalShouldOpen} onClose={() => setModalShouldOpen(false)} />

      {/* Unsubscribe Modal Start */}
      <Modal show={unsubscribeModal} onHide={() => setUnsubscribeModal(false)} className="cmnModal deleteVideorModal">
        <Modal.Body>
          <h3>{langJson?.please_confirm_your_actions}</h3>
          <p>{langJson?.please_note_that_if_you_unsubscribe_then_this_user_s_posts_will_n}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" className="cancel-btn" onClick={() => setUnsubscribeModal(false)}>
            {langJson?.cancel}
          </Button>
          <Button variant="primary" disabled={btnDis} onClick={() => unsubscribeChannel()}>
            {langJson?.unsubscribe}
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Unsubscribe Modal End */}
    </div>
  );
};

export default Subcriptions;
