import React from "react";
import {Link, NavLink, useParams} from "react-router-dom";
import "./Liked.scss";
import "../user-common.scss";
import AfterLoginHeader from "../../../include/AfterLoginHeader";
import AfterLoginSidebar from "../../../include/AfterLoginSidebar";
import ChannelBanner from "../../../include/channelBanner/ChannelBanner";
import Footer from "../../../include/Footer";
import {getChannelLikedApi} from "../../../services/ApiService";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import {useLanguage} from "../../../utils/LanguageProvider";
import {useState} from "react";

const Liked = props => {
  const {username} = useParams();
  const {langJson} = useLanguage();

  const [videosList, setVideosList] = React.useState([]); // For store
  const [currentPage, setCurrentPage] = useState(1); // For load more
  const [loading, setLoading] = useState(false); // For show load more buttton
  const [videoResponseConnt, setVideoResponseConnt] = useState(); // For show load more buttton
  const [videoLimit] = useState(16); // For video limit

  // Fetching videos
  const fetchVideos = async page => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("page", page);
      formData.append("limit", videoLimit);
      let response = await getChannelLikedApi(username.slice(1), formData);
      if (response) {
        if (response.data.res == 1) {
          setVideoResponseConnt(response.data.data.length);
          let tempArray = [...videosList];
          response.data.data.map((item, index) => {
            tempArray.push(item);
          });
          setVideosList(tempArray);
        } else {
          setVideosList([]);
        }
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  // Triggered after clicking load more button
  const onLoadMore = () => {
    setCurrentPage(prevCount => (prevCount += 1));
  };

  React.useEffect(() => {
    fetchVideos(currentPage);
  }, [currentPage]);

  return (
    <div>
      <AfterLoginHeader />
      <AfterLoginSidebar />
      {/* <ChannelBanner /> */}
      <div className="content-wrapper channel-content-wrapper">
        <div className="content">
          <div className="content-block-header">
            <h1>{langJson?.liked_videos}</h1>
          </div>
          {videosList && videosList?.length > 0 ? (
            <>
              <div className="recommended-videos">
                <ul className="row main-play-list">
                  {videosList &&
                    videosList?.map((item, index) => (
                      <li className="col-lg-3 col-md-4" key={index}>
                        <div className="video-grid-item">
                          <div className="video-poster">
                            <a className href="/watch/0">
                              {/* <img src="/static/media/ukmVudQ8LvHAVWowILTz_28_b394842e90a83ee71be0c59fda2c702f_thumbnail_1280x720.566cfa0bf15e144f0927.jpg" alt /> */}
                              <img src={item?.thumbnail} alt />
                              <span className="video-dur">{item?.duration}</span>
                            </a>
                          </div>
                          <div className="video-description">
                            <div className="avatar">
                              <img src={item?.u_avatar} alt />
                            </div>
                            <div className="video-info">
                              <h6>
                                <a className href="/watch/0">
                                  {item?.title}
                                </a>
                              </h6>
                              <div className="video-meta-info">
                                <div className="uname">
                                  <Link to={`/@` + item?.username}>{item?.u_name}</Link>
                                  {/* <Link to={item?.u_url}>{item?.u_name}</Link> */}
                                </div>
                                <div className="quick-stats">
                                  <span>
                                    {item?.views} {langJson?.views} • {item?.time}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="controls">
                              <Dropdown>
                                <Dropdown.Toggle variant="link" id="dropdown-basic">
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                    aria-labelledby="appsAltIconTitle"
                                    stroke="#000000"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    fill="none"
                                    color="#000000"
                                    {...props}>
                                    <title id="appsAltIconTitle">{"Apps drawer"}</title>
                                    <rect x={5} y={5} width={2} height={2} />
                                    <rect x={11} y={5} width={2} height={2} />
                                    <rect x={17} y={5} width={2} height={2} />
                                    <rect x={5} y={11} width={2} height={2} />
                                    <rect x={11} y={11} width={2} height={2} />
                                    <rect x={17} y={11} width={2} height={2} />
                                    <rect x={5} y={17} width={2} height={2} />
                                    <rect x={11} y={17} width={2} height={2} />
                                    <rect x={17} y={17} width={2} height={2} />
                                  </svg>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item>
                                    <Link to={`/edit-video/${item?.id}`}></Link>
                                    {langJson?.delete}
                                  </Dropdown.Item>
                                  <Dropdown.Item>{langJson?.edit}</Dropdown.Item>
                                  <Dropdown.Item>{langJson?.save}</Dropdown.Item>
                                  <Dropdown.Item>{langJson?.report}</Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                </ul>
                {!loading && videoResponseConnt >= videoLimit ? (
                  <div className="ufx-load-more d-flex">
                    <Button variant="outline-success" type="butto" className="btn-custom text-uppercase main-outline" onClick={() => onLoadMore()}>
                      {langJson?.load_more}
                    </Button>
                  </div>
                ) : null}
              </div>
            </>
          ) : !loading && videosList && videosList?.length === 0 ? (
            <div className="empty-page-placeholder">
              <div className="placeholder-message d-flex">
                <div className="icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width={512} height={512} viewBox="0 0 512 512" {...props}>
                    <path
                      d="M320,458.16S304,464,256,464s-74-16-96-32H96a64,64,0,0,1-64-64V320a64,64,0,0,1,64-64h30a32.34,32.34,0,0,0,27.37-15.4S162,221.81,188,176.78,264,64,272,48c29,0,43,22,34,47.71-10.28,29.39-23.71,54.38-27.46,87.09-.54,4.78,3.14,12,7.95,12L416,205"
                      style={{
                        fill: "none",
                        strokeLinecap: "round",
                        strokeLinejoin: "round",
                        strokeWidth: 32,
                      }}
                    />
                    <path
                      d="M416,271l-80-2c-20-1.84-32-12.4-32-30h0c0-17.6,14-28.84,32-30l80-4c17.6,0,32,16.4,32,34v.17A32,32,0,0,1,416,271Z"
                      style={{
                        fill: "none",
                        strokeMiterlimit: 10,
                        strokeWidth: 32,
                      }}
                    />
                    <path
                      d="M448,336l-112-2c-18-.84-32-12.41-32-30h0c0-17.61,14-28.86,32-30l112-2a32.1,32.1,0,0,1,32,32h0A32.1,32.1,0,0,1,448,336Z"
                      style={{
                        fill: "none",
                        strokeMiterlimit: 10,
                        strokeWidth: 32,
                      }}
                    />
                    <path
                      d="M400,464l-64-3c-21-1.84-32-11.4-32-29h0c0-17.6,14.4-30,32-30l64-2a32.09,32.09,0,0,1,32,32h0A32.09,32.09,0,0,1,400,464Z"
                      style={{
                        fill: "none",
                        strokeMiterlimit: 10,
                        strokeWidth: 32,
                      }}
                    />
                    <path
                      d="M432,400l-96-2c-19-.84-32-12.4-32-30h0c0-17.6,13-28.84,32-30l96-2a32.09,32.09,0,0,1,32,32h0A32.09,32.09,0,0,1,432,400Z"
                      style={{
                        fill: "none",
                        strokeMiterlimit: 10,
                        strokeWidth: 32,
                      }}
                    />
                  </svg>
                </div>
                <div className="message-text">
                  <h3>{langJson?.no_videos_found}</h3>
                  <p>{langJson?.oops_it_looks_like_there_is_no_video_to_display_in_our_database_y}</p>
                  <NavLink to="/upload-video">
                    <Button className="btn-custom text-uppercase main-outline">{langJson?.upload_my_first_video}</Button>
                  </NavLink>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Liked;
