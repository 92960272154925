import React, {useEffect, useRef, useState} from "react";
import {NavLink, Link, useNavigate} from "react-router-dom";
import "./ImportVideo.scss";
import "../user-common.scss";
import AfterLoginHeader from "../../../include/AfterLoginHeader";
import AfterLoginSidebar from "../../../include/AfterLoginSidebar2";
import Footer from "../../../include/Footer";
import {Button} from "react-bootstrap";
import {useLanguage} from "../../../utils/LanguageProvider";
import {deleteVideoPoster, getVideoCategories, importVideoApi, importVideoDraftApi, importVideoSaveApi, uploadPoster} from "../../../services/ApiService";
import {BASE_URL} from "../../../app_url";
import GlobalSuccessModal from "../../../include/GlobalSuccessModal";

const ImportVideo = props => {
  const navigate = useNavigate();
  const {langJson} = useLanguage();
  const imageFileRef = useRef();

  const [formValues, setFormValues] = useState({
    title: null,
    description: null,
    category: null,
    tags: null,
    comments: "E",
    agreeTerms: null,
  }); // Setting field values
  const [formValuesErrors, setFormValuesErrors] = useState({
    title: null,
    description: null,
    category: null,
    tags: null,
    comments: null,
    agreeTerms: null,
  }); // Setting field values errors
  const [categoryList, setCategoryList] = useState([]); // For store categories
  const [videoFile, setVideoFile] = useState(); // Video
  const [loading, setLoading] = useState(false); // For publish button login disable
  const [showPoster, setShowPoster] = useState(); // For success modal
  const [imagePosterFile, setImagePosterFile] = useState(); // Poster
  const [imagePosterError, setImagePosterError] = useState(); //For invalid image poster format.
  const [modalOpenMessage, setModalOpenMessage] = useState(); // For success modal
  const [progressModalShouldOpen, setProgressModalShouldOpen] = useState(false); // For success modal
  const [uploadProgress, setUploadProgress] = useState(0); // For progress bar
  const [videoError, setVideoError] = useState(); //For invalid video format.
  const [videoUrl, setVideoUrl] = useState(); //For video URL.
  const [importVideoDetails, setImportVideoDetails] = useState(); //For video URL.
  const [modalShouldOpen, setModalShouldOpen] = useState(false); // For success modal
  const [draftId, setDraftId] = useState(); // For draft id
  const [draftAvailable, setDraftAvailable] = useState(false); // For draft id

  //Checking validation
  const validateForm = () => {
    let isValid = true;
    const errors = {
      title: null,
      description: null,
      category: null,
      tags: null,
      comments: null,
      agreeTerms: null,
    };

    if (!formValues.title) {
      errors.title = langJson?.title_is_required;
      isValid = false;
    }

    if (formValues?.title?.length > 100) {
      errors.title = langJson?.video_title_exceeding_maximum_limit;
      isValid = false;
    }

    if (!formValues.description) {
      errors.description = langJson?.description_is_required;
      isValid = false;
    }

    if (!formValues.category) {
      errors.category = langJson?.video_category_is_required;
      isValid = false;
    }

    if (!formValues.tags) {
      errors.tags = langJson?.video_tags_is_required;
      isValid = false;
    }

    if (!formValues.comments) {
      errors.comments = langJson?.comments_is_required;
      isValid = false;
    }

    if (!formValues.agreeTerms) {
      errors.agreeTerms = langJson?.please_accept_terms_of_use_and_privacy_policy;
      isValid = false;
    }

    setFormValuesErrors(errors);
    return isValid;
  };

  //When change any input value it will trigger.
  const handleChange = e => {
    const {name, value, type, checked} = e.target;

    // Clear the error message associated with the field
    setFormValuesErrors(prevErrors => ({...prevErrors, [name]: ""}));
    setFormValues({...formValues, [name]: value});
  };

  // Handle publish button click
  const handlePublishVideo = async () => {
    // console.log(formValues);
    if (validateForm()) {
      setLoading(true);
      const formData = new FormData();
      Object.keys(formValues).map((key, index) => {
        // console.log(key);
        // console.log(formValues[key]);
      });
      formData.append("title", formValues.title);
      formData.append("description", formValues.description);
      formData.append("category_id", formValues.category);
      formData.append("comments", formValues.comments);
      formData.append("tags", formValues.tags);
      try {
        let response = await importVideoSaveApi(formData);
        if (response) {
          if (response.data.res == 1) {
            setModalOpenMessage(response.data.msg);
            setModalShouldOpen(true);
            setTimeout(() => {
              setModalShouldOpen(false);
              let logData = JSON.parse(localStorage.getItem("loginInfo"));
              navigate("/@" + logData.user.username);
            }, 1000);
          }
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    } else {
    }
  };

  // Delete image poster
  const onImagePosterDelete = async () => {
    try {
      let response = await deleteVideoPoster(draftId);
      if (response) {
        if (response.data.res == 1) {
          setImagePosterFile(null);
          setImagePosterError("");
          setShowPoster(null);
        } else {
        }
      }
    } catch (error) {}
  };

  // Upload image poster
  const onImageUploadChange = async e => {
    const allowedExtensions = ["jpg", "jpeg", "png"];
    const file = e.target.files[0];
    if (file) {
      const fileExtension = file.name.split(".").pop().toLowerCase();
      if (allowedExtensions.includes(fileExtension)) {
        setImagePosterFile(file);
        setImagePosterError("");

        const formData = new FormData();
        formData.append("poster", file);
        setProgressModalShouldOpen(true);
        try {
          const response = await uploadPoster(formData, progressEvent => {
            const progress = (progressEvent.loaded / progressEvent.total) * 100;
            setUploadProgress(progress);
          });
          if (response.data.res == 1) {
            // setVideoFile(response.data.file_name);
            // setShowPoster(BASE_URL + "static/" + response.data.data.thumbnail);
            setShowPoster(response.data.data.thumbnail);
            setProgressModalShouldOpen(false);
          } else {
            setImagePosterError(response.data.msg);
          }
        } catch (error) {
          // Handle errors, e.g., display an error message
          console.error("Upload failed:", error);
          setVideoError(langJson?.upload_failed_please_try_again);
          setProgressModalShouldOpen(false);
        }
      } else {
        setImagePosterFile(null);
        setImagePosterError(langJson?.invalid_file_format_Please_upload_a_valid__image_file);
      }
    }
    setUploadProgress(0);
  };

  const parseTextWithLinks = text => {
    const parts = text.split(/\{\@link[1-2]\@\}/); // Split the text using {@link1@} and {@link2@} as delimiters
    const link1Url = "/terms";
    const link2Url = "/privacy";
    return parts.map((part, index) => (
      <React.Fragment key={index}>
        {part}
        {index === 0 ? <Link to={link1Url}>{langJson?.terms_of_use}</Link> : null}
        {index === 1 ? <Link to={link2Url}>{langJson?.privacy_policy}</Link> : null}
      </React.Fragment>
    ));
  };

  const fetchImportDraft = async () => {
    try {
      const response = await importVideoDraftApi();
      if (response.data.res == 1) {
        if (response.data.data) {
          setDraftAvailable(true);
          setImportVideoDetails(response.data.data);
          if (response.data.data.thumbnail) {
            setShowPoster(response.data.data.thumbnail);
          }
          setDraftId(response.data.data.id);
          setVideoUrl(response.data.data.import_url);
          setFormValues({
            ...formValues,
            title: response.data.data.title,
            description: response.data.data.description,
            category: response.data.data.category_id,
            tags: response.data.data.tags,
            comments: response.data.data.comments,
          });
        }
      } else {
      }
    } catch (error) {}
  };

  const handleImportVideo = async () => {
    try {
      const formData = new FormData();
      formData.append("video_url", videoUrl);
      const response = await importVideoApi(formData);
      if (response.data.res == 1) {
        setDraftAvailable(true);
        setFormValues({
          ...formValues,
          title: response.data.data.title,
          description: response.data.data.desc,
          category: response.data.data.category_id,
          tags: response.data.data.tags,
          comments: response.data.data.comments,
        });
        // setVideoFile(response.data.file_name);
        setShowPoster(response.data.data.poster);
        // setProgressModalShouldOpen(false);
      } else {
        // setImagePosterError(response.data.msg);
      }
    } catch (error) {
      // Handle errors, e.g., display an error message
      console.error("Upload failed:", error);
      // setVideoError(langJson?.upload_failed_please_try_again);
      setProgressModalShouldOpen(false);
    }
  };

  // Fetching video category
  const fetchVideoCategory = async () => {
    try {
      let response = await getVideoCategories();
      if (response) {
        if (response.data.res == 1) {
          setCategoryList(response.data.data);
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchImportDraft();
    fetchVideoCategory();
  }, []);

  return (
    <div>
      <AfterLoginHeader />
      <AfterLoginSidebar />
      <div className="content-wrapper">
        <div className="content">
          <div className="content-block-header">
            <h1>{langJson?.import_new_video}</h1>
            <NavLink to="/upload-video">
              <Button className="btn-custom text-uppercase main-outline">{langJson?.upload_new_video}</Button>
            </NavLink>
          </div>
          <div className="upload-section">
            <ul className="row import-video-list">
              <li className="col-md-12">
                <div className="icon-holder d-flex">
                  <div className="icon">
                    <svg
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      aria-labelledby="downloadIconTitle"
                      strokeWidth={1.8}
                      strokeLinecap="square"
                      strokeLinejoin="miter"
                      fill="none"
                      {...props}>
                      <path d="M12,3 L12,16" />
                      <polyline points="7 12 12 17 17 12" />
                      <path d="M20,21 L4,21" />
                    </svg>
                  </div>
                </div>
              </li>
              <li className="col-md-12">
                <h3>{langJson?.import_new_video}</h3>
                <p>
                  {langJson?.enter_the_url_in_the_field_to_import_the_video_supported_video_so}
                  <strong>
                    <a>YouTube</a>, <a>Vimeo</a>,
                  </strong>{" "}
                  MP4
                </p>
              </li>
              <li className="col-md-12">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={langJson?.enter_video_url_here + "..."}
                    value={videoUrl}
                    onChange={e => setVideoUrl(e.target.value)}
                  />
                  <Button
                    className="btn-outline-secondary d-flex"
                    type="button"
                    id="button-addon2"
                    disabled={!videoUrl ? true : false}
                    onClick={() => handleImportVideo()}>
                    <svg
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      aria-labelledby="circleArrowDownIconTitle"
                      fill="none"
                      strokeWidth={1.8}
                      strokeLinecap="square"
                      strokeLinejoin="miter"
                      {...props}>
                      <path d="M15 13.5l-3 3-3-3" />
                      <path d="M12 15V7.5" />
                      <path strokeLinecap="round" d="M12 16.5V15v1.5z" />
                      <circle cx={12} cy={12} r={10} />
                    </svg>
                  </Button>
                </div>
              </li>
            </ul>
          </div>
          {draftAvailable ? (
            <div className="upload-section upload-section-one">
              <div className="row">
                <div className="col-lg-6 upload-form-section">
                  <h3>{langJson?.video_details}</h3>
                  <ul className="row upload-form-list">
                    <li className="col-md-12">
                      <label className="form-label">{langJson?.video_title}</label>
                      {formValues?.title?.length ? "  " + "(" + formValues?.title?.length + ")" : null}
                      <input
                        type="text"
                        className="form-control"
                        placeholder={langJson?.name_required_field}
                        value={formValues.title}
                        name="title"
                        onChange={handleChange}
                      />
                      {formValuesErrors.title && <span className="error-message">{formValuesErrors.title}</span>}
                    </li>
                    <li className="col-md-12">
                      <label className="form-label">{langJson?.video_description}</label>
                      <textarea
                        className="form-control textarea-control"
                        placeholder={langJson?.description}
                        rows="3"
                        name="description"
                        value={formValues.description}
                        onChange={handleChange}></textarea>
                      <div id="emailHelp" className="form-text">
                        {langJson?.specify_a_video_category_to_make_it_easier_for_users_to_find_this}
                      </div>
                      {formValuesErrors.description && <span className="error-message">{formValuesErrors.description}</span>}
                    </li>
                    <li className="col-md-12">
                      <label className="form-label">{langJson?.video_category}</label>
                      <select className="form-select" aria-label="Default select example" name="category" value={formValues.category} onChange={handleChange}>
                        <option selected>{langJson?.open_this_select_menu}</option>
                        {Object.entries(categoryList)?.map((item, index) => (
                          <option key={index} value={item[0]}>
                            {item[1]}
                          </option>
                        ))}
                      </select>
                      <div id="emailHelp" className="form-text">
                        {langJson?.specify_a_video_category_to_make_it_easier_for_users_to_find_this}
                      </div>
                      {formValuesErrors.category && <span className="error-message">{formValuesErrors.category}</span>}
                    </li>
                    <li className="col-md-12">
                      <label className="form-label">{langJson?.tags}</label>
                      <input type="text" className="form-control" placeholder={langJson?.tags} name="tags" value={formValues.tags} onChange={handleChange} />
                      <div id="emailHelp" className="form-text">
                        {langJson?.we_recommend_adding_tags_this_is_useful_for_seo_optimization_and_}
                      </div>
                      {formValuesErrors.tags && <span className="error-message">{formValuesErrors.tags}</span>}
                    </li>
                    <li className="col-md-12">
                      <label className="form-label">{langJson?.comments}</label>
                      <select className="form-select" aria-label="Default select example" value={formValues.comments} name="comments" onChange={handleChange}>
                        <option value="E">{langJson?.enable_comments}</option>
                        <option value="D">{langJson?.disable_comments}</option>
                      </select>
                      {formValuesErrors.comments && <span className="error-message">{formValuesErrors.comments}</span>}
                    </li>
                    <li className="col-md-12">
                      <div className="d-grid gap-2">
                        <Button className="btn-custom main-inline btn-block" disabled={loading} onClick={() => handlePublishVideo()}>
                          {langJson?.publish_video}
                        </Button>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-6 upload-form-section">
                  <h3>{langJson?.video_source}</h3>
                  <ul className="row upload-form-list">
                    <li className="col-md-12">
                      <label className="form-label">{langJson?.video_thumbnail}</label>
                      {showPoster ? (
                        <div className="video-file-dropzone">
                          {/* <img src={URL.createObjectURL(imagePosterFile)} /> */}
                          <img src={showPoster} />
                          <button onClick={() => onImagePosterDelete()} className="removeBtn">
                            <svg xmlns="http://www.w3.org/2000/svg" width={512} height={512} viewBox="0 0 512 512">
                              <path
                                d="M448,256c0-106-86-192-192-192S64,150,64,256s86,192,192,192S448,362,448,256Z"
                                style={{
                                  fill: "none",
                                  strokeMiterlimit: 10,
                                  strokeWidth: 32,
                                }}
                              />
                              <line
                                x1={320}
                                y1={320}
                                x2={192}
                                y2={192}
                                style={{
                                  fill: "none",
                                  strokeLinecap: "round",
                                  strokeLinejoin: "round",
                                  strokeWidth: 32,
                                }}
                              />
                              <line
                                x1={192}
                                y1={320}
                                x2={320}
                                y2={192}
                                style={{
                                  fill: "none",
                                  strokeLinecap: "round",
                                  strokeLinejoin: "round",
                                  strokeWidth: 32,
                                }}
                              />
                            </svg>
                          </button>
                        </div>
                      ) : (
                        <div className="video-file-dropzone" onClick={() => imageFileRef?.current?.click()}>
                          <div className="video-file-dropzone-inner d-flex">
                            <div className="icon">
                              <svg xmlns="http://www.w3.org/2000/svg" width={512} height={512} viewBox="0 0 512 512" {...props}>
                                <path
                                  d="M350.54,148.68l-26.62-42.06C318.31,100.08,310.62,96,302,96H210c-8.62,0-16.31,4.08-21.92,10.62l-26.62,42.06C155.85,155.23,148.62,160,140,160H80a32,32,0,0,0-32,32V384a32,32,0,0,0,32,32H432a32,32,0,0,0,32-32V192a32,32,0,0,0-32-32H373C364.35,160,356.15,155.23,350.54,148.68Z"
                                  style={{
                                    fill: "none",
                                    strokeLinecap: "round",
                                    strokeLinejoin: "round",
                                    strokeWidth: 32,
                                  }}
                                />
                                <circle
                                  cx={256}
                                  cy={272}
                                  r={80}
                                  style={{
                                    fill: "none",
                                    strokeMiterlimit: 10,
                                    strokeWidth: 32,
                                  }}
                                />
                                <polyline
                                  points="124 158 124 136 100 136 100 158"
                                  style={{
                                    fill: "none",
                                    strokeLinecap: "round",
                                    strokeLinejoin: "round",
                                    strokeWidth: 32,
                                  }}
                                />
                              </svg>
                            </div>
                            <input ref={imageFileRef} hidden type="file" accept="image/*" onChange={e => onImageUploadChange(e)} />
                            <div className="text-labels">
                              <strong>{langJson?.select_poster}</strong>
                              <span>{langJson?.upload_a_new_icon_or_leave_the_default_one_already_selected}</span>
                            </div>
                          </div>
                        </div>
                      )}
                      {imagePosterError && <span className="error-message">{imagePosterError}</span>}
                    </li>
                    {/* <li className="col-md-12">
                    <label className="form-label">
                      {langJson?.video_file} {videoFile ? `(${videoFile})` : `(${langJson?.not_selected})`}
                    </label>
                    {videoFile ? (
                      <div className="upload-video-sec">
                        <div className="d-grid gap-2">
                          <Button className="btn-custom main-inline btn-block" onClick={() => onVideoDelete()}>
                            {langJson?.delete_video}
                          </Button>
                        </div>
                      </div>
                    ) : (
                      <div className="upload-video-sec">
                        <input type="file" className="input-file" accept="video/*" onChange={e => onVideoUploadChange(e)} />
                        <div className="d-grid gap-2">
                          {videoFile ? (
                            <Button className="btn-custom main-inline btn-block" onClick={() => onVideoDelete()}>
                              {langJson?.delete_video}
                            </Button>
                          ) : (
                            <Button className="btn-custom main-inline btn-block">{langJson?.upload_video}</Button>
                          )}
                        </div>
                      </div>
                    )}
                    {videoError && <span className="error-message">{videoError}</span>}
                  </li> */}
                    <li className="col-md-12">
                      <div className="tos-agree d-flex">
                        <div className="tos-agree-chbox">
                          <input id="chbox-tos-agree" type="checkbox" className="filled-in chk-col-main" name="agreeTerms" onChange={handleChange} />
                          <label htmlFor="chbox-tos-agree"></label>
                        </div>
                        <p>{parseTextWithLinks(langJson?.by_posting_a_video_you_agree_to_the_you_also_agree_to_respect_the)}</p>
                      </div>
                      {formValuesErrors.agreeTerms && <span className="error-message">{formValuesErrors.agreeTerms}</span>}
                      {/* <progress value={uploadProgress} max={100} /> */}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <Footer />
      <GlobalSuccessModal message={modalOpenMessage} show={modalShouldOpen} onClose={() => setModalShouldOpen(false)} />
    </div>
  );
};

export default ImportVideo;
