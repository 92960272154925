import React, {useState, useEffect, useRef} from "react";
import axios from "axios";
import {NavLink, useNavigate} from "react-router-dom";
import "./LinksSettings.scss";
import "../user-common.scss";
import AfterLoginHeader from "../../../include/AfterLoginHeader";
import AfterLoginSidebar2 from "../../../include/AfterLoginSidebar2";
import SettingsBanner from "../../../include/settingsBanner/SettingsBanner";
import Footer from "../../../include/Footer";
import {Button} from "react-bootstrap";
import {NotificationManager} from "react-notifications";
import {BASE_URL} from "../../../app_url";
import {useLanguage} from "../../../utils/LanguageProvider";

const LinksSettings = props => {
  const navigate = useNavigate();
  const {langJson} = useLanguage();
  const [loginData, setLoginData] = useState();
  const [userDetails, setUserDetails] = useState([]);
  const [storedUserData, setStoredUserData] = useState();

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loginInfo, setLoginInfo] = useState("");
  const sidebarRef = useRef(null);
  const [countryList, setCountryList] = useState([]);

  useEffect(() => {
    let value = JSON.parse(localStorage.getItem("loginInfo")); //JSON.parse(secureLocalStorage.getItem('loginInfo'));
    setStoredUserData(value["token"]);
    if (value == undefined) {
      navigate("/");
    }
    //console.log(value['user']['id']);
    //console.log(value);
    if (value) {
      setLoginData(value["user"]);
    }
    getUserData(value["user"]["id"], value["token"]);
  }, []);

  const [formData, setFormData] = useState({
    website: "",
    facebook: "",
    twitter: "",
    instagram: "",
    youtube: "",
  });

  const [formErrors, setFormErrors] = useState({
    website: "",
    facebook: "",
    twitter: "",
    instagram: "",
    youtube: "",
  });

  const validateForm = () => {
    let isValid = true;
    const errors = {
      website: "",
      facebook: "",
      twitter: "",
      instagram: "",
      youtube: "",
    };

    // if (formData.firstName === '') {
    //   errors.firstName = 'First name is required';
    //   isValid = false;
    // }

    // if (formData.lastName === '') {
    //   errors.lastName = 'Last name is required';
    //   isValid = false;
    // }

    // if (formData.username === '') {
    //   errors.username = 'Username is required';
    //   isValid = false;
    // } else if (formData.username.length < 3 || formData.username.length > 20) {
    //   errors.username = 'Username must be between 3 and 20 characters';
    //   isValid = false;
    // }

    // if (formData.email === '') {
    //   errors.email = 'Email is required';
    //   isValid = false;
    // } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
    //   errors.email = 'Invalid email format';
    //   isValid = false;
    // }

    // if (formData.password === '') {
    //   errors.password = 'Password is required';
    //   isValid = false;
    // } else if (
    //   !/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}/.test(formData.password)
    // ) {
    //   errors.password = 'Password should be strong (example: Abc@1234)';
    //   isValid = false;
    // }

    // if (formData.confirmPassword === '') {
    //   errors.confirmPassword = 'Confirm password is required';
    //   isValid = false;
    // } else if (formData.confirmPassword !== formData.password) {
    //   errors.confirmPassword = 'Passwords do not match';
    //   isValid = false;
    // }

    // if (!formData.agreedToTerms) {
    //   errors.agreedToTerms = 'You must agree to the terms';
    //   isValid = false;
    // }

    setFormErrors(errors);
    return isValid;
  };

  const handleSubmit = e => {
    e.preventDefault();

    if (validateForm()) {
      setIsSubmitting(true);
      // Send the signup data to your Python Flask API
      // You can use libraries like Axios to make API requests
      // Example:
      axios
        .post(`${BASE_URL}editlink`, JSON.stringify(formData), {
          headers: {
            "Content-Type": "application/json", // Set the content type
            Authorization: `Bearer ${storedUserData}`,
          },
        })
        .then(response => {
          setIsSubmitting(false);
          setSuccessMessage(response.data.message);
          setErrorMessage(""); // Clear any previous error messages

          setFormErrors({
            website: "",
            facebook: "",
            twitter: "",
            instagram: "",
            youtube: "",
          });
        })
        .catch(error => {
          setIsSubmitting(false);
          if (error.response) {
            setErrorMessage(error.response.data.error || langJson?.something_went_wrong_please_try_again);
          } else {
            setErrorMessage(langJson?.something_went_wrong_please_try_again);
          }
          setSuccessMessage(""); // Clear any previous success messages
        });
    }
  };

  const handleChange = e => {
    const {name, value, type, checked} = e.target;

    // Clear the error message associated with the field
    setFormErrors(prevErrors => ({...prevErrors, [name]: ""}));
    setFormData({...formData, [name]: value});
  };

  const getUserData = async (userId, token) => {
    try {
      let response = await axios.post(
        `${BASE_URL}userdetailsbyid`,
        {
          userId: userId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response) {
        // response = JSON.stringify(response);
        //console.log(response);
        if (response.data.res === true) {
          //console.log(response.data.data.fname);
          setUserDetails(response.data.data);
          setFormData({
            website: response.data.data.website,
            facebook: response.data.data.facebook,
            twitter: response.data.data.twitter,
            instagram: response.data.data.instagram,
            youtube: response.data.data.youtube,
          });
        } else {
          NotificationManager.error(langJson?.something_went_wrong_please_try_again, "", 3000);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <AfterLoginHeader />
      <AfterLoginSidebar2 />
      <div className="content-wrapper">
        <div className="content">
          <div className="content-block-header">
            <h1>{langJson?.account_settings}</h1>
          </div>
          <div className="white-settings-section">
            <SettingsBanner />
            <form className="form" onSubmit={handleSubmit}>
              <div className="seetings-bottom-section">
                <h3>{langJson?.account_links}</h3>
                <ul className="row setting-form-list">
                  {successMessage && <div className="success-message">{successMessage}</div>}
                  {errorMessage && <div className="error-message">{errorMessage}</div>}
                  <li className="col-md-12">
                    <label className="form-label">{langJson?.website}</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={langJson?.enter_your_website_url}
                      value={formData.website}
                      name="website"
                      onChange={handleChange}
                    />
                  </li>
                  <li className="col-md-6">
                    <label className="form-label">{langJson?.facebook}</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={langJson?.enter_your_facebook_url}
                      value={formData.facebook}
                      name="facebook"
                      onChange={handleChange}
                    />
                  </li>
                  <li className="col-md-6">
                    <label className="form-label">{langJson?.twitter}</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={langJson?.enter_your_twitter_url}
                      value={formData.twitter}
                      name="twitter"
                      onChange={handleChange}
                    />
                  </li>
                  <li className="col-md-6">
                    <label className="form-label">{langJson?.instagram}</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={langJson?.enter_your_instagram_url}
                      value={formData.instagram}
                      name="instagram"
                      onChange={handleChange}
                    />
                  </li>
                  <li className="col-md-6">
                    <label className="form-label">{langJson?.youtube}</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder={langJson?.enter_your_youtube_url}
                      value={formData.youtube}
                      name="youtube"
                      onChange={handleChange}
                    />
                  </li>
                  <li className="col-md-12">
                    <div className="d-grid gap-2">
                      {/* <Button className="btn-custom main-inline btn-block">
                        Save changes!
                      </Button> */}

                      <button type="submit" className={`btn btn-custom main-inline ${isSubmitting ? "disabled" : ""}`} disabled={isSubmitting}>
                        {isSubmitting ? langJson?.updated : langJson?.save_changes}
                      </button>
                    </div>
                  </li>
                </ul>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default LinksSettings;
