import React from "react";
import {NavLink} from "react-router-dom";
import "./channels.scss";
import Header from "../../include/Header";
import Sidebar from "../../include/Sidebar";
import Footer from "../../include/Footer";
import Button from "react-bootstrap/Button";
import {useLanguage} from "../../utils/LanguageProvider";
import {BASE_URL} from "../../app_url";

const avatar = BASE_URL + "static/" + "themes/default/img/avatar.png";

const Channels = () => {
  const {langJson} = useLanguage();

  const updateMetaTags = () => {
    const ogUrl = document.querySelector('meta[property="og:url"]');
    const ogTitle = document.querySelector('meta[property="og:title"]');
    const ogImage = document.querySelector('meta[property="og:image"]');

    ogUrl.setAttribute("content", "https://www.tailwindapp.com/wp-content/uploads/2023/05/fb-photo-sizes-Blog-graphic.jpg");
    ogTitle.setAttribute("content", "titlescssvvsv0254545");
    ogImage.setAttribute("content", "https://www.tailwindapp.com/wp-content/uploads/2023/05/fb-photo-sizes-Blog-graphic.jpg");
  };

  // Function to open the Facebook share dialog
  const shareOnFacebook = () => {
    updateMetaTags();

    // Wait for a short delay (e.g., 500ms) before sharing
    setTimeout(() => {
      // Create the sharing URL
      const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerMeltdowns.mp4"
      )}`;
      window.open(shareUrl, "_blank");
    }, 5000); // Adjust the delay time as needed
  };

  return (
    <div>
      <Header />
      <Sidebar />
      <div className="content-wrapper">
        <div className="content-header">
          <h1 className="text-uppercase">{langJson?.popular_channels}</h1>
          {/* <button onClick={shareOnFacebook}>Share on Facebook</button> */}
        </div>
        <div className="content">
          <div className="channelListSec">
            <ul className="row channelList">
              <li className="col-lg-2 col-md-2 col-sm-4 col-6">
                <div className="channel-grid-item d-flex">
                  <div className="avatar">
                    <img src={avatar} alt="avater" />
                  </div>
                  <div className="uname">
                    <NavLink to="/my-Channel">manager tester</NavLink>
                    <time>0 Subscribers</time>
                    <Button className="btn-custom text-uppercase main-outline">Subscribe</Button>
                  </div>
                </div>
              </li>
              <li className="col-lg-2 col-md-2 col-sm-4 col-6">
                <div className="channel-grid-item d-flex">
                  <div className="avatar">
                    <img src={avatar} alt="avater" />
                  </div>
                  <div className="uname">
                    <NavLink to="/my-Channel">manager tester</NavLink>
                    <time>0 Subscribers</time>
                    <Button className="btn-custom text-uppercase main-outline">Subscribe</Button>
                  </div>
                </div>
              </li>
              <li className="col-lg-2 col-md-2 col-sm-4 col-6">
                <div className="channel-grid-item d-flex">
                  <div className="avatar">
                    <img src={avatar} alt="avater" />
                  </div>
                  <div className="uname">
                    <NavLink to="/my-Channel">manager tester</NavLink>
                    <time>0 Subscribers</time>
                    <Button className="btn-custom text-uppercase main-outline">Subscribe</Button>
                  </div>
                </div>
              </li>
              <li className="col-lg-2 col-md-2 col-sm-4 col-6">
                <div className="channel-grid-item d-flex">
                  <div className="avatar">
                    <img src={avatar} alt="avater" />
                  </div>
                  <div className="uname">
                    <NavLink to="/my-Channel">manager tester</NavLink>
                    <time>0 Subscribers</time>
                    <Button className="btn-custom text-uppercase main-outline">Subscribe</Button>
                  </div>
                </div>
              </li>
              <li className="col-lg-2 col-md-2 col-sm-4 col-6">
                <div className="channel-grid-item d-flex">
                  <div className="avatar">
                    <img src={avatar} alt="avater" />
                  </div>
                  <div className="uname">
                    <NavLink to="/my-Channel">manager tester</NavLink>
                    <time>0 Subscribers</time>
                    <Button className="btn-custom text-uppercase main-outline">Subscribe</Button>
                  </div>
                </div>
              </li>
              <li className="col-lg-2 col-md-2 col-sm-4 col-6">
                <div className="channel-grid-item d-flex">
                  <div className="avatar">
                    <img src={avatar} alt="avater" />
                  </div>
                  <div className="uname">
                    <NavLink to="/my-Channel">manager tester</NavLink>
                    <time>0 Subscribers</time>
                    <Button className="btn-custom text-uppercase main-outline">Subscribe</Button>
                  </div>
                </div>
              </li>
              <li className="col-lg-2 col-md-2 col-sm-4 col-6">
                <div className="channel-grid-item d-flex">
                  <div className="avatar">
                    <img src={avatar} alt="avater" />
                  </div>
                  <div className="uname">
                    <NavLink to="/my-Channel">manager tester</NavLink>
                    <time>0 Subscribers</time>
                    <Button className="btn-custom text-uppercase main-outline">Subscribe</Button>
                  </div>
                </div>
              </li>
              <li className="col-lg-2 col-md-2 col-sm-4 col-6">
                <div className="channel-grid-item d-flex">
                  <div className="avatar">
                    <img src={avatar} alt="avater" />
                  </div>
                  <div className="uname">
                    <NavLink to="/my-Channel">manager tester</NavLink>
                    <time>0 Subscribers</time>
                    <Button className="btn-custom text-uppercase main-outline">Subscribe</Button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Channels;
