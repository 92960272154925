import React, {useEffect, useState} from "react";
import "./CMS.scss";
import "./Blog.scss";
import Header from "../../include/Header";
import PlayerSidebar from "../../include/PlayerSidebar";
import Footer from "../../include/Footer";
import {
  blogAddCommentApi,
  blogCommentDeleteApi,
  blogCommentReplyApi,
  blogCommentReplyLikeApi,
  blogDetailsApi,
  blogFetchCommentApi,
} from "../../services/ApiService";
import Dropdown from "react-bootstrap/Dropdown";
import {useLanguage} from "../../utils/LanguageProvider";
import Button from "react-bootstrap/Button";
import {Link, NavLink, useNavigate, useParams} from "react-router-dom";
import Collapse from "react-bootstrap/Collapse";
import {BASE_URL} from "../../app_url";
import Modal from "react-bootstrap/Modal";

const avatarImg = BASE_URL + "static/" + "themes/default/img/avatar.png";

const BlogDetails = () => {
  const {blogId} = useParams();
  const {langJson} = useLanguage();

  const [loading, setLoading] = useState(false); // For load
  const [loadingBtn, setLoadingBtn] = useState(false); // For button details
  const [blogDetails, setBlogDetails] = useState([]); // For Details
  const [commentSort, setCommentSort] = useState("desc"); // For sorting comment
  const [userDetail] = useState(JSON.parse(localStorage.getItem("loginInfo"))?.user); // Set the login user id
  const [commentMessage, setCommentMessage] = useState(); // For comments
  const [commentDetails, setCommentDetails] = useState([]); // For comments
  const [parentUserId, setParentUserId] = useState(); // For reply comment
  const [parentCommentId, setParentCommentId] = useState(); // For reply comment
  const [modalOpenReply, setModalOpenReply] = useState(false); // For success
  const [parentComment, setParentComment] = useState(); // For reply comment
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [clickedCommentIndex, setClickedCommentIndex] = useState(); // For success modal
  const [open, setOpen] = useState(); // For reply modal
  const [avatar, setAvatar] = useState(BASE_URL + "static/" + "themes/default/img/avatar.png");
  const [replyCommentMessage, setReplyCommentMessage] = useState(); // For reply comments

  // Fetching blog details
  const fetchBlogDetails = async () => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("id", blogId);
      let response = await blogDetailsApi(formData);
      if (response) {
        if (response.data.res == 1) {
          setBlogDetails(response.data.data);
        }
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  // Handle add comment button click
  const onHandleLeaveComment = async () => {
    setLoadingBtn(true);
    try {
      const formData = new FormData();
      formData.append("user_id", userDetail?.id);
      formData.append("post_id", blogId);
      formData.append("comment", commentMessage);
      let response = await blogAddCommentApi(formData);
      if (response) {
        if (response.data.res == 1) {
          fetchBlogComments(commentSort);
          let tempObj = {...blogDetails};
          tempObj.total_comms = parseInt(tempObj.total_comms) + 1;
          setBlogDetails(tempObj);
          setCommentMessage("");
        } else {
        }
        setLoadingBtn(false);
      }
    } catch (error) {
      setLoadingBtn(false);
    }
  };

  // Handle delete comment
  const onDeleteComment = async (id, subId, index, source) => {
    try {
      const formData = new FormData();
      formData.append("comment_id", source === "main" ? id : subId);
      formData.append("post_id", blogId);
      let response = await blogCommentDeleteApi(formData);
      if (response) {
        if (response.data.res == 1) {
          if (source === "main") {
            let tempArray = [...commentDetails];
            tempArray.splice(index, 1);
            setCommentDetails(tempArray);
          } else {
            let tempArray = [...commentDetails];
            let mainObj = tempArray.find(item => parseInt(item?.id) === parseInt(id));
            mainObj.reply.splice(index, 1);
            setCommentDetails(tempArray);
          }
          let tempObj = {...blogDetails};
          // tempObj.total_comms = parseInt(tempObj.total_comms) - 1;
          tempObj.total_comms = response.data.total_comms;
          setBlogDetails(tempObj);
        } else {
        }
      }
    } catch (error) {}
  };

  // Handle click like comment
  const handleCommentRemoveLike = async (item, subIndex, index, source) => {
    let tempArray = [...commentDetails];
    if (source === "main") {
      let selectedItem = tempArray[index];
      let newLikeObj = selectedItem.likes_json === "None" ? null : selectedItem.likes_json;
      selectedItem.likes_int = parseInt(selectedItem.likes_int) > 0 ? parseInt(selectedItem.likes_int) - 1 : 0;
      delete newLikeObj[userDetail?.id];
      try {
        const formData = new FormData();
        formData.append("comment_id", selectedItem.id);
        formData.append("likes_json", JSON.stringify(newLikeObj));
        formData.append("likes_int", selectedItem.likes_int);
        let response = await blogCommentReplyLikeApi(formData);
        if (response) {
          if (response.data.res == 1) {
            setCommentDetails(tempArray);
          }
        }
      } catch (error) {}
      // console.log(newLikeObj);
    } else {
      let selectedItem = tempArray[index];
      let childSelectedItem = selectedItem?.reply[subIndex];
      let newLikeObj = childSelectedItem.likes_json === "None" ? {} : childSelectedItem.likes_json;
      childSelectedItem.likes_int = parseInt(childSelectedItem.likes_int) > 0 ? parseInt(childSelectedItem.likes_int) - 1 : 0;
      delete newLikeObj[userDetail?.id];
      // setCommentDetails(tempArray);
      // return;
      try {
        const formData = new FormData();
        formData.append("comment_id", childSelectedItem.id);
        formData.append("likes_json", JSON.stringify(newLikeObj));
        formData.append("likes_int", childSelectedItem.likes_int);
        let response = await blogCommentReplyLikeApi(formData);
        if (response) {
          if (response.data.res == 1) {
            setCommentDetails(tempArray);
          }
        }
      } catch (error) {}
      // console.log(newLikeObj);
    }
  };

  // Handle click like comment
  const handleCommentLike = async (item, subIndex, index, source) => {
    let tempArray = [...commentDetails];
    if (source === "main") {
      let selectedItem = tempArray[index];
      let newLikeObj = selectedItem.likes_json === "None" || selectedItem.likes_json === null ? {} : selectedItem.likes_json;
      // console.log(newLikeObj);
      // return;
      selectedItem.likes_int = parseInt(selectedItem.likes_int) + 1;
      // if (selectedItem.likes_json === "None") {
      newLikeObj[userDetail?.id] = userDetail?.id;
      // } else {
      // }
      try {
        const formData = new FormData();
        formData.append("comment_id", selectedItem.id);
        formData.append("likes_json", JSON.stringify(newLikeObj));
        formData.append("likes_int", selectedItem.likes_int);
        let response = await blogCommentReplyLikeApi(formData);
        if (response) {
          if (response.data.res == 1) {
            setCommentDetails(tempArray);
          }
        }
      } catch (error) {}
    } else {
      let selectedItem = tempArray[index];
      let childSelectedItem = selectedItem?.reply[subIndex];
      let newLikeObj = childSelectedItem.likes_json === "None" || selectedItem.likes_json === null ? {} : childSelectedItem.likes_json;
      childSelectedItem.likes_int = parseInt(childSelectedItem.likes_int) + 1;
      // Modify the newLikeObj
      newLikeObj[userDetail?.id] = userDetail?.id;
      let selectedItemCopy = {...selectedItem};
      selectedItemCopy.reply[subIndex].likes_json = newLikeObj;
      // // Create a copy of tempArray with the updated selectedItem
      let updatedTempArray = [...tempArray];
      updatedTempArray[index] = selectedItemCopy;
      try {
        const formData = new FormData();
        formData.append("comment_id", childSelectedItem.id);
        formData.append("likes_json", JSON.stringify(newLikeObj));
        formData.append("likes_int", childSelectedItem.likes_int);
        let response = await blogCommentReplyLikeApi(formData);
        if (response) {
          if (response.data.res == 1) {
            setCommentDetails(updatedTempArray);
          }
        }
      } catch (error) {}
      // console.log(newLikeObj);
    }
  };

  // Function to toggle the state of a specific comment
  const toggleComment = index => {
    setClickedCommentIndex(index);
    const newIsOpen = [...open]; // Create a copy of the state array
    newIsOpen[index] = !newIsOpen[index]; // Toggle the state for the clicked comment
    setOpen(newIsOpen); // Update the state
  };

  // Handle add comment button click
  const onHandleLeaveReplyComment = async () => {
    if (!replyCommentMessage) {
      return;
    }
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("user_id", userDetail?.id);
      formData.append("post_id", blogId);
      formData.append("comment", replyCommentMessage);
      formData.append("target_uid", parentUserId);
      formData.append("comment_id", parentCommentId);
      let response = await blogCommentReplyApi(formData);
      if (response) {
        if (response.data.res == 1) {
          // fetchComments(currentPageForComment, commentSort);
          let tempAllDetailsObj = {...blogDetails};
          tempAllDetailsObj.total_comms = parseInt(tempAllDetailsObj.total_comms) + 1;
          setBlogDetails(tempAllDetailsObj);
          let tempArray = [...commentDetails];
          let tempObj = tempArray.find(item => parseInt(item.id) === parseInt(parentCommentId));
          tempObj.reply.push(response.data.data[0]);
          setCommentDetails(tempArray);
          setReplyCommentMessage("");
          setModalOpenReply(false);
        } else {
        }
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  // Fetching blog comments
  const fetchBlogComments = async sort => {
    try {
      const formData = new FormData();
      formData.append("post_id", blogId);
      formData.append("sort", sort);
      let response = await blogFetchCommentApi(formData);
      if (response) {
        if (response.data.res == 1) {
          setCommentDetails(response.data.data);
          setOpen(new Array(response.data.data.length).fill(false));
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchBlogDetails();
    setAvatar(BASE_URL + "static/" + userDetail?.avatar);
  }, []);

  useEffect(() => {
    fetchBlogComments(commentSort);
  }, [commentSort]);

  return (
    <div>
      <Header />
      <PlayerSidebar />
      <div className="content-wrapper blog-content-wrapper">
        <div className="content">
          <div className="blog-details">
            <div className="post-palyer-holder">
              <img src={blogDetails?.thumbnail} alt="image" />
            </div>
            <div className="post-info-holder">
              <div className="post-views">
                <time>{blogDetails?.date}</time>
              </div>
              <h4 className="post-name">{blogDetails?.name}</h4>
            </div>
            <div className="post-description-holder">
              <div className="description">
                <p></p>
              </div>
            </div>
          </div>
          <div className="col-md-12 player-left-sec">
            <div className="video-comments">
              <div className="video-comments-sorting d-flex">
                <div className="total-comments d-flex">
                  <strong>{blogDetails?.total_comms ? blogDetails?.total_comms : 0}</strong>
                  <span>{langJson?.comments}</span>
                </div>
                <Dropdown>
                  <Dropdown.Toggle variant="link" id="dropdown-basic">
                    <svg xmlns="http://www.w3.org/2000/svg" width={512} height={512} viewBox="0 0 512 512">
                      <path d="M496,432H400V80h96Z" />
                      <path d="M368,432H272V160h96Z" />
                      <path d="M240,432H144V224h96Z" />
                      <path d="M112,432H16V288h96Z" />
                    </svg>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => setCommentSort("desc")}>{langJson?.newest_comments_first}</Dropdown.Item>
                    <Dropdown.Item onClick={() => setCommentSort("asc")}>{langJson?.oldest_comments_first}</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              {userDetail?.id ? (
                <div className="video-comment-form-wrapper">
                  <div className="video-comment-form-body d-flex">
                    <div className="lp">
                      <div className="avatar-image">
                        <img src={avatar} alt="Avatar" />
                      </div>
                    </div>
                    <div className="rp">
                      <form className="form">
                        <div className="form-group">
                          <textarea
                            placeholder={langJson?.write_a_comment + "..."}
                            className="form-control textarea-control"
                            value={commentMessage}
                            onChange={e => setCommentMessage(e.target.value)}></textarea>
                        </div>
                        <div className="form-group form-ctrls d-flex">
                          <Button type="button" className="btn-custom main-outline lg" disabled={!commentMessage} onClick={() => setCommentMessage("")}>
                            {langJson?.cancel}
                          </Button>
                          <Button
                            type="button"
                            className="btn-custom main-inline"
                            disabled={!commentMessage || loadingBtn}
                            onClick={() => onHandleLeaveComment()}>
                            {langJson?.leave_a_comment}
                          </Button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              ) : null}

              <div className="video-comments-list-wrapper">
                <div className="video-comments-list">
                  {commentDetails &&
                    commentDetails?.map((item, index) => (
                      <div className="video-comments-list-item d-flex" key={index}>
                        <div className="user-avatar">
                          <div className="avatar">
                            <img src={item?.avatar} alt="Avatar" />
                          </div>
                        </div>
                        <div className="comment-body">
                          <div className="user-name d-flex">
                            <div className="flex-item">
                              <NavLink className="uname" to={`/${item?.username}`}>
                                {item?.fname} {item?.lname}
                              </NavLink>
                              <time>{item?.time}</time>
                            </div>
                            {userDetail && parseInt(userDetail?.id) === parseInt(item?.user_id) ? (
                              <div className="flex-item ms-auto">
                                <Button className="delete-comment" onClick={() => onDeleteComment(item?.id, null, index, "main")}>
                                  <svg
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="48px"
                                    height="48px"
                                    viewBox="0 0 24 24"
                                    aria-labelledby="binIconTitle"
                                    stroke="#2329D6"
                                    strokeWidth={1}
                                    strokeLinecap="square"
                                    strokeLinejoin="miter"
                                    fill="none"
                                    color="#2329D6">
                                    <path d="M19 6L5 6M14 5L10 5M6 10L6 20C6 20.6666667 6.33333333 21 7 21 7.66666667 21 11 21 17 21 17.6666667 21 18 20.6666667 18 20 18 19.3333333 18 16 18 10" />
                                  </svg>
                                </Button>
                              </div>
                            ) : null}
                          </div>
                          <div className="comment-text">
                            <p>{item?.comment}</p>
                          </div>
                          <div className="comment-inline-ctrls">
                            <div className="d-flex flex-nw align-items-center">
                              {userDetail?.id ? (
                                <>
                                  <div className="flex-item">
                                    {item?.likes_json !== "None" && item?.likes_json !== null && item?.likes_json[userDetail?.id] ? (
                                      // If exists
                                      <Button
                                        className={
                                          item?.likes_json !== "None" && item?.likes_json !== null
                                            ? `btn-ctrl-item d-flex ${item?.likes_json[userDetail?.id] ? "active" : ""}`
                                            : "btn-ctrl-item d-flex"
                                        }
                                        onClick={() => handleCommentRemoveLike(item, null, index, "main")}>
                                        <span className="icon">
                                          <svg
                                            role="img"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="48px"
                                            height="48px"
                                            viewBox="0 0 24 24"
                                            aria-labelledby="favouriteIconTitle"
                                            stroke="#2329D6"
                                            strokeWidth={1}
                                            strokeLinecap="square"
                                            strokeLinejoin="miter"
                                            fill="none"
                                            color="#2329D6">
                                            <path d="M12,21 L10.55,19.7051771 C5.4,15.1242507 2,12.1029973 2,8.39509537 C2,5.37384196 4.42,3 7.5,3 C9.24,3 10.91,3.79455041 12,5.05013624 C13.09,3.79455041 14.76,3 16.5,3 C19.58,3 22,5.37384196 22,8.39509537 C22,12.1029973 18.6,15.1242507 13.45,19.7149864 L12,21 Z" />
                                          </svg>
                                        </span>
                                        <span className="txt-label m-left">{parseInt(item?.likes_int)}</span>
                                      </Button>
                                    ) : (
                                      <Button
                                        className={
                                          item?.likes_json !== "None" && item?.likes_json !== null
                                            ? `btn-ctrl-item d-flex ${item?.likes_json[userDetail?.id] ? "active" : ""}`
                                            : "btn-ctrl-item d-flex"
                                        }
                                        onClick={() => handleCommentLike(item, null, index, "main")}>
                                        <span className="icon">
                                          <svg
                                            role="img"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="48px"
                                            height="48px"
                                            viewBox="0 0 24 24"
                                            aria-labelledby="favouriteIconTitle"
                                            stroke="#2329D6"
                                            strokeWidth={1}
                                            strokeLinecap="square"
                                            strokeLinejoin="miter"
                                            fill="none"
                                            color="#2329D6">
                                            <path d="M12,21 L10.55,19.7051771 C5.4,15.1242507 2,12.1029973 2,8.39509537 C2,5.37384196 4.42,3 7.5,3 C9.24,3 10.91,3.79455041 12,5.05013624 C13.09,3.79455041 14.76,3 16.5,3 C19.58,3 22,5.37384196 22,8.39509537 C22,12.1029973 18.6,15.1242507 13.45,19.7149864 L12,21 Z" />
                                          </svg>
                                        </span>
                                        <span className="txt-label m-left">{parseInt(item?.likes_int)}</span>
                                      </Button>
                                    )}
                                  </div>
                                  <div className="flex-item">
                                    <Button
                                      className="btn-ctrl-item d-flex"
                                      onClick={() => {
                                        setParentUserId(item?.user_id);
                                        setParentCommentId(item?.id);
                                        setModalOpenReply(true);
                                        setParentComment(item);
                                      }}>
                                      <span className="icon">
                                        <svg
                                          width="48px"
                                          height="48px"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                          aria-labelledby="featherIconTitle"
                                          stroke="#2329D6"
                                          strokeWidth={1}
                                          strokeLinecap="square"
                                          strokeLinejoin="miter"
                                          color="#2329D6">
                                          <title id="featherIconTitle">{"Feather"}</title>
                                          <path d="M5.12634 17C5.04271 17.6571 5 18.325 5 19V21M5.12634 17C6.03384 9.86861 11.7594 4 20 4L19 8H16L17 10L15 12H11L13 14L12 16H8L5.12634 17Z" />
                                        </svg>
                                      </span>
                                      <span className="txt-label m-left">{langJson?.reply}</span>
                                    </Button>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="flex-item">
                                    {item?.likes_json !== "None" && item?.likes_json[userDetail?.id] ? (
                                      // If exists
                                      <Button
                                        className={
                                          item?.likes_json !== "None"
                                            ? `btn-ctrl-item d-flex ${item?.likes_json[userDetail?.id] ? "active" : ""}`
                                            : "btn-ctrl-item d-flex"
                                        }
                                        onClick={() => setLoginModalOpen(true)}>
                                        <span className="icon">
                                          <svg
                                            role="img"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="48px"
                                            height="48px"
                                            viewBox="0 0 24 24"
                                            aria-labelledby="favouriteIconTitle"
                                            stroke="#2329D6"
                                            strokeWidth={1}
                                            strokeLinecap="square"
                                            strokeLinejoin="miter"
                                            fill="none"
                                            color="#2329D6">
                                            <path d="M12,21 L10.55,19.7051771 C5.4,15.1242507 2,12.1029973 2,8.39509537 C2,5.37384196 4.42,3 7.5,3 C9.24,3 10.91,3.79455041 12,5.05013624 C13.09,3.79455041 14.76,3 16.5,3 C19.58,3 22,5.37384196 22,8.39509537 C22,12.1029973 18.6,15.1242507 13.45,19.7149864 L12,21 Z" />
                                          </svg>
                                        </span>
                                        <span className="txt-label m-left">{parseInt(item?.likes_int)}</span>
                                      </Button>
                                    ) : (
                                      <Button
                                        className={
                                          item?.likes_json !== "None"
                                            ? `btn-ctrl-item d-flex ${item?.likes_json[userDetail?.id] ? "active" : ""}`
                                            : "btn-ctrl-item d-flex"
                                        }
                                        onClick={() => setLoginModalOpen(true)}>
                                        <span className="icon">
                                          <svg
                                            role="img"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="48px"
                                            height="48px"
                                            viewBox="0 0 24 24"
                                            aria-labelledby="favouriteIconTitle"
                                            stroke="#2329D6"
                                            strokeWidth={1}
                                            strokeLinecap="square"
                                            strokeLinejoin="miter"
                                            fill="none"
                                            color="#2329D6">
                                            <path d="M12,21 L10.55,19.7051771 C5.4,15.1242507 2,12.1029973 2,8.39509537 C2,5.37384196 4.42,3 7.5,3 C9.24,3 10.91,3.79455041 12,5.05013624 C13.09,3.79455041 14.76,3 16.5,3 C19.58,3 22,5.37384196 22,8.39509537 C22,12.1029973 18.6,15.1242507 13.45,19.7149864 L12,21 Z" />
                                          </svg>
                                        </span>
                                        <span className="txt-label m-left">{parseInt(item?.likes_int)}</span>
                                      </Button>
                                    )}
                                  </div>
                                  <div className="flex-item">
                                    <Button className="btn-ctrl-item d-flex" onClick={() => setLoginModalOpen(true)}>
                                      <span className="icon">
                                        <svg
                                          width="48px"
                                          height="48px"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                          aria-labelledby="featherIconTitle"
                                          stroke="#2329D6"
                                          strokeWidth={1}
                                          strokeLinecap="square"
                                          strokeLinejoin="miter"
                                          color="#2329D6">
                                          <title id="featherIconTitle">{"Feather"}</title>
                                          <path d="M5.12634 17C5.04271 17.6571 5 18.325 5 19V21M5.12634 17C6.03384 9.86861 11.7594 4 20 4L19 8H16L17 10L15 12H11L13 14L12 16H8L5.12634 17Z" />
                                        </svg>
                                      </span>
                                      <span className="txt-label m-left">{langJson?.reply}</span>
                                    </Button>
                                  </div>
                                </>
                              )}

                              {item?.reply?.length > 0 ? (
                                <div className="flex-item">
                                  <Button
                                    className="btn-ctrl-item d-flex"
                                    onClick={() => toggleComment(index)}
                                    aria-controls="example-collapse-text"
                                    aria-expanded={open}>
                                    <span className="txt-label m-right">
                                      {langJson?.show_replies} ({item?.reply?.length})
                                    </span>
                                    <span className="icon">
                                      <svg xmlns="http://www.w3.org/2000/svg" width={512} height={512} viewBox="0 0 512 512">
                                        <polyline
                                          points="112 184 256 328 400 184"
                                          style={{
                                            fill: "none",
                                            strokeLinecap: "round",
                                            strokeLinejoin: "round",
                                            strokeWidth: 48,
                                          }}
                                        />
                                      </svg>
                                    </span>
                                  </Button>
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <Collapse in={open[index]}>
                            <div id="example-collapse-text" className="video-comments-list-wrapper">
                              <div className="video-comments-list">
                                {item?.reply?.map((subItem, subIndex) => (
                                  <div className="video-comments-list-item d-flex" key={index}>
                                    <div className="user-avatar">
                                      <div className="avatar">
                                        <img src={subItem?.avatar} alt="Avatar" />
                                      </div>
                                    </div>
                                    <div className="comment-body">
                                      <div className="user-name d-flex">
                                        <div className="flex-item">
                                          <NavLink className="uname" to={`/${subItem?.username}`}>
                                            {subItem?.fname} {subItem?.lname}
                                          </NavLink>
                                          <time>{subItem?.time}</time>
                                        </div>
                                        {userDetail && parseInt(userDetail?.id) === parseInt(subItem?.user_id) ? (
                                          <div className="flex-item ms-auto">
                                            <Button className="delete-comment" onClick={() => onDeleteComment(item?.id, subItem?.id, subIndex, "reply")}>
                                              <svg
                                                role="img"
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="48px"
                                                height="48px"
                                                viewBox="0 0 24 24"
                                                aria-labelledby="binIconTitle"
                                                stroke="#2329D6"
                                                strokeWidth={1}
                                                strokeLinecap="square"
                                                strokeLinejoin="miter"
                                                fill="none"
                                                color="#2329D6">
                                                <path d="M19 6L5 6M14 5L10 5M6 10L6 20C6 20.6666667 6.33333333 21 7 21 7.66666667 21 11 21 17 21 17.6666667 21 18 20.6666667 18 20 18 19.3333333 18 16 18 10" />
                                              </svg>
                                            </Button>
                                          </div>
                                        ) : null}
                                      </div>
                                      <div className="comment-text">
                                        <p>
                                          <NavLink className="target-uname" to={`/${subItem?.username}`}>
                                            {subItem?.username}
                                          </NavLink>
                                          {subItem?.comment}
                                        </p>
                                      </div>
                                      <div className="comment-inline-ctrls">
                                        {userDetail?.id ? (
                                          <div className="d-flex flex-nw align-items-center">
                                            <div className="flex-item">
                                              {subItem?.likes_json !== "None" && subItem?.likes_json[userDetail?.id] ? (
                                                <Button
                                                  className={
                                                    subItem?.likes_json !== "None"
                                                      ? `btn-ctrl-item d-flex ${subItem?.likes_json[userDetail?.id] ? "active" : ""}`
                                                      : "btn-ctrl-item d-flex"
                                                  }
                                                  onClick={() => handleCommentRemoveLike(subItem, subIndex, index, "reply")}>
                                                  <span className="icon">
                                                    <svg
                                                      role="img"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      width="48px"
                                                      height="48px"
                                                      viewBox="0 0 24 24"
                                                      aria-labelledby="favouriteIconTitle"
                                                      stroke="#2329D6"
                                                      strokeWidth={1}
                                                      strokeLinecap="square"
                                                      strokeLinejoin="miter"
                                                      fill="none"
                                                      color="#2329D6">
                                                      <path d="M12,21 L10.55,19.7051771 C5.4,15.1242507 2,12.1029973 2,8.39509537 C2,5.37384196 4.42,3 7.5,3 C9.24,3 10.91,3.79455041 12,5.05013624 C13.09,3.79455041 14.76,3 16.5,3 C19.58,3 22,5.37384196 22,8.39509537 C22,12.1029973 18.6,15.1242507 13.45,19.7149864 L12,21 Z" />
                                                    </svg>
                                                  </span>
                                                  <span className="txt-label m-left">{subItem?.likes_int}</span>
                                                </Button>
                                              ) : (
                                                <Button
                                                  className={
                                                    subItem?.likes_json !== "None"
                                                      ? `btn-ctrl-item d-flex ${subItem?.likes_json[userDetail?.id] ? "active" : ""}`
                                                      : "btn-ctrl-item d-flex"
                                                  }
                                                  onClick={() => handleCommentLike(subItem, subIndex, index, "reply")}>
                                                  <span className="icon">
                                                    <svg
                                                      role="img"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      width="48px"
                                                      height="48px"
                                                      viewBox="0 0 24 24"
                                                      aria-labelledby="favouriteIconTitle"
                                                      stroke="#2329D6"
                                                      strokeWidth={1}
                                                      strokeLinecap="square"
                                                      strokeLinejoin="miter"
                                                      fill="none"
                                                      color="#2329D6">
                                                      <path d="M12,21 L10.55,19.7051771 C5.4,15.1242507 2,12.1029973 2,8.39509537 C2,5.37384196 4.42,3 7.5,3 C9.24,3 10.91,3.79455041 12,5.05013624 C13.09,3.79455041 14.76,3 16.5,3 C19.58,3 22,5.37384196 22,8.39509537 C22,12.1029973 18.6,15.1242507 13.45,19.7149864 L12,21 Z" />
                                                    </svg>
                                                  </span>
                                                  <span className="txt-label m-left">{subItem?.likes_int}</span>
                                                </Button>
                                              )}
                                            </div>
                                            <div className="flex-item">
                                              <Button
                                                className="btn-ctrl-item d-flex"
                                                onClick={() => {
                                                  setParentUserId(item?.user_id);
                                                  setParentCommentId(item?.id);
                                                  setModalOpenReply(true);
                                                  setParentComment(item);
                                                }}>
                                                <span className="icon">
                                                  <svg
                                                    width="48px"
                                                    height="48px"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    aria-labelledby="featherIconTitle"
                                                    stroke="#2329D6"
                                                    strokeWidth={1}
                                                    strokeLinecap="square"
                                                    strokeLinejoin="miter"
                                                    color="#2329D6">
                                                    <title id="featherIconTitle">{"Feather"}</title>
                                                    <path d="M5.12634 17C5.04271 17.6571 5 18.325 5 19V21M5.12634 17C6.03384 9.86861 11.7594 4 20 4L19 8H16L17 10L15 12H11L13 14L12 16H8L5.12634 17Z" />
                                                  </svg>
                                                </span>
                                                <span className="txt-label m-left">{langJson?.reply}</span>
                                              </Button>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="d-flex flex-nw align-items-center">
                                            <div className="flex-item">
                                              {subItem?.likes_json !== "None" && subItem?.likes_json[userDetail?.id] ? (
                                                <Button
                                                  className={
                                                    subItem?.likes_json !== "None"
                                                      ? `btn-ctrl-item d-flex ${subItem?.likes_json[userDetail?.id] ? "active" : ""}`
                                                      : "btn-ctrl-item d-flex"
                                                  }
                                                  onClick={() => setLoginModalOpen(true)}>
                                                  <span className="icon">
                                                    <svg
                                                      role="img"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      width="48px"
                                                      height="48px"
                                                      viewBox="0 0 24 24"
                                                      aria-labelledby="favouriteIconTitle"
                                                      stroke="#2329D6"
                                                      strokeWidth={1}
                                                      strokeLinecap="square"
                                                      strokeLinejoin="miter"
                                                      fill="none"
                                                      color="#2329D6">
                                                      <path d="M12,21 L10.55,19.7051771 C5.4,15.1242507 2,12.1029973 2,8.39509537 C2,5.37384196 4.42,3 7.5,3 C9.24,3 10.91,3.79455041 12,5.05013624 C13.09,3.79455041 14.76,3 16.5,3 C19.58,3 22,5.37384196 22,8.39509537 C22,12.1029973 18.6,15.1242507 13.45,19.7149864 L12,21 Z" />
                                                    </svg>
                                                  </span>
                                                  <span className="txt-label m-left">{subItem?.likes_int}</span>
                                                </Button>
                                              ) : (
                                                <Button
                                                  className={
                                                    subItem?.likes_json !== "None"
                                                      ? `btn-ctrl-item d-flex ${subItem?.likes_json[userDetail?.id] ? "active" : ""}`
                                                      : "btn-ctrl-item d-flex"
                                                  }
                                                  onClick={() => setLoginModalOpen(true)}>
                                                  <span className="icon">
                                                    <svg
                                                      role="img"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      width="48px"
                                                      height="48px"
                                                      viewBox="0 0 24 24"
                                                      aria-labelledby="favouriteIconTitle"
                                                      stroke="#2329D6"
                                                      strokeWidth={1}
                                                      strokeLinecap="square"
                                                      strokeLinejoin="miter"
                                                      fill="none"
                                                      color="#2329D6">
                                                      <path d="M12,21 L10.55,19.7051771 C5.4,15.1242507 2,12.1029973 2,8.39509537 C2,5.37384196 4.42,3 7.5,3 C9.24,3 10.91,3.79455041 12,5.05013624 C13.09,3.79455041 14.76,3 16.5,3 C19.58,3 22,5.37384196 22,8.39509537 C22,12.1029973 18.6,15.1242507 13.45,19.7149864 L12,21 Z" />
                                                    </svg>
                                                  </span>
                                                  <span className="txt-label m-left">{subItem?.likes_int}</span>
                                                </Button>
                                              )}
                                            </div>
                                            <div className="flex-item">
                                              <Button className="btn-ctrl-item d-flex" onClick={() => setLoginModalOpen(true)}>
                                                <span className="icon">
                                                  <svg
                                                    width="48px"
                                                    height="48px"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    aria-labelledby="featherIconTitle"
                                                    stroke="#2329D6"
                                                    strokeWidth={1}
                                                    strokeLinecap="square"
                                                    strokeLinejoin="miter"
                                                    color="#2329D6">
                                                    <title id="featherIconTitle">{"Feather"}</title>
                                                    <path d="M5.12634 17C5.04271 17.6571 5 18.325 5 19V21M5.12634 17C6.03384 9.86861 11.7594 4 20 4L19 8H16L17 10L15 12H11L13 14L12 16H8L5.12634 17Z" />
                                                  </svg>
                                                </span>
                                                <span className="txt-label m-left">{langJson?.reply}</span>
                                              </Button>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </Collapse>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />

      {/* Comment reply Modal Start */}
      <Modal show={modalOpenReply} onHide={() => setModalOpenReply(false)} className="cmnModal replyCommentModal">
        <div className="modal-header">
          <h5 className="modal-title">
            {langJson?.reply_to_comment}{" "}
            <Link to={`/${parentComment?.username}`}>
              {parentComment?.fname} {parentComment?.lname}
            </Link>
          </h5>
          <Button className="close" onClick={() => setModalOpenReply(false)}>
            <svg xmlns="http://www.w3.org/2000/svg" width={512} height={512} viewBox="0 0 512 512">
              <line
                x1={368}
                y1={368}
                x2={144}
                y2={144}
                style={{
                  fill: "none",
                  strokeLinecap: "round",
                  strokeLinejoin: "round",
                  strokeWidth: 32,
                }}
              />
              <line
                x1={368}
                y1={144}
                x2={144}
                y2={368}
                style={{
                  fill: "none",
                  strokeLinecap: "round",
                  strokeLinejoin: "round",
                  strokeWidth: 32,
                }}
              />
            </svg>
          </Button>
        </div>
        <Modal.Body>
          <div className="video-comment-form">
            <div className="video-comment-form-body">
              <div className="rp">
                <form className="form">
                  <div className="form-group">
                    <textarea
                      name="text"
                      data-an="comment-input"
                      placeholder={langJson?.write_a_comment + "..."}
                      className="form-control empty"
                      value={replyCommentMessage}
                      onChange={e => setReplyCommentMessage(e.target.value)}
                    />
                  </div>
                  <div className="form-group form-ctrls">
                    <Button disabled={!replyCommentMessage} onClick={() => setReplyCommentMessage("")} className="btn btn-custom main-outline lg">
                      {langJson?.cancel}
                    </Button>
                    <Button onClick={() => onHandleLeaveReplyComment()} disabled={!replyCommentMessage} className="btn btn-custom main-inline lg">
                      {langJson?.leave_a_comment}
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* Comment reply Modal End */}
    </div>
  );
};

export default BlogDetails;
