import React from "react";
import ChannelBanner from "./channelBanner/ChannelBanner";

const ChannelLayout = ({children}) => {
  return (
    <div>
      <ChannelBanner />
      {children}
    </div>
  );
};

export default ChannelLayout;
