import React from "react";
import ReactDOM from "react-dom/client";
import ScrollToTop from "./ScrollToTop";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {BrowserRouter} from "react-router-dom";
import {LanguageProvider} from "./utils/LanguageProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // "homepage": "https://demoupdates.com/updates/diakanext/html/",
  // <BrowserRouter basename="/updates/diakanext/html/">
  // <BrowserRouter basename="">
  // <React.StrictMode></React.StrictMode>
  // <BrowserRouter basename="/betafront/">
  <BrowserRouter basename="">
    <ScrollToTop>
      <LanguageProvider>
        <App />
      </LanguageProvider>
    </ScrollToTop>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
