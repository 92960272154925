import React, {useState, useEffect, useRef} from "react";
import axios from "axios";
import {NavLink, useNavigate} from "react-router-dom";
import "./GeneralSettings.scss";
import "../user-common.scss";
import AfterLoginHeader from "../../../include/AfterLoginHeader";
import AfterLoginSidebar2 from "../../../include/AfterLoginSidebar2";
import SettingsBanner from "../../../include/settingsBanner/SettingsBanner";
import Footer from "../../../include/Footer";
import Button from "react-bootstrap/Button";
import {NotificationManager} from "react-notifications";
import {BASE_URL} from "../../../app_url";
import {useLanguage} from "../../../utils/LanguageProvider";

const GeneralSettings = props => {
  const navigate = useNavigate();
  const {langJson} = useLanguage();
  const [loginData, setLoginData] = useState();
  const [userDetails, setUserDetails] = useState([]);
  const [storedUserData, setStoredUserData] = useState();

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loginInfo, setLoginInfo] = useState("");
  const sidebarRef = useRef(null);
  const [countryList, setCountryList] = useState([]);

  useEffect(() => {
    let value = JSON.parse(localStorage.getItem("loginInfo")); //JSON.parse(secureLocalStorage.getItem('loginInfo'));
    setStoredUserData(value["token"]);
    if (value == undefined) {
      navigate("/");
    }
    //console.log(value['user']['id']);
    //console.log(value);
    if (value) {
      setLoginData(value["user"]);
    }
    getUserData(value["user"]["id"], value["token"]);
    getCountryList();
  }, []);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    country_id: "",
    email: "",
    username: "",
    about: "",
  });

  const [formErrors, setFormErrors] = useState({
    firstName: "",
    lastName: "",
  });

  const validateForm = () => {
    let isValid = true;
    const errors = {
      firstName: "",
      lastName: "",
    };

    if (formData.firstName === "") {
      errors.firstName = langJson?.first_name_is_required;
      isValid = false;
    }

    if (formData.lastName === "") {
      errors.lastName = langJson?.last_name_is_required;
      isValid = false;
    }

    // if (formData.username === '') {
    //   errors.username = 'Username is required';
    //   isValid = false;
    // } else if (formData.username.length < 3 || formData.username.length > 20) {
    //   errors.username = 'Username must be between 3 and 20 characters';
    //   isValid = false;
    // }

    // if (formData.email === '') {
    //   errors.email = 'Email is required';
    //   isValid = false;
    // } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
    //   errors.email = 'Invalid email format';
    //   isValid = false;
    // }

    // if (formData.password === '') {
    //   errors.password = 'Password is required';
    //   isValid = false;
    // } else if (
    //   !/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}/.test(formData.password)
    // ) {
    //   errors.password = 'Password should be strong (example: Abc@1234)';
    //   isValid = false;
    // }

    // if (formData.confirmPassword === '') {
    //   errors.confirmPassword = 'Confirm password is required';
    //   isValid = false;
    // } else if (formData.confirmPassword !== formData.password) {
    //   errors.confirmPassword = 'Passwords do not match';
    //   isValid = false;
    // }

    // if (!formData.agreedToTerms) {
    //   errors.agreedToTerms = 'You must agree to the terms';
    //   isValid = false;
    // }

    setFormErrors(errors);
    return isValid;
  };

  const handleSubmit = e => {
    e.preventDefault();

    if (validateForm()) {
      setIsSubmitting(true);
      // Send the signup data to your Python Flask API
      // You can use libraries like Axios to make API requests
      // Example:
      axios
        .post(`${BASE_URL}editprofile`, JSON.stringify(formData), {
          headers: {
            "Content-Type": "application/json", // Set the content type
            Authorization: `Bearer ${storedUserData}`,
          },
        })
        .then(response => {
          setIsSubmitting(false);
          setSuccessMessage(response.data.message);
          setErrorMessage(""); // Clear any previous error messages

          setFormErrors({
            firstName: "",
            lastName: "",
            about: "",
          });
        })
        .catch(error => {
          setIsSubmitting(false);
          if (error.response) {
            setErrorMessage(error.response.data.error || langJson?.something_went_wrong_please_try_again);
          } else {
            setErrorMessage(langJson?.something_went_wrong_please_try_again);
          }
          setSuccessMessage(""); // Clear any previous success messages
        });
    }
  };

  const handleChange = e => {
    const {name, value, type, checked} = e.target;

    // Clear the error message associated with the field
    setFormErrors(prevErrors => ({...prevErrors, [name]: ""}));
    setFormData({...formData, [name]: value});
  };

  const getUserData = async (userId, token) => {
    try {
      let response = await axios.post(
        `${BASE_URL}userdetailsbyid`,
        {
          userId: userId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response) {
        // response = JSON.stringify(response);
        //console.log(response);
        if (response.data.res === true) {
          //console.log(response.data.data.fname);
          setUserDetails(response.data.data);
          setFormData({
            username: response.data.data.username,
            firstName: response.data.data.fname,
            lastName: response.data.data.lname,
            email: response.data.data.email,
            country_id: response.data.data.country_id,
            about: response.data.data.about,
          });
        } else {
          NotificationManager.error(langJson?.something_went_wrong_please_try_again, "", 3000);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCountryList = async () => {
    try {
      let response = await axios.post(
        `${BASE_URL}getcountry`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response) {
        // alert(JSON.parse(response));
        // response = JSON.parse(response);
        // console.log(response);

        //setCountryList(response);
        if (response.data.res === true) {
          //alert(response.data.countries);
          setCountryList(response.data.countries);
        } else {
          NotificationManager.error(langJson?.something_went_wrong_please_try_again, "", 3000);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <AfterLoginHeader />
      <AfterLoginSidebar2 />
      <div className="content-wrapper">
        <div className="content">
          <div className="content-block-header">
            <h1>
              {langJson?.general} {langJson?.settings}
            </h1>
          </div>
          <div className="white-settings-section">
            <SettingsBanner />
            <form className="form" onSubmit={handleSubmit}>
              <div className="seetings-bottom-section">
                <h3>{langJson?.general_account_settings}</h3>
                {successMessage && <div className="success-message">{successMessage}</div>}
                {errorMessage && <div className="error-message">{errorMessage}</div>}
                <ul className="row setting-form-list">
                  <li className="col-md-12">
                    <label className="form-label">{langJson?.username}</label>
                    <input type="text" className="form-control" value={formData.username} />
                  </li>
                  <li className="col-md-6">
                    <label className="form-label">{langJson?.first_name}</label>
                    <input type="text" className="form-control" value={formData.firstName} name="firstName" onChange={handleChange} />
                    {formErrors.firstName && <span className="error-message">{formErrors.firstName}</span>}
                  </li>
                  <li className="col-md-6">
                    <label className="form-label">{langJson?.last_name}</label>
                    <input type="text" className="form-control" value={formData.lastName} name="lastName" onChange={handleChange} />
                    {formErrors.lastName && <span className="error-message">{formErrors.lastName}</span>}
                  </li>
                  <li className="col-md-6">
                    <label className="form-label">{langJson?.e_mail_address}</label>
                    <input type="email" className="form-control" value={formData.email} />
                  </li>
                  <li className="col-md-6">
                    <label className="form-label">{langJson?.country}</label>

                    <select className="form-control" name="country_id" onChange={handleChange} value={formData.country_id}>
                      {Object.entries(countryList).map(([key, value]) => (
                        <option key={key} value={key} selected={formData.country_id === key}>
                          {value}
                        </option>
                      ))}
                      {/* {countryList && countryList.length > 0 && countryList.map((item, index) => (
                        <option >1</option>
                      ))} */}
                    </select>
                  </li>
                  <li className="col-md-12">
                    <label className="form-label">{langJson?.about_me}</label>
                    <input type="text" className="form-control" value={formData.about} name="about" onChange={handleChange} />
                  </li>
                  <li className="col-md-12">
                    <div className="d-grid gap-2">
                      {/* <Button className="btn-custom main-inline btn-block">
                        Save changes!
                      </Button> */}

                      <button type="submit" className={`btn btn-custom main-inline ${isSubmitting ? "disabled" : ""}`} disabled={isSubmitting}>
                        {isSubmitting ? langJson?.updated : langJson?.save_changes}
                      </button>
                    </div>
                  </li>
                </ul>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default GeneralSettings;
