import React, {createContext, useContext, useState, useEffect} from "react";

// Import language JSON files
import EnglishLang from "../assets/langs/english.json";
import FrenchLang from "../assets/langs/french.json";
import GermanLang from "../assets/langs/german.json";
import ItalianLang from "../assets/langs/italian.json";
import RussianLang from "../assets/langs/russian.json";
import PortugueseLang from "../assets/langs/portuguese.json";
import SpanishLang from "../assets/langs/spanish.json";
import TurkishLang from "../assets/langs/turkish.json";
import DutchLang from "../assets/langs/dutch.json";
import UkraineLang from "../assets/langs/ukraine.json";

const LanguageContext = createContext();

export function useLanguage() {
  return useContext(LanguageContext);
}

export function LanguageProvider({children}) {
  const [selectedLanguage, setSelectedLanguage] = useState("en");
  const [langJson, setLangJson] = useState(EnglishLang);
  const [videoCount, setVideoCount] = useState(0);
  const [notificationsCount, setNotificationsCount] = useState(0);

  useEffect(() => {
    // Load the selected language and langJson from localStorage
    const appLang = localStorage.getItem("appLanguage");
    if (appLang) {
      setSelectedLanguage(appLang);
      switch (appLang) {
        case "es":
          setLangJson(SpanishLang);
          break;
        case "de":
          setLangJson(GermanLang);
          break;
        case "it":
          setLangJson(ItalianLang);
          break;
        case "fr":
          setLangJson(FrenchLang);
          break;
        case "ru":
          setLangJson(RussianLang);
          break;
        case "tr":
          setLangJson(TurkishLang);
          break;
        case "pt":
          setLangJson(PortugueseLang);
          break;
        case "nl":
          setLangJson(DutchLang);
          break;
        case "ua":
          setLangJson(UkraineLang);
          break;
        default:
          break;
      }
    }
  }, []);

  const changeLanguage = newLanguage => {
    setSelectedLanguage(newLanguage);
    localStorage.setItem("appLanguage", newLanguage);
    // Load the langJson for the new language
    switch (newLanguage) {
      case "en":
        setLangJson(EnglishLang);
        break;
      case "es":
        setLangJson(SpanishLang);
        break;
      case "de":
        setLangJson(GermanLang);
        break;
      case "it":
        setLangJson(ItalianLang);
        break;
      case "fr":
        setLangJson(FrenchLang);
        break;
      case "ru":
        setLangJson(RussianLang);
        break;
      case "tr":
        setLangJson(TurkishLang);
        break;
      case "pt":
        setLangJson(PortugueseLang);
        break;
      case "nl":
        setLangJson(DutchLang);
        break;
      case "ua":
        setLangJson(UkraineLang);
        break;
      default:
        break;
    }
  };

  return (
    <LanguageContext.Provider value={{selectedLanguage, langJson, changeLanguage, videoCount, setVideoCount, notificationsCount, setNotificationsCount}}>
      {children}
    </LanguageContext.Provider>
  );
}
