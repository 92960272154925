import React, {useState, useEffect, useRef} from "react";
import axios from "axios";
import "./CMS.scss";
import Header from "../../include/Header";
import Sidebar from "../../include/Sidebar";
import Footer from "../../include/Footer";
import {NotificationManager} from "react-notifications";
import {BASE_URL} from "../../app_url";
import {useParams} from "react-router-dom";

import DOMPurify from "dompurify";

const Faqs = () => {
  const [loginData, setLoginData] = useState();
  const [pageData, setPageData] = useState([]);
  const [storedUserData, setStoredUserData] = useState();

  const {pagedata} = useParams();

  // const location = useLocation();
  // const route = location.pathname.replace('/', '');
  //alert(pagedata)
  useEffect(() => {
    // let value = JSON.parse(localStorage.getItem("loginInfo")); //JSON.parse(secureLocalStorage.getItem('loginInfo'));
    // setStoredUserData(value["token"]);
    // // if(value==undefined){
    // //   navigate('/');
    // // }

    // setLoginData(value["user"]);

    //getUserData(value['user']['id'],value['token'])
    getPageDetails(pagedata);
  }, [pagedata]);

  function sanitizeHTML(html) {
    return DOMPurify.sanitize(html);
  }
  const getPageDetails = async name => {
    try {
      let response = await axios.post(
        `${BASE_URL}getpegbyname`,
        {
          name: "faqs",
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response) {
        // alert(JSON.parse(response));
        // response = JSON.parse(response);
        // console.log(response);

        //setCountryList(response);
        if (response.data.res === true) {
          //alert(response.data.countries);
          setPageData(response.data.data);
        } else {
          NotificationManager.error("Something went wrong. Please try again.", "", 3000);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Header />
      <Sidebar />
      <div className="content-wrapper">
        <div className="content-header">
          <h1 className="text-uppercase">{pageData.title}</h1>
        </div>
        <div className="content">
          <div className="stat-page" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(pageData.description)}}>
            {/* {DOMPurify.sanitize(pageData.description)} */}
            {/* dangerouslySetInnerHTML={{ __html: sanitizeHTML(pageData.description) }} */}
            {/* <h4>About Us Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eos corrupti, quaerat officiis quo iure maiores.</h4>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsam obcaecati necessitatibus facilis voluptates commodi sit cumque minus qui laboriosam. Ratione explicabo mollitia laudantium, consectetur dolore laboriosam reprehenderit veniam, quo sed.</p>
                    <p>&nbsp;</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Veritatis omnis inventore cupiditate a optio vel sunt quis sapiente reprehenderit doloremque. Maxime debitis quas libero nostrum cum laudantium, vel repudiandae labore.</p>
                    <p><br /><br /></p>
                    <h4>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus, repellat.</h4>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Doloribus atque pariatur, aperiam, perspiciatis ullam libero eum dolorem quod dolor. Tenetur officiis hic odit quae ratione excepturi suscipit, similique temporibus nam eaque voluptate repudiandae voluptatum earum aliquid repellat magnam molestias, quia ad facilis nemo dicta dolore minus placeat, vero! Blanditiis repellendus, quisquam? Cum ea, qui eveniet culpa quas deleniti eius ipsum quibusdam asperiores quisquam, laborum veniam aut, doloremque neque! Saepe vitae facilis quae molestiae ab alias velit fugiat voluptatum fuga ea labore, vel voluptatem in nulla autem. Vel omnis earum eius quibusdam cupiditate, inventore accusantium eveniet a porro! Nostrum quisquam qui, illum enim iste necessitatibus temporibus nesciunt at harum. Quasi ullam, assumenda illo! Sequi modi totam quae eligendi velit dignissimos! Quaerat praesentium nesciunt itaque quam soluta, et. Nesciunt nihil autem qui animi, tenetur inventore aliquid quasi eum! Perferendis placeat, labore veritatis dolore, nemo, harum iure at facere dolorem, molestiae quisquam! Eius.</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Blanditiis labore cum expedita, nulla reprehenderit. Possimus dolorum dolorem, iure officia error.</p> */}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Faqs;
