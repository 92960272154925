import React, {useEffect, useState} from "react";
import {Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend} from "chart.js";
import {Bar} from "react-chartjs-2";
import {Tooltip as ReactTooltip} from "react-tooltip";
import "./Dashboard.scss";
import "../user-common.scss";
import AfterLoginHeader from "../../../include/AfterLoginHeader";
import AfterLoginSidebar from "../../../include/AfterLoginSidebar2";
import Footer from "../../../include/Footer";
import {useLanguage} from "../../../utils/LanguageProvider";
import {getChannelDashboardApi} from "../../../services/ApiService";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "",
    },
  },
};

const Dashboard = () => {
  const {langJson} = useLanguage();
  const [data, setData] = useState({
    labels: [
      langJson?.january,
      langJson?.february,
      langJson?.march,
      langJson?.april,
      langJson?.may,
      langJson?.june,
      langJson?.july,
      langJson?.august,
      langJson?.september,
      langJson?.october,
      langJson?.november,
      langJson?.december,
    ],
    datasets: [
      {
        label: langJson?.annual_subscriptions,
        //data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
        data: [],
        backgroundColor: "#f79852",
      },
      // {
      //   label: "Dataset 2",
      //   // data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
      //   data: [1, 8, 15],
      //   backgroundColor: "rgba(53, 162, 235, 0.5)",
      // },
    ],
  });

  const [allDetails, setAllDetails] = useState();

  // Fetching channel dashboard details
  const fetchChannelDashboardDetails = async () => {
    try {
      let response = await getChannelDashboardApi();
      if (response) {
        if (response.data.res == 1) {
          setAllDetails(response.data.data);
          setData(prevData => ({
            ...prevData,
            datasets: [
              {
                ...prevData.datasets[0],
                data: response.data.data.annual_stats,
              },
            ],
          }));
        }
      }
    } catch (error) {}
  };

  // Update the label when langJson changes
  useEffect(() => {
    setData(prevData => ({
      ...prevData,
      labels: [
        langJson?.january,
        langJson?.february,
        langJson?.march,
        langJson?.april,
        langJson?.may,
        langJson?.june,
        langJson?.july,
        langJson?.august,
        langJson?.september,
        langJson?.october,
        langJson?.november,
        langJson?.december,
      ],
      datasets: [
        {
          ...prevData.datasets[0],
          label: langJson?.annual_subscriptions,
        },
      ],
    }));
  }, [langJson]);

  useEffect(() => {
    fetchChannelDashboardDetails();
  }, []);

  return (
    <div>
      <AfterLoginHeader />
      <AfterLoginSidebar />
      <div className="content-wrapper">
        <div className="content">
          <div className="content-block-header">
            <h1>{langJson?.channel_dashboard}</h1>
          </div>
          <ul className="row dashboardTopList">
            <li className="col-md-2">
              <div className="info-box d-flex bg-blue">
                <div className="info-box-icon">
                  <div className="icon d-flex">
                    <svg xmlns="http://www.w3.org/2000/svg" width={512} height={512} viewBox="0 0 512 512">
                      <path
                        d="M402,168c-2.93,40.67-33.1,72-66,72s-63.12-31.32-66-72c-3-42.31,26.37-72,66-72S405,126.46,402,168Z"
                        style={{
                          fill: "none",
                          strokeLinecap: "round",
                          strokeLinejoin: "round",
                          strokeWidth: 32,
                        }}
                      />
                      <path
                        d="M336,304c-65.17,0-127.84,32.37-143.54,95.41-2.08,8.34,3.15,16.59,11.72,16.59H467.83c8.57,0,13.77-8.25,11.72-16.59C463.85,335.36,401.18,304,336,304Z"
                        style={{
                          fill: "none",
                          strokeMiterlimit: 10,
                          strokeWidth: 32,
                        }}
                      />
                      <path
                        d="M200,185.94C197.66,218.42,173.28,244,147,244S96.3,218.43,94,185.94C91.61,152.15,115.34,128,147,128S202.39,152.77,200,185.94Z"
                        style={{
                          fill: "none",
                          strokeLinecap: "round",
                          strokeLinejoin: "round",
                          strokeWidth: 32,
                        }}
                      />
                      <path
                        d="M206,306c-18.05-8.27-37.93-11.45-59-11.45-52,0-102.1,25.85-114.65,76.2C30.7,377.41,34.88,384,41.72,384H154"
                        style={{
                          fill: "none",
                          strokeLinecap: "round",
                          strokeMiterlimit: 10,
                          strokeWidth: 32,
                        }}
                      />
                    </svg>
                  </div>
                </div>
                <div className="info-box-content">
                  <strong>{allDetails?.subscribers ? allDetails?.subscribers : 0}</strong>
                  <span>{langJson?.total_subscribers}</span>
                </div>
              </div>
            </li>
            <li className="col-md-2">
              <div className="info-box d-flex bg-red">
                <div className="info-box-icon">
                  <div className="icon d-flex">
                    <svg xmlns="http://www.w3.org/2000/svg" width={512} height={512} viewBox="0 0 512 512">
                      <path
                        d="M350.54,148.68l-26.62-42.06C318.31,100.08,310.62,96,302,96H210c-8.62,0-16.31,4.08-21.92,10.62l-26.62,42.06C155.85,155.23,148.62,160,140,160H80a32,32,0,0,0-32,32V384a32,32,0,0,0,32,32H432a32,32,0,0,0,32-32V192a32,32,0,0,0-32-32H373C364.35,160,356.15,155.23,350.54,148.68Z"
                        style={{
                          fill: "none",
                          strokeLinecap: "round",
                          strokeLinejoin: "round",
                          strokeWidth: 32,
                        }}
                      />
                      <circle
                        cx={256}
                        cy={272}
                        r={80}
                        style={{
                          fill: "none",
                          strokeMiterlimit: 10,
                          strokeWidth: 32,
                        }}
                      />
                      <polyline
                        points="124 158 124 136 100 136 100 158"
                        style={{
                          fill: "none",
                          strokeLinecap: "round",
                          strokeLinejoin: "round",
                          strokeWidth: 32,
                        }}
                      />
                    </svg>
                  </div>
                </div>
                <div className="info-box-content">
                  <strong>{allDetails?.videos ? allDetails?.videos : 0}</strong>
                  <span>{langJson?.total_videos}</span>
                </div>
              </div>
            </li>
            <li className="col-md-2">
              <div className="info-box d-flex bg-green">
                <div className="info-box-icon">
                  <div className="icon d-flex">
                    <svg xmlns="http://www.w3.org/2000/svg" width={512} height={512} viewBox="0 0 512 512">
                      <path
                        d="M255.66,112c-77.94,0-157.89,45.11-220.83,135.33a16,16,0,0,0-.27,17.77C82.92,340.8,161.8,400,255.66,400,348.5,400,429,340.62,477.45,264.75a16.14,16.14,0,0,0,0-17.47C428.89,172.28,347.8,112,255.66,112Z"
                        style={{
                          fill: "none",
                          strokeLinecap: "round",
                          strokeLinejoin: "round",
                          strokeWidth: 32,
                        }}
                      />
                      <circle
                        cx={256}
                        cy={256}
                        r={80}
                        style={{
                          fill: "none",
                          strokeMiterlimit: 10,
                          strokeWidth: 32,
                        }}
                      />
                    </svg>
                  </div>
                </div>
                <div className="info-box-content">
                  <strong>{allDetails?.total_views ? allDetails?.total_views : 0}</strong>
                  <span>{langJson?.total_views}</span>
                </div>
              </div>
            </li>
            <li className="col-md-2">
              <div className="info-box d-flex bg-orange">
                <div className="info-box-icon">
                  <div className="icon d-flex">
                    <svg xmlns="http://www.w3.org/2000/svg" width={512} height={512} viewBox="0 0 512 512">
                      <path
                        d="M431,320.6c-1-3.6,1.2-8.6,3.3-12.2a33.68,33.68,0,0,1,2.1-3.1A162,162,0,0,0,464,215c.3-92.2-77.5-167-173.7-167C206.4,48,136.4,105.1,120,180.9a160.7,160.7,0,0,0-3.7,34.2c0,92.3,74.8,169.1,171,169.1,15.3,0,35.9-4.6,47.2-7.7s22.5-7.2,25.4-8.3a26.44,26.44,0,0,1,9.3-1.7,26,26,0,0,1,10.1,2L436,388.6a13.52,13.52,0,0,0,3.9,1,8,8,0,0,0,8-8,12.85,12.85,0,0,0-.5-2.7Z"
                        style={{
                          fill: "none",
                          strokeLinecap: "round",
                          strokeMiterlimit: 10,
                          strokeWidth: 32,
                        }}
                      />
                      <path
                        d="M66.46,232a146.23,146.23,0,0,0,6.39,152.67c2.31,3.49,3.61,6.19,3.21,8s-11.93,61.87-11.93,61.87a8,8,0,0,0,2.71,7.68A8.17,8.17,0,0,0,72,464a7.26,7.26,0,0,0,2.91-.6l56.21-22a15.7,15.7,0,0,1,12,.2c18.94,7.38,39.88,12,60.83,12A159.21,159.21,0,0,0,284,432.11"
                        style={{
                          fill: "none",
                          strokeLinecap: "round",
                          strokeMiterlimit: 10,
                          strokeWidth: 32,
                        }}
                      />
                    </svg>
                  </div>
                </div>
                <div className="info-box-content">
                  <strong>{allDetails?.total_comments ? allDetails?.total_comments : 0}</strong>
                  <span>{langJson?.total_comments}</span>
                </div>
              </div>
            </li>
            <li className="col-md-2">
              <div className="info-box d-flex bg-deep-orange">
                <div className="info-box-icon">
                  <div className="icon d-flex">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path d="M198 448h172c15.7 0 28.6-9.6 34.2-23.4l57.1-135.4c1.7-4.4 2.6-9 2.6-14v-38.6c0-21.1-17-44.6-37.8-44.6H306.9l18-81.5.6-6c0-7.9-3.2-15.1-8.3-20.3L297 64 171 191.3c-6.8 6.9-11 16.5-11 27.1v192c0 21.1 17.2 37.6 38 37.6zM48 224h64v224H48z" />
                    </svg>
                  </div>
                </div>
                <div className="info-box-content">
                  <strong>{allDetails?.total_likes ? allDetails?.total_likes : 0}</strong>
                  <span>{langJson?.total_likes}</span>
                </div>
              </div>
            </li>
            <li className="col-md-2">
              <div className="info-box d-flex bg-blue-grey">
                <div className="info-box-icon">
                  <div className="icon d-flex">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path d="M314 64H142c-15.7 0-28.6 9.6-34.2 23.4L50.6 222.8c-1.7 4.4-2.6 9-2.6 14v38.6c0 21.1 17 44.6 37.8 44.6h119.3l-18 81.5-.6 6c0 7.9 3.2 15.1 8.3 20.3l20 20.1L341 320.7c6.8-6.9 11-16.5 11-27.1v-192c0-21.1-17.2-37.6-38-37.6zM400 64h64v224h-64z" />
                    </svg>
                  </div>
                </div>
                <div className="info-box-content">
                  <strong>{allDetails?.total_dislikes ? allDetails?.total_dislikes : 0}</strong>
                  <span>{langJson?.total_dislikes}</span>
                </div>
              </div>
            </li>
          </ul>
          <div className="channel-subscriptions-chart">
            <div className="dashboardHeaderTest">
              <h4>
                {langJson?.subscriptions}{" "}
                <a data-tooltip-id="my-tooltip-1">
                  <svg xmlns="http://www.w3.org/2000/svg" width={512} height={512} viewBox="0 0 512 512">
                    <path
                      d="M288.55,150.84c-8.09-3.86-20-6-32.72-5.82-18,.22-33.13,5.2-45,14.78-23,18.48-24.55,40.37-24.77,42.8a61.69,61.69,0,0,0-.09,12,3,3,0,0,0,3,2.69h21.23a3,3,0,0,0,3-3A65.7,65.7,0,0,1,214,204c0-.11,1.14-11.7,14.36-22.34,7-5.64,16.11-8.44,27.83-8.59,9.32-.11,16.93,1.47,20.34,3.09C291,183,298,192.31,298,204.57c0,18-10.9,26.23-30.18,39.18C247.08,257.68,237,275.1,237,297v11a3,3,0,0,0,3,3h22a3,3,0,0,0,3-3V297c0-9.16,2.23-19.13,18.44-30C303.39,253.59,326,238.4,326,204.57,326,181.43,312.7,162.34,288.55,150.84Z"
                      style={{
                        fill: "none",
                      }}
                    />
                    <path d="M256,64C150,64,64,150,64,256s86,192,192,192,192-86,192-192S362,64,256,64Zm10.44,302H236.23a2.57,2.57,0,0,1-2.56-2.57v-30.2a2.57,2.57,0,0,1,2.56-2.57h30.21a2.57,2.57,0,0,1,2.56,2.57v30.2A2.57,2.57,0,0,1,266.44,366Zm17-99C267.23,277.88,265,287.85,265,297v11a3,3,0,0,1-3,3H240a3,3,0,0,1-3-3V297c0-21.91,10.08-39.33,30.82-53.26C287.1,230.8,298,222.6,298,204.57c0-12.26-7-21.57-21.49-28.46-3.41-1.62-11-3.2-20.34-3.09-11.72.15-20.82,2.95-27.83,8.59C215.12,192.25,214,203.84,214,204a65.7,65.7,0,0,0-.84,10.28,3,3,0,0,1-3,3H188.91a3,3,0,0,1-3-2.69,61.69,61.69,0,0,1,.09-12c.22-2.43,1.8-24.32,24.77-42.8,11.91-9.58,27.06-14.56,45-14.78,12.7-.15,24.63,2,32.72,5.82C312.7,162.34,326,181.43,326,204.57,326,238.4,303.39,253.59,283.44,267Z" />
                  </svg>
                </a>
              </h4>
              <ReactTooltip id="my-tooltip-1" place="left" content="Annual statistics of subscriptions to your channel" />
            </div>
            <div className="graph-section">
              <Bar options={options} data={data} />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Dashboard;
