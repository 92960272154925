import React, {useState} from "react";
import "./Products.scss";
import "../user-common.scss";
import AfterLoginHeader from "../../../include/AfterLoginHeader";
import AfterLoginSidebar from "../../../include/AfterLoginSidebar";
import ChannelBanner from "../../../include/channelBanner/ChannelBanner";
import Footer from "../../../include/Footer";
import {Link, useNavigate, useParams} from "react-router-dom";
import {deleteProductApi, deleteProductImageApi, getChannelProductsApi} from "../../../services/ApiService";
import Dropdown from "react-bootstrap/Dropdown";
import {Modal, Button} from "react-bootstrap";
import {useLanguage} from "../../../utils/LanguageProvider";
import GlobalSuccessModal from "../../../include/GlobalSuccessModal";

const Products = props => {
  const {username} = useParams();
  const navigate = useNavigate();
  const {langJson} = useLanguage();

  const [userDetail] = React.useState(JSON.parse(localStorage.getItem("loginInfo"))?.user); // Set the login user id
  const [productsList, setProductsList] = React.useState([]); // For store
  const [deleteVideosShow, setDeleteVideosShow] = React.useState(false);
  const [productId, setProductId] = React.useState();
  const [modalShouldOpen, setModalShouldOpen] = React.useState(false); // For success modal
  const [modalOpenMessage, setModalOpenMessage] = React.useState(); // For success modal
  const [btnDis, setBtnDis] = React.useState(false); // For button disable
  const handleDeleteShow = () => setDeleteVideosShow(true);
  const handleDeleteClose = () => setDeleteVideosShow(false);
  const [currentPage, setCurrentPage] = useState(1); // For load more
  const [loading, setLoading] = useState(false); // For show load more buttton
  const [videoResponseConnt, setVideoResponseConnt] = useState(); // For show load more buttton
  const [videoLimit] = useState(16); // For video limit

  const goToCreateProduct = () => {
    navigate("/create-product");
  };

  // Fetching products
  const fetchProducts = async page => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("page", page);
      formData.append("limit", videoLimit);
      let response = await getChannelProductsApi(username.slice(1), formData);
      if (response) {
        if (response.data.res == 1) {
          setVideoResponseConnt(response.data.data.length);
          let tempArray = [...productsList];
          response.data.data.map((item, index) => {
            tempArray.push(item);
          });

          setProductsList(tempArray);
        } else {
          setProductsList([]);
        }
      }
    } catch (error) {}
  };

  // Delete products
  const deleteProduct = async () => {
    try {
      setBtnDis(true);
      let response = await deleteProductApi(productId);
      if (response) {
        if (response.data.res == 1) {
          let tempArray = [...productsList];
          let index = tempArray.findIndex(item => parseInt(item?.id) === parseInt(productId));
          tempArray.splice(index, 1);
          setProductsList(tempArray);
          handleDeleteClose();
          setModalOpenMessage(response.data.msg);
          setModalShouldOpen(true);
          setTimeout(() => {
            setModalShouldOpen(false);
          }, 1000);
        } else {
        }
        setBtnDis(false);
      }
    } catch (error) {
      setBtnDis(false);
    }
  };

  // Triggered after clicking load more button
  const onLoadMore = () => {
    setCurrentPage(prevCount => (prevCount += 1));
  };

  React.useEffect(() => {
    fetchProducts(currentPage);
  }, [currentPage]);

  return (
    <div>
      <AfterLoginHeader />
      <AfterLoginSidebar />
      {/* <ChannelBanner /> */}
      <div className="content-wrapper channel-content-wrapper">
        <div className="content">
          <div className="content-block-header">
            <h1>
              {langJson?.products}{" "}
              {userDetail?.username === username.slice(1) ? (
                <Button className="btn-custom main-outline" onClick={() => goToCreateProduct()}>
                  {langJson?.create_product}
                </Button>
              ) : null}
            </h1>
          </div>

          {productsList && productsList?.length > 0 ? (
            <>
              <div className="recommended-videos">
                <ul className="row main-play-list">
                  {productsList &&
                    productsList?.map((item, index) => (
                      <li className="col-lg-3 col-md-4" key={index}>
                        <div className="video-grid-item">
                          <div className="video-poster">
                            <Link className to={`/watch/${item?.id}`}>
                              {/* <img src="/static/media/ukmVudQ8LvHAVWowILTz_28_b394842e90a83ee71be0c59fda2c702f_thumbnail_1280x720.566cfa0bf15e144f0927.jpg" alt /> */}
                              <img src={item?.thumbnail} alt />
                              <span className="video-dur">{item?.duration}</span>
                            </Link>
                          </div>
                          <div className="video-description">
                            <div className="avatar">
                              <img src={item?.u_avatar} alt />
                            </div>
                            <div className="video-info">
                              <h6>
                                <a className href="/watch/0">
                                  {item?.title}
                                </a>
                              </h6>
                              {/* <div className="video-meta-info">
                                <div className="uname">
                                  <a>{item?.u_name}</a>
                                </div>
                                <div className="quick-stats">
                                  <span>
                                    {item?.views} views • {item?.time}
                                  </span>
                                </div>
                              </div> */}
                            </div>
                            <div className="controls">
                              <Dropdown>
                                <Dropdown.Toggle variant="link" id="dropdown-basic">
                                  <svg
                                    width="20px"
                                    height="20px"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                    aria-labelledby="appsAltIconTitle"
                                    stroke="#000000"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    fill="none"
                                    color="#000000"
                                    {...props}>
                                    <title id="appsAltIconTitle">{"Apps drawer"}</title>
                                    <rect x={5} y={5} width={2} height={2} />
                                    <rect x={11} y={5} width={2} height={2} />
                                    <rect x={17} y={5} width={2} height={2} />
                                    <rect x={5} y={11} width={2} height={2} />
                                    <rect x={11} y={11} width={2} height={2} />
                                    <rect x={17} y={11} width={2} height={2} />
                                    <rect x={5} y={17} width={2} height={2} />
                                    <rect x={11} y={17} width={2} height={2} />
                                    <rect x={17} y={17} width={2} height={2} />
                                  </svg>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Button
                                    variant="link"
                                    className="dropdown-item"
                                    onClick={() => {
                                      handleDeleteShow();
                                      setProductId(item?.id);
                                    }}>
                                    {langJson?.delete}
                                  </Button>
                                  <Link to={`/edit-product/${item?.id}`} className="dropdown-item">
                                    {langJson?.edit}
                                  </Link>

                                  {/* <Dropdown.Item>
                                    <Button variant="link" className="" onClick={() => handleSaveListShow()}>
                                    {langJson?.save}
                                    </Button>
                                  </Dropdown.Item> */}
                                  {/* <Dropdown.Item>Report</Dropdown.Item> */}
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                </ul>
                {!loading && videoResponseConnt >= videoLimit ? (
                  <div className="ufx-load-more d-flex">
                    <Button variant="outline-success" type="butto" className="btn-custom text-uppercase main-outline" onClick={() => onLoadMore()}>
                      {langJson?.load_more}
                    </Button>
                  </div>
                ) : null}
              </div>
            </>
          ) : !loading && productsList && productsList?.length === 0 ? (
            <div className="empty-page-placeholder">
              <div className="placeholder-message d-flex">
                <div className="message-text">
                  <h3>{langJson?.no_products_found}</h3>
                  <p>{langJson?.oops_it_looks_like_there_is_no_products}</p>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <Footer />
      <GlobalSuccessModal message={modalOpenMessage} show={modalShouldOpen} onClose={() => setModalShouldOpen(false)} />

      {/* Delete Video Modal Start */}
      <Modal show={deleteVideosShow} onHide={handleDeleteClose} className="cmnModal deleteVideorModal">
        <Modal.Body>
          <h3>{langJson?.please_confirm_your_actions}</h3>
          <p>{langJson?.please_note_that_if_you_delete_this_products_information_related}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" className="cancel-btn" onClick={handleDeleteClose}>
            {langJson?.cancel}
          </Button>
          <Button variant="primary" disabled={btnDis} onClick={() => deleteProduct()}>
            {langJson?.delete}
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Delete Video Modal End */}
    </div>
  );
};

export default Products;
